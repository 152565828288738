import React, { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { BiFilterAlt } from "react-icons/bi";
import UseToggle from "../../../../../Hooks/UseToggle";
import AddUpdateSubjectMetadata from "./AddUpdateSubjectMetadata";
import { authServices } from "../../../../../APIServices/authServices";
import { useParams } from "react-router-dom";
import Pagination from "../../../../../Hooks/Pagination";

const SubjectMetadata = () => {
  const { toggle, setToggle } = UseToggle();
  const [loading, setLoading] = useState(false);
  const [subjectConfigId, setSubjectConfigId] = useState("");
  const [subjectColumnData, setSubjectColumnData] = useState([]);
  const { studyId } = useParams();

  const getSubjectColumnData = async () => {
    setLoading(true);
    let data = await authServices.getSubjectMetadata("SUBJECT", studyId);
    setSubjectColumnData(data || []);
    setLoading(false);
  };

  const handleAddSubjectConfigClick = () => {
    const newId = result[0]?.id;
    setSubjectConfigId(newId);
    setToggle();
  };

  useEffect(() => {
    getSubjectColumnData();
  }, [studyId]);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = subjectColumnData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(subjectColumnData?.length / itemsPerPage);

  return (
    <>
      <div
        className="hstack justify-content-between w-100 border-bottom"
        style={{ padding: "4.6px 9px" }}
      >
        <div className="hstack gap-2">
          <button
            title="Add Subject"
            className="tabButtons border rounded-1 fs-10"
            style={{ padding: "3px 10px" }}
            onClick={handleAddSubjectConfigClick}
          >
            Add Subject Config
          </button>
          <div className="vr"></div>
          <button
            title="Filter"
            className="tabButtons border rounded-1 fs-13"
            style={{ padding: "1px 5px" }}
          >
            <BiFilterAlt />
          </button>
        </div>
      </div>
      {loading ? (
        <div
          className="hstack justify-content-center"
          style={{ height: "calc(100vh - 156px)" }}
        >
          <Spinner />
        </div>
      ) : subjectColumnData?.length === 0 ? (
        <div
          className="hstack justify-content-center"
          style={{ height: "calc(100vh - 156px)" }}
        >
          Subject Not Found!
        </div>
      ) : (
        <>
          <div
            className="position-relative px-2 mt-2 overflow-auto"
            style={{ height: "calc(100vh - 155px)" }}
          >
            <Table
              id="resizable-table"
              className="text-nowrap mb-1 custom-table"
              hover
              bordered
              striped
            >
              <thead className="position-sticky z-1" style={{ top: -1 }}>
                <tr>
                  <th style={{ width: 40 }}>Sr.</th>
                  <th>Label</th>
                  <th>Name</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                {result?.map((item, index) =>
                  item?.data?.map((header, i) => (
                    <tr
                      key={item.id}
                      className="position-relative cursor-pointer"
                    >
                      <td>{index + 1}</td>

                      <td>{header?.label}</td>
                      <td>{header?.name}</td>
                      <td>{header?.type}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>

            <div className="position-sticky bottom-0">
              <Pagination
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={subjectColumnData?.length}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </div>
        </>
      )}
      <AddUpdateSubjectMetadata
        Show={!toggle}
        Hide={setToggle}
        subjectConfigId={subjectConfigId}
        subjectColumnData={subjectColumnData}
        getSubjectColumnData={getSubjectColumnData}
        Title={"Add Subject Config"}
      />
    </>
  );
};

export default SubjectMetadata;
