import React, { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import Multiselect from "multiselect-react-dropdown";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FaSquarePlus, FaXmark } from "react-icons/fa6";

const EditorFieldSettings = ({
  fieldData,
  formValues,
  setFormValues,
  handleUpdateField,
  formIndex,
}) => {
  const [optionIndex, setOptionIndex] = useState("")
  const [optionName, setOptionName] = useState("")

  const handleByAdditionalAddSection = (formIndex) => {
    const updatedFormValues = [...formValues];
    const additionalCategory = updatedFormValues[formIndex]?.additionalCategory || [];
    updatedFormValues[formIndex].additionalCategory = [
      ...additionalCategory,
      { id: Math.random().toString().substr(2, 12), options: [] }
    ];
    setFormValues(updatedFormValues);
    setOptionIndex("")
    setOptionName("")
  };

  const handleByAdditionalRemoveSection = (formIndex, id) => {
    const updatedFormValues = [...formValues];
    const additionalCategory = updatedFormValues[formIndex]?.additionalCategory || [];
    updatedFormValues[formIndex].additionalCategory = additionalCategory.filter((section) => section.id !== id);
    setFormValues(updatedFormValues);
    setOptionIndex("")
    setOptionName("")
  };

  const handleByAdditionalSelectChange = (formIndex, id, field, value) => {
    const updatedFormValues = [...formValues];
    const additionalCategory = updatedFormValues[formIndex]?.additionalCategory || [];
    updatedFormValues[formIndex].additionalCategory = additionalCategory.map((section) =>
      section.id === id ? { ...section, [field]: value } : section
    );
    setFormValues(updatedFormValues);
    setOptionIndex("")
    setOptionName("")
  };

  const addAdditionalOption = (formIndex, id) => {
    if (id) {
      const updatedFormValues = [...formValues];
      const additionalCategory = updatedFormValues[formIndex]?.additionalCategory || [];
      updatedFormValues[formIndex].additionalCategory = additionalCategory.map((section) =>
        section.id === id
          ? {
            ...section,
            options: [
              ...section?.options || [],
              { id: Math.random().toString().substr(2, 12), value: optionName },
            ],
          }
          : section
      );
      setFormValues(updatedFormValues);
      setOptionIndex("")
      setOptionName("")
    }
  };

  const deleteAdditionalOption = (formIndex, id, optionIndex) => {
    const updatedFormValues = [...formValues];
    const additionalCategory = updatedFormValues[formIndex]?.additionalCategory || [];
    updatedFormValues[formIndex].additionalCategory = additionalCategory.map((section) =>
      section.id === id
        ? { ...section, options: section.options.filter((_, index) => index !== optionIndex) }
        : section
    );
    setFormValues(updatedFormValues);
    setOptionIndex("")
    setOptionName("")
  };

  const updateAdditionalOption = (formIndex, id) => {
    const updatedFormValues = [...formValues];
    const additionalCategory = updatedFormValues[formIndex]?.additionalCategory || [];

    updatedFormValues[formIndex].additionalCategory = additionalCategory.map((section) =>
      section.id === id
        ? {
          ...section,
          options: section.options.map((option, index) =>
            index === (optionIndex - 1) ? { ...option, value: optionName } : option
          ),
        }
        : section
    );
    setFormValues(updatedFormValues);
    setOptionName("")
    setOptionIndex("")
  };

  const handleAddOption = (id) => {
    if (id) {
      const updatedFormValues = formValues.map((field) =>
        field.id === id
          ? {
            ...field,
            options: [...(field.options || []), { id: Math.random().toString().substr(2, 12), value: optionName }],
          }
          : field
      );
      setFormValues(updatedFormValues);
      setOptionIndex("")
      setOptionName("")
    }
  };

  const handleEditOption = (id) => {
    if (id) {
      const updatedFormValues = formValues.map((field) =>
        field.id === id
          ? {
            ...field,
            options: field.options.map((opt, idx) =>
              idx === (optionIndex - 1) ? { ...opt, value: optionName } : opt
            ),
          }
          : field
      );
      setFormValues(updatedFormValues);
      setOptionName("")
      setOptionIndex("")
    }
  };

  const handleDeleteOption = (id, optionIndex) => {
    const updatedFormValues = formValues.map((field) =>
      field.id === id
        ? {
          ...field,
          options: field.options.filter((_, idx) => idx !== optionIndex),
        }
        : field
    );
    setFormValues(updatedFormValues);
    setOptionIndex("")
    setOptionName("")
  };

  useEffect(() => {
    setOptionName("")
    setOptionIndex("")
  }, [fieldData]);

  return (
    <div className="p-3">
      <Row>
        <Form.Group as={Col} md={6} className="mb-2">
          <Form.Label className="fw-bold mb-1">
            Field Label <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            onChange={(e) => handleUpdateField(fieldData?.id, "label", e.target.value)}
            value={fieldData?.label || ""}
            placeholder="Enter Text Label" />
        </Form.Group>
        <Form.Group as={Col} md={6} className="mb-2">
          <Form.Label className="fw-bold mb-1">
            Field Name <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            onChange={(e) => handleUpdateField(fieldData?.id, "name", e.target.value)}
            value={fieldData?.name || ""}
            placeholder="Enter Text Name" />
        </Form.Group>
        {fieldData?.type !== "heading" &&
          <>
            <Form.Group as={Col} md={6} className="mb-2">
              <Form.Label className="fw-bold mb-1">
                Field Placeholder <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => handleUpdateField(fieldData?.id, "placeholder", e.target.value)}
                value={fieldData?.placeholder || ""}
                placeholder="Enter Placeholder" />
            </Form.Group>
            <Form.Group as={Col} md={6} className="mb-2">
              <Form.Label className="fw-bold mb-1">
                Field Help <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => handleUpdateField(fieldData?.id, "help", e.target.value)}
                value={fieldData?.help || ""}
                placeholder="Enter Field Help"
              />
            </Form.Group>
          </>
        }
        {(fieldData?.type === "text" || fieldData?.type === "textarea") &&
          <Form.Group as={Col} md={6} className="mb-2">
            <Form.Label className="fw-bold mb-1">
              Text Length <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Text Length"
              onChange={(e) => handleUpdateField(fieldData?.id, "length", e.target.value)}
              value={fieldData?.length || ""}
            />
          </Form.Group>
        }
        {fieldData?.type === "number" &&
          <Form.Group as={Col} md={6} className="mb-2">
            <Form.Label className="fw-bold mb-1">
              Number Type <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => handleUpdateField(fieldData?.id, "numberType", e.target.value)}
            >
              <option>Select Number Type</option>
              <option selected={fieldData?.numberType === "wholeNumber"} value="wholeNumber">Whole Number</option>
              <option selected={fieldData?.numberType === "floatNumber"} value="floatNumber">Float Number</option>
            </Form.Select>
          </Form.Group>
        }
        {(fieldData?.type === "text" || fieldData?.type === "number") &&
          <>
            <Form.Group as={Col} md={6} className="mb-2">
              <Form.Label className="fw-bold mb-1">
                Unit Label <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Unit Label"
                onChange={(e) => handleUpdateField(fieldData?.id, "unitLabel", e.target.value)}
                value={fieldData?.unitLabel || ""}
              />
            </Form.Group>
            <Form.Group as={Col} md={6} className="mb-2">
              <Form.Label className="fw-bold mb-1">
                Unit Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Unit Name"
                onChange={(e) => handleUpdateField(fieldData?.id, "unitName", e.target.value)}
                value={fieldData?.unitName || ""}
              />
            </Form.Group>
            <Form.Group as={Col} md={6} className="mb-2">
              <Form.Label className="fw-bold mb-1">
                Unit Help <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => handleUpdateField(fieldData?.id, "unitHelp", e.target.value)}
                value={fieldData?.unitHelp || ""}
                placeholder="Enter Unit Help"
              />
            </Form.Group>
          </>
        }
        {fieldData?.type === "time" &&
          <Form.Group as={Col} md={6} className="mb-2">
            <Form.Label className="fw-bold mb-1">
              Time Formate <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => handleUpdateField(fieldData?.id, "format", e.target.value)}
            >
              <option>Select Format</option>
              <option selected={fieldData?.format === "HH:MM:SS"} value="HH:MM:SS">HH:MM:SS</option>
              <option selected={fieldData?.format === "HH:MM"} value="HH:MM">HH:MM</option>
            </Form.Select>
          </Form.Group>
        }
        {fieldData?.type === "date" &&
          <Form.Group as={Col} md={6} className="mb-2">
            <Form.Label className="fw-bold mb-1">
              Date Formate <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              value={fieldData?.format}
              onChange={(e) => handleUpdateField(fieldData?.id, "format", e.target.value)}
            >
              <option value="">Select Format</option>
              <option selected={fieldData?.format === "YYYY_MMM_DD"} value="YYYY_MMM_DD">YYYYMMMDD</option>
              <option selected={fieldData?.format === "DD_MMM_YYYY"} value="DD_MMM_YYYY">DDMMMYYYY</option>
              <option selected={fieldData?.format === "DD/MMM/YYYY"} value="DD/MMM/YYYY">DD/MMM/YYYY</option>
              <option selected={fieldData?.format === "YYYY/MMM/DD"} value="YYYY/MMM/DD">YYYY/MMM/DD</option>
              <option selected={fieldData?.format === "DD-MMM-YYYY"} value="DD-MMM-YYYY">DD-MMM-YYYY</option>
              <option selected={fieldData?.format === "YYYY-MMM-DD"} value="YYYY-MMM-DD">YYYY-MMM-DD</option>
              <option selected={fieldData?.format === "DD_MM_YYYY"} value="DD_MM_YYYY">DDMMYYYY</option>
              <option selected={fieldData?.format === "YYYY_MM_DD"} value="YYYY_MM_DD">YYYYMMDD</option>
              <option selected={fieldData?.format === "DD/MM/YYYY"} value="DD/MM/YYYY">DD/MM/YYYY</option>
              <option selected={fieldData?.format === "YYYY/MM/DD"} value="YYYY/MM/DD">YYYY/MM/DD</option>
              <option selected={fieldData?.format === "YYYY-MM-DD"} value="YYYY-MM-DD">YYYY-MM-DD</option>
            </Form.Select>
          </Form.Group>
        }
        {(fieldData?.type === "radio" || fieldData?.type === "checkbox" || fieldData?.type === "select" || (fieldData?.unitName && (fieldData?.type === "text" || fieldData?.type === "number"))) &&
          <Form.Group as={Col} md={6} className="mb-2">
            <Form.Label className="fw-bold mb-1">
              Add {(fieldData?.type === "text" || fieldData?.type === "number") && "Unit "}Options <span className="text-danger">*</span>
            </Form.Label>
            <InputGroup className="mb-2">
              <Form.Control type="text"
                placeholder="Option Name"
                value={optionName}
                onChange={(e) => setOptionName(e.target.value)}
              />
              <InputGroup.Text
                className="py-0 px-2 bg-color text-white cursor-pointer"
                onClick={() => { optionIndex ? handleEditOption(fieldData?.id) : !optionName ? handleAddOption() : handleAddOption(fieldData?.id) }}
                style={{ fontSize: 12, border: "1px solid #172b73" }}>{optionIndex ? "Update" : "Add"}</InputGroup.Text>
            </InputGroup>
            {fieldData?.options?.map((option, index) => (
              <div className="hstack gap-3" key={option.id}>
                <Form.Label className="m-0">{option.value}</Form.Label>
                <div style={{ fontSize: 11 }}>
                  {(optionIndex - 1) !== index &&
                    <FaRegEdit
                      className="CP"
                      title="Edit Option"
                      style={{ marginRight: 5, cursor: "pointer" }}
                      onClick={() => { setOptionIndex(index + 1); setOptionName(option.value) }}
                    />
                  }
                  <RiDeleteBin6Line
                    title="Delete Option"
                    className="text-danger CP"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDeleteOption(fieldData?.id, index)}
                  />
                </div>
              </div>
            ))}
          </Form.Group>
        }
        {fieldData?.type === "upload" &&
          <Form.Group as={Col} md={12} className="mb-2">
            <Form.Label className="fw-bold mb-1">
              File Type <span className="text-danger">*</span>
            </Form.Label>
            <Multiselect
              displayValue="label"
              onKeyPressFn={function noRefCheck() { }}
              onRemove={(selectedList) => handleUpdateField(fieldData?.id, "extension", selectedList)}
              onSelect={(selectedList) => handleUpdateField(fieldData?.id, "extension", selectedList)}
              options={[
                { label: "PDF File", value: ".pdf" },
                { label: "TXT File", value: ".txt" },
                { label: "ZIP File", value: ".zip" },
                { label: "RAR File", value: ".rar" },
                { label: "XPT File", value: ".xpt" },
                { label: "CSV File", value: ".csv" },
                { label: "DOC File", value: ".doc" },
                { label: "GAS File", value: ".gas" },
                { label: "DOCx File", value: ".docx" },
                { label: "XLS File", value: ".xls" },
                { label: "XLSx File", value: ".xlsx" },
                { label: "PPT File", value: ".ppt" },
                { label: "PPTx File", value: ".pptx" },
                { label: "PNG File", value: ".png" },
                { label: "JPG File", value: ".jpg" },
                { label: "JPEG File", value: ".jpeg" },
              ]}
            />
          </Form.Group>
        }
        {(fieldData?.type === "radio" || fieldData?.type === "checkbox" || fieldData?.type === "select" || fieldData?.type === "text" || fieldData?.type === "number") &&
          <>
            <Form.Group className="mb-2">
              <div className="hstack gap-2">
                <Form.Label className="fw-bold mb-0">
                  Group Field Category <span className="text-danger">*</span>
                </Form.Label>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  checked={!!fieldData?.fieldCategories}
                  onClick={(e) => handleUpdateField(fieldData?.id, "fieldCategories", e.target.checked)} />
              </div>
            </Form.Group>
            {fieldData?.fieldCategories &&
              <>
                <Row>
                  <Form.Group as={Col} md={6} className="mb-2">
                    <Form.Label className="fw-bold mb-1">
                      Category Name  <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => handleUpdateField(fieldData?.id, "categoryName", e.target.value)}
                      value={fieldData?.categoryName || ""}
                      placeholder="Enter Category Label"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6} className="mb-2">
                    <Form.Label className="fw-bold mb-1">
                      Category Label <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => handleUpdateField(fieldData?.id, "categoryLabel", e.target.value)}
                      value={fieldData?.categoryLabel || ""}
                      placeholder="Enter Category Label"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6} className="mb-2">
                    <Form.Label className="fw-bold mb-1">
                      Category Value Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => handleUpdateField(fieldData?.id, "categoryValueName", e.target.value)}
                      value={fieldData?.categoryValueName || ""}
                      placeholder="Enter Category Value Name"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6} className="mb-2">
                    <Form.Label className="fw-bold mb-1">
                      Category Value Label <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => handleUpdateField(fieldData?.id, "categoryValueLabel", e.target.value)}
                      value={fieldData?.categoryValueLabel || ""}
                      placeholder="Enter Category Value Label"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6} className="mb-2">
                    <Form.Label className="fw-bold mb-1">
                      Category Unit Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => handleUpdateField(fieldData?.id, "categoryUnitName", e.target.value)}
                      value={fieldData?.categoryUnitName || ""}
                      placeholder="Enter Category Unit Name"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6} className="mb-2">
                    <Form.Label className="fw-bold mb-1">
                      Category Unit Label <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => handleUpdateField(fieldData?.id, "categoryUnitLabel", e.target.value)}
                      value={fieldData?.categoryUnitLabel || ""}
                      placeholder="Enter Category Unit Label"
                    />
                  </Form.Group>
                </Row>
                <Form.Group className="mb-2">
                  <button
                    className="bg-color fs-13 text-white border"
                    style={{ padding: "2px 25px", borderRadius: 3 }}
                    onClick={() => handleByAdditionalAddSection(formIndex)}
                  >
                    Add Additional Fields <FaSquarePlus />
                  </button>
                </Form.Group>
                {(fieldData?.additionalCategory || [])?.map((item, index) => (
                  <Form.Group className="mb-2">
                    <div
                      className="rounded-1 position-relative p-3 mb-2"
                      style={{ border: "1px dashed #c7c7c7" }}
                      key={item?.id}
                    >
                      <Row>
                        <Form.Group as={Col} md={12} className="mb-2">
                          <Form.Label className="fw-bold mb-1">
                            Additional Category Type <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Select
                            name="type"
                            aria-label="Default select example"
                            value={item?.[`type${index + 1}`] || ""}
                            onChange={(e) =>
                              handleByAdditionalSelectChange(formIndex, item?.id, `type${index + 1}`, e.target.value)
                            }
                          >
                            <option>-- Select Additional Category Type--</option>
                            <option selected={`type${index + 1}` === "select"} value="select">Dropdown</option>
                            <option selected={`type${index + 1}` === "checkbox"} value="checkbox">Checkbox</option>
                            <option selected={`type${index + 1}` === "radio"} value="radio">Radio</option>
                            <option selected={`type${index + 1}` === "date"} value="date">Date</option>
                            <option selected={`type${index + 1}` === "time"} value="time">Time</option>
                            <option selected={`type${index + 1}` === "text"} value="text">Text</option>
                            <option selected={`type${index + 1}` === "number"} value="number">Number</option>
                            <option selected={`type${index + 1}` === "textarea"} value="textarea">Long Text</option>
                          </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} md={6} className="mb-2">
                          <Form.Label className="fw-bold mb-1">
                            Additional Category Name <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={item?.[`name${index + 1}`] || ""}
                            onChange={(e) => handleByAdditionalSelectChange(formIndex, item?.id, `name${index + 1}`, e.target.value)}
                            placeholder="Enter Additional Category Name"
                          />
                        </Form.Group>

                        <Form.Group as={Col} md={6} className="mb-2">
                          <Form.Label className="fw-bold mb-1">
                            Additional Category Label <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={item?.[`label${index + 1}`] || ""}
                            onChange={(e) => handleByAdditionalSelectChange(formIndex, item?.id, `label${index + 1}`, e.target.value)}
                            placeholder="Enter Additional Category Label"
                          />
                        </Form.Group>

                        <Form.Group as={Col} md={6} className="mb-2">
                          <Form.Label className="fw-bold mb-1">
                            Additional Category Placeholder <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={item?.[`placeholder${index + 1}`] || ""}
                            onChange={(e) => handleByAdditionalSelectChange(formIndex, item?.id, `placeholder${index + 1}`, e.target.value)}
                            placeholder="Enter Additional Category Placeholder"
                          />
                        </Form.Group>
                        <Form.Group as={Col} md={6} className="mb-2">
                          <Form.Label className="fw-bold mb-1">
                            Additional Category Help <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={item?.[`help${index + 1}`] || ""}
                            onChange={(e) => handleByAdditionalSelectChange(formIndex, item?.id, `help${index + 1}`, e.target.value)}
                            placeholder="Enter Additional Category Help"
                          />
                        </Form.Group>
                        {(item?.[`type${index + 1}`] === "checkbox" || item?.[`type${index + 1}`] === "select" || item?.[`type${index + 1}`] === "radio") &&
                          <Form.Group as={Col} md={6} className="mb-2">
                            <Form.Label className="fw-bold mb-1">
                              Add Options <span className="text-danger">*</span>
                            </Form.Label>
                              <InputGroup className="mb-1">
                                <Form.Control type="text"
                                  placeholder="Option Name"
                                  value={optionName}
                                  onChange={(e) => setOptionName(e.target.value)}
                                />
                                <InputGroup.Text
                                  className="py-0 px-2 bg-color text-white cursor-pointer"
                                  onClick={() => { optionIndex ? updateAdditionalOption(formIndex, item?.id) : !optionName ? addAdditionalOption() : addAdditionalOption(formIndex, item?.id) }}
                                  style={{ fontSize: 12, border: "1px solid #172b73" }}>{optionIndex ? "Update" : "Add"}</InputGroup.Text>
                              </InputGroup>
                            {(item?.options || [])?.map((option, i) => (
                              <div className="hstack gap-3" key={option.id}>
                                <Form.Label className="m-0">{option.value}</Form.Label>
                                <div style={{ fontSize: 11 }}>
                                  {optionIndex !== i &&
                                    <FaRegEdit
                                      className="CP"
                                      title="Edit Option"
                                      style={{ marginRight: 5, cursor: "pointer" }}
                                      onClick={() => { setOptionIndex(i); setOptionName(option.value) }}
                                    />
                                  }
                                  <RiDeleteBin6Line
                                    title="Delete Option"
                                    className="text-danger CP"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => deleteAdditionalOption(formIndex, item?.id, i)}
                                  />
                                </div>
                              </div>
                            ))}
                          </Form.Group>
                        }
                      </Row>
                      <div
                        className="position-absolute bg-color px-2 text-white"
                        style={{ top: -11, right: 30 }}
                      >
                        <Form.Label className="mb-0">Additional Category {index + 1}</Form.Label>
                      </div>
                      <div
                        className="position-absolute bg-danger px-1 text-white cursor-pointer"
                        title="Delete Additional Field"
                        style={{ top: -11, right: 6 }}
                        onClick={() => handleByAdditionalRemoveSection(formIndex, item?.id)}
                      >
                        <FaXmark />
                      </div>
                    </div>
                  </Form.Group>
                ))}
              </>
            }
          </>
        }
      </Row>
    </div>
  );
};

export default EditorFieldSettings;
