import React, { useEffect, useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { FaPlusSquare } from "react-icons/fa";
import { authServices } from "../../../../APIServices/authServices";

const AddClientForm = ({
  Show,
  Hide,
  Title,
  userUpdateId,
  getUserListByType,
}) => {
  const [city, setCity] = useState("");
  const [id, setId] = useState("");
  const [state, setState] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const validatePhoneNumber = (phone) => {
    const phonePattern = /^\d{10}$/;

    if (phonePattern.test(phone)) {
      setPhoneError("");
    } else {
      setPhoneError("Phone Number should have 10 digits!");
    }
  };

  const addUserClient = async () => {
    let userData = {};
    userData.clientUserName = userName;
    userData.firstName = companyName;
    userData.phoneNumber = phone;
    userData.website = website;
    userData.email = email;
    userData.country = country;
    userData.state = state;
    userData.city = city;
    userData.pinCode = postalCode;
    userData.address = address;
    userData.erroracccessrights = false;
    userData.status = "Active";
    userData.userType = "sponsor";
    userData.authorities = [{ name: "" }];
    let result = await authServices.addUserClient(userData);
    getUserListByType();
    clearForm();
    Hide();
  };

  const updateUserClient = async () => {
    let userData = {};
    userData.id = id;
    userData.clientUserName = userName;
    userData.username = companyName;
    userData.phoneNumber = phone;
    userData.website = website;
    userData.email = email;
    userData.userType = "client";
    userData.country = country;
    userData.state = state;
    userData.city = city;
    userData.pinCode = postalCode;
    userData.address = address;
    let data = await authServices.updateUserClient(userData, id);
    getUserListByType();
    Hide();
  };

  const clearForm = () => {
    setUserName("");
    setCompanyName("");
    setPhone("");
    setWebsite("");
    setEmail("");
    setCountry("");
    setState("");
    setCity("");
    setPostalCode("");
    setAddress("");
    setId("");
  };

  useEffect(() => {
    setUserName(userUpdateId?.clientUserName);
    setCompanyName(userUpdateId?.firstName);
    setPhone(userUpdateId?.phoneNumber);
    setWebsite(userUpdateId?.website);
    setEmail(userUpdateId?.email);
    setCountry(userUpdateId?.country);
    setState(userUpdateId?.state);
    setCity(userUpdateId?.city);
    setPostalCode(userUpdateId?.pinCode);
    setAddress(userUpdateId?.address);
    setId(userUpdateId?.id);
  }, [userUpdateId]);

  return (
    <div>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title>{Title}</Offcanvas.Title>
          <div onClick={Hide} className="close_modal" title="Close">
            X
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Username <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Username"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Company Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Phone <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Phone number"
              onChange={(e) => {
                setPhone(e.target.value);
                validatePhoneNumber(e.target.value);
              }}
              value={phone}
            />
            {phoneError && phone && (
              <Form.Text className="fs-10 m-0 text-danger">
                {phoneError}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Email <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Website <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Website"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              State Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter State"
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              City Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Address <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Enter your address"
              style={{ height: "29px" }}
              onChange={(e) => setAddress(e.target.value)}
              value={address}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Pin Code <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="number"
              placeholder="Enter Postel Code"
              onChange={(e) => setPostalCode(e.target.value)}
              value={postalCode}
            />
          </Form.Group>
          <Form.Group className="text-end">
            <button
              onClick={userUpdateId ? updateUserClient : addUserClient}
              className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
            >
              <FaPlusSquare style={{ marginBottom: 2 }} />{" "}
              {userUpdateId ? "Update" : "Add"} Client
            </button>
          </Form.Group>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default AddClientForm;
