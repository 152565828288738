import React, { useState } from "react";
import { Form, InputGroup, Offcanvas } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { FaFloppyDisk } from "react-icons/fa6";
import { authServices } from "../../../../../APIServices/authServices";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const AddUpdateConfigMetadata = ({
  Show,
  Hide,
  Title,
  visitConfigId,
  getVisitColumnData,
  visitColumnData,
}) => {
  const [columns, setColumnName] = useState([
    { label: "", name: "", type: "" },
  ]);
  const [loader, setLoader] = useState(false);
  const { studyId } = useParams();

  const addVisitConfigMetadata = async () => {
    setLoader(true);
    let userData = {};
    userData.data = columns;
    userData.studyId = studyId;
    userData.type = "VISIT";
    let data = await authServices.addConfigMetadata(userData, visitConfigId);
    if (data?.status_code === 200) {
      getVisitColumnData();
      setLoader(false);
    } else {
      setLoader(false);
    }
    Hide();
  };

  const handleRowChange = (index, field, value) => {
    const updatedRows = [...columns];
    updatedRows[index][field] = value;
    setColumnName(updatedRows);
  };

  const handleAddRow = () => {
    setColumnName([...columns, { name: "", label: "", type: "" }]);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = columns.filter((_, i) => i !== index);
    setColumnName(updatedRows);
  };

  useEffect(() => {
    setColumnName(visitColumnData[0]?.data);
  }, [visitColumnData]);

  return (
    <div>
      <Offcanvas
        show={Show}
        onHide={Hide}
        placement="end"
        style={{ width: 550 }}
      >
        <Offcanvas.Header>
          <Offcanvas.Title>{Title}</Offcanvas.Title>
          <div onClick={Hide} className="close_modal" title="Close">
            X
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="overflow-auto py-2 px-3"
            style={{ height: "calc(100vh - 84px)" }}
          >
            <div className="mb-3">
              {columns?.map((row, index) => (
                <InputGroup className="mb-2">
                  <Form.Control
                    type="text"
                    placeholder="Enter Label"
                    value={row.label}
                    onChange={(e) =>
                      handleRowChange(index, "label", e.target.value)
                    }
                  />
                  <Form.Control
                    type="text"
                    placeholder="Enter Name"
                    value={row.name}
                    onChange={(e) =>
                      handleRowChange(index, "name", e.target.value)
                    }
                  />
                  <Form.Select
                    onChange={(e) =>
                      handleRowChange(index, "type", e.target.value)
                    }
                  >
                    <option value="">Select Type</option>
                    <option selected={row?.type === "Text"} value="Text">
                      Text
                    </option>
                    <option selected={row?.type === "Number"} value="Number">
                      Number
                    </option>
                    <option selected={row?.type === "Date"} value="Date">
                      Date
                    </option>
                    <option selected={row?.type === "Time"} value="Time">
                      Time
                    </option>
                  </Form.Select>
                  <InputGroup.Text
                    id="basic-addon1"
                    className="cursor-pointer "
                    title="Delete Visit Config"
                    onClick={() => handleDeleteRow(index)}
                  >
                    <FaTrashAlt
                      className="text-danger"
                      style={{ fontSize: 10 }}
                    />
                  </InputGroup.Text>
                </InputGroup>
              ))}
            </div>

            <div className="text-center">
              <button
                className="w-50 p-1 px-3 border-tertiory border rounded-1 bg-tertiory-subtle fs-13"
                onClick={handleAddRow}
              >
                + Add More
              </button>
            </div>
          </div>
          <Form.Group className="text-end p-2 bg-light">
            <button
              onClick={addVisitConfigMetadata}
              className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
            >
              <FaFloppyDisk style={{ marginBottom: 2 }} /> Save
            </button>
          </Form.Group>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default AddUpdateConfigMetadata;
