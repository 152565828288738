import React, { useEffect, useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { FaPlusSquare } from "react-icons/fa";
import { authServices } from "../../../APIServices/authServices";

const AddAccessManagementForm = ({
  accessMgmtID,
  Show,
  Hide,
  Title,
  accessManagements,
  accessManagementName,
  setAccessManagementName,
  accessManagementDescription,
  setAccessManagementDescription
}) => {
  const [id, setId] = useState("");

  const addAccessManagemnt = async () => {
    let userData = {};
    userData.name = accessManagementName;
    userData.description = accessManagementDescription;
    await authServices.addAccessManagemnt(userData);
    accessManagements();
    Hide();
  };
  
  const updateAccessManagemnt = async () => {
    let userData = {};
    userData.accessGroupId = id;
    userData.name = accessManagementName;
    userData.description = accessManagementDescription;
    await authServices.updateAccessManagemnt(userData, id);
    accessManagements();
    Hide();
  };

  useEffect(() => {
    setAccessManagementName(accessMgmtID?.name);
    setAccessManagementDescription(accessMgmtID?.description);
    setId(accessMgmtID?.id);
  }, [accessMgmtID]);

  return (
    <div>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title>{Title}</Offcanvas.Title>
          <div onClick={Hide} className="close_modal" title="Close">
            X
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Access Management Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Name"
              value={accessManagementName}
              onChange={(e) => setAccessManagementName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Access Management Description{" "}
              <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Enter Description"
              value={accessManagementDescription}
              onChange={(e) => setAccessManagementDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="text-end">
            <button
              className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
              onClick={accessMgmtID ? updateAccessManagemnt : addAccessManagemnt}
            >
              <FaPlusSquare style={{ marginBottom: 2 }} />{" "}
              {!accessMgmtID ? "Add" : "Update"} Access Management
            </button>
          </Form.Group>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default AddAccessManagementForm;
