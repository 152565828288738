import React, { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { BiFilterAlt } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import UseToggle from "../../../../../Hooks/UseToggle";
import AddUpdateConfigMetadata from "./AddUpdateConfigMetadata";
import AddUpdateVisitMetadata from "./AddUpdateVisitMetadata";
import { useParams } from "react-router-dom";
import { authServices } from "../../../../../APIServices/authServices";
import Swal from "sweetalert2";
import Pagination from "../../../../../Hooks/Pagination";

const VisitMetadata = ({ onMouseDown, tableRef }) => {
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [loading, setLoading] = useState(false);
  const [visitConfigId, setVisitConfigId] = useState("");
  const [visitColumnData, setVisitColumnData] = useState([]);
  const [visitRowData, setVisitRowData] = useState([]);
  const [updateId, setUpdateId] = useState("");
  const [id, setId] = useState("");
  const { studyId } = useParams();

  const getVisitColumnData = async () => {
    setLoading(true);
    let data = await authServices.getFormEntryBySite("VISIT", studyId);
    setVisitColumnData(data || []);
    setLoading(false);
  };

  const getVisitRowData = async () => {
    setLoading(true);
    let data = await authServices.getStudyManageDetails(studyId, "VISIT");
    setVisitRowData(data || []);
    setLoading(false);
  };

  const handleUpdateClick = (item) => {
    setUpdateId(item);
    setId(item?.id);
    setToggle1();
  };

  const handleAddVisitConfigClick = () => {
    const newId = result[0]?.id;
    setVisitConfigId(newId);
    setToggle();
  };

  const deleteVisitMetaData = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete This MetaData!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await authServices.deleteMetaData(studyId, id);
        getVisitRowData();
      }
    });
  };

  useEffect(() => {
    getVisitColumnData();
    getVisitRowData();
  }, [studyId]);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = visitRowData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(visitRowData?.length / itemsPerPage);

  return (
    <>
      <div
        className="hstack justify-content-between w-100 border-bottom"
        style={{ padding: "4.6px 9px" }}
      >
        <div className="hstack gap-2">
          <button
            title="Visit Config"
            className="tabButtons border rounded-1 fs-10"
            style={{ padding: "3px 10px" }}
            onClick={handleAddVisitConfigClick}
          >
            Add Visit Config
          </button>
          <div className="vr"></div>
          <button
            title="Visit Config"
            className="tabButtons border rounded-1 fs-10"
            style={{ padding: "3px 10px" }}
            onClick={() => {
              setToggle1();
              setUpdateId("");
              setId("");
            }}
          >
            Add New Visit
          </button>
          <div className="vr"></div>
          <button
            title="Filter"
            className="tabButtons border rounded-1 fs-13"
            style={{ padding: "1px 5px" }}
          >
            <BiFilterAlt />
          </button>
        </div>
      </div>
      {loading ? (
        <div
          className="hstack justify-content-center"
          style={{ height: "calc(100vh - 156px)" }}
        >
          <Spinner />
        </div>
      ) : (
        <div
          className="position-relative px-2 mt-2 overflow-auto"
          style={{ height: "calc(100vh - 155px)" }}
        >
          <Table
            tableRef={tableRef}
            id="resizable-table"
            className="text-nowrap mb-1 custom-table"
            hover
            bordered
            striped
          >
            {visitColumnData?.length === 0 ? (
              <div
                className="hstack justify-content-center"
                style={{ height: "calc(100vh - 156px)" }}
              >
                Visit Not Found!
              </div>
            ) : (
              <thead className="position-sticky z-1" style={{ top: -1 }}>
                <tr>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 0)}
                    style={{ width: 40 }}
                  >
                    Sr.
                  </th>
                  {visitColumnData[0]?.data?.map((header, index) => (
                    <th key={index} onMouseDown={(e) => onMouseDown(e, 1)}>
                      {header?.label}
                    </th>
                  ))}
                  <th
                    onMouseDown={(e) => onMouseDown(e, 2)}
                    style={{ width: 80 }}
                  >
                    <div className="text-center">Edit</div>
                  </th>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 3)}
                    style={{ width: 80 }}
                  >
                    <div className="text-center">Remove</div>
                  </th>
                </tr>
              </thead>
            )}
            <tbody>
              {result?.map((item, index) => (
                <tr key={item.id} className="position-relative cursor-pointer">
                  <td>{index + 1}</td>
                  {Object.keys(item.data).map((header, i) => (
                    <td key={i}>{item.data[header]}</td>
                  ))}

                  <td
                    className="text-center"
                    onClick={() => handleUpdateClick(item)}
                  >
                    <div title="Update">
                      <FiEdit className="text-primary" />
                    </div>
                  </td>
                  <td
                    className="text-center cursor-pointer"
                    onClick={() => deleteVisitMetaData(item.id)}
                    title="Delete Visit"
                  >
                    <i className="fa-solid fa-trash-can CP red text-danger"></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="position-sticky bottom-0">
            <Pagination
              totalPage={totalPage}
              pageNumber={pageNumber}
              itemsPerPage={itemsPerPage}
              totalItems={visitRowData?.length}
              setPageNumber={setPageNumber}
              setItemsPerPage={setItemsPerPage}
            />
          </div>
        </div>
      )}
      <AddUpdateConfigMetadata
        Show={!toggle}
        Hide={setToggle}
        visitConfigId={visitConfigId}
        visitColumnData={visitColumnData}
        getVisitColumnData={getVisitColumnData}
        Title={"Add Visit Config"}
      />

      <AddUpdateVisitMetadata
        Show={!toggle1}
        Hide={setToggle1}
        Title={`${updateId ? "Update" : "Add"} Visit`}
        getVisitRowData={getVisitRowData}
        visitColumnData={visitColumnData}
        id={id}
        updateId={updateId}
      />
    </>
  );
};

export default VisitMetadata;
