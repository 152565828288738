import React, { useRef, useState } from "react";
import { authServices } from "../../../APIServices/authServices";
import { Alert, Form, Spinner } from "react-bootstrap";

const TwoFactorAuthByEmail = ({
  emailTwoFA,
  setNextBy,
  getTwoFactorAuthType,
}) => {
  const [OTPSent, setOTPSent] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const otpInputs = useRef([]);

  const handleInputChange = (index, e) => {
    const value = e.target.value;
    if (value === "" || /^[0-9]$/.test(value)) {
      const updatedValues = [...otpValues];
      updatedValues[index] = value;
      setOtpValues(updatedValues);

      if (value !== "" && index < 5) {
        otpInputs.current[index + 1].focus();
      }

      if (value === "" && index > 0) {
        otpInputs.current[index - 1].focus();
      }
    }
  };

  const sendOTPForEmailTwoFa = async () => {
    let data = await authServices.emailOTPFor2FA();
    if (data?.statusCode === 200) {
      setOTPSent(true);
    }
  };

  const enableTwoFactorAuthByEmail = async () => {
    setLoader(true);
    let userData = {};
    userData.otp = otpValues;
    let data = await authServices.enableTwoFactorAuthByEmail(userData);
    setStatusMessage(data);
    setLoader(false);
    if (data?.statusCode === 200) {
      getTwoFactorAuthType();
      setOTPSent(false);
      setOtpValues(["", "", "", "", "", ""]);
    }
    setTimeout(() => {
      setStatusMessage("");
    }, 3000);
  };

  const disableTwoFactorAuthByEmail = async () => {
    setLoader(true);
    let userData = {};
    userData.otp = otpValues;
    let data = await authServices.disableTwoFactorAuthByEmail(userData);
    setStatusMessage(data);
    setLoader(false);
    if (data?.statusCode === 200) {
      getTwoFactorAuthType();
      setOTPSent(false);
      setNextBy(true);
      setOtpValues(["", "", "", "", "", ""]);
    }
    setTimeout(() => {
      setStatusMessage("");
    }, 3000);
  };
  return (
    <>
      {!emailTwoFA ? (
        <>
          {!OTPSent ? (
            <>
              <div className="qr__imag mb-3">
                <div className="security-shield">
                  <i className="fa-solid fa-shield-halved"></i>
                  <p className="m-0">
                    <b>Disabled</b>
                  </p>
                </div>
              </div>
              <p style={{ fontSize: 12, textAlign: "center" }}>
                To enable Email 2FA, send OTP to the registered email address.
                If you click on Send OTP button, OTP will be automatically sent
                to your registered email address.
              </p>
              <div className="hstack gap-3 justify-content-end pt-2">
                <button
                  className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
                  onClick={() => setNextBy(false)}
                >
                  Back
                </button>
                <button
                  className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
                  onClick={() => sendOTPForEmailTwoFa()}
                >
                  {"Send OTP"}
                </button>
              </div>
            </>
          ) : (
            <>
              <center>
                <Form.Label className="mb-0 fw-bold">Enter OTP</Form.Label>
              </center>
              <div className="otp-input-fields hstack gap-2 py-3 justify-content-center">
                {otpValues.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    pattern="\d*"
                    maxLength="1"
                    className={`border text-center otp__digit otp__field__${
                      index + 1
                    }`}
                    style={{ height: 35, width: 35 }}
                    value={value}
                    onChange={(e) => handleInputChange(index, e)}
                    ref={(input) => (otpInputs.current[index] = input)}
                  />
                ))}
              </div>
              <div className="hstack gap-2 justify-content-between">
                <button
                  className="w-100 p-1 px-3 border rounded-1 text-white bg-color fs-13"
                  onClick={() => {
                    setOTPSent(false);
                    setOtpValues(["", "", "", "", "", ""]);
                  }}
                >
                  Back
                </button>
                <button
                  className="w-100 p-1 px-3 border rounded-1 text-white bg-color fs-13"
                  onClick={() => enableTwoFactorAuthByEmail()}
                >
                  {loader && <Spinner style={{ width: 14, height: 14 }} />}{" "}
                  Enable
                </button>
              </div>
              {statusMessage && (
                <Alert
                  className="fs-10 py-1 px-1 m-0 text-center mt-2"
                  variant={
                    statusMessage?.statusCode === 200 ? "success" : "danger"
                  }
                >
                  {statusMessage?.customMessage}
                </Alert>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {!OTPSent ? (
            <>
              <div className="qr__imag">
                <div className="security-shield">
                  <i className="fa-solid fa-shield-halved"></i>
                  <p className="m-0">
                    <b>Enabled (2FA by Email)</b>
                  </p>
                </div>
              </div>
              <div className="hstack gap-3 justify-content-center pt-3">
                <button
                  className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
                  onClick={() => sendOTPForEmailTwoFa()}
                >
                  Disable
                </button>
              </div>
            </>
          ) : (
            <>
              <center>
                <Form.Label className="mb-0 fw-bold">Enter OTP</Form.Label>
              </center>
              <div className="otp-input-fields hstack gap-2 py-3 justify-content-center">
                {otpValues.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    pattern="\d*"
                    maxLength="1"
                    className={`border text-center otp__digit otp__field__${
                      index + 1
                    }`}
                    style={{ height: 35, width: 35 }}
                    value={value}
                    onChange={(e) => handleInputChange(index, e)}
                    ref={(input) => (otpInputs.current[index] = input)}
                  />
                ))}
              </div>
              <div className="hstack gap-3 justify-content-end pt-2">
                <button
                  className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
                  onClick={() => {
                    setOTPSent(false);
                  }}
                >
                  Back
                </button>
                <button
                  className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
                  onClick={() => disableTwoFactorAuthByEmail()}
                >
                  {loader && <Spinner style={{ width: 14, height: 14 }} />}{" "}
                  Disable 2FA
                </button>
              </div>
              {statusMessage && (
                <Alert
                  className="fs-10 py-1 px-1 m-0 text-center mt-2"
                  variant={
                    statusMessage?.statusCode === 200 ? "success" : "danger"
                  }
                >
                  {statusMessage?.customMessage}
                </Alert>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default TwoFactorAuthByEmail;
