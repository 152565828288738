import React, { useState } from "react";
import Moment from "react-moment";
import { Form, Table } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import { FaRegEye } from "react-icons/fa";
import { authServices } from "../../../../APIServices/authServices";
import Pagination from "../../../../Hooks/Pagination";

const ClientTableData = ({
  onMouseDown,
  tableRef,
  setToggle1,
  setToggle,
  handleAddNewTab,
  userList,
  getUserListByType,
  setUserUpdateId,
}) => {
  const deleteUserById = async (id) => {
    await authServices.deleteUserById(id);
    getUserListByType();
  };

  const viewClientData = (item) => {
    setUserUpdateId(item);
    setToggle1();
  };

  const viewProject = (item) => {
    handleAddNewTab(
      `${item?.firstName} Project `,
      "fa-solid fa-circle-nodes",
      `projectId_${item?.id}`,
      { id: item?.id }
    );
  };

  const updateClientData = (item) => {
    setUserUpdateId(item);
    setToggle();
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = userList?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(userList?.length / itemsPerPage);

  return (
    <>
      <Table
        tableRef={tableRef}
        id="resizable-table"
        className="text-nowrap mb-1 custom-table"
        hover
        bordered
        striped
      >
        <thead className="position-sticky z-1" style={{ top: -1 }}>
          <tr>
            <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>
              Sr.
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 1)}>Username</th>
            <th onMouseDown={(e) => onMouseDown(e, 2)}>Company Name</th>
            <th onMouseDown={(e) => onMouseDown(e, 3)}>Email</th>
            <th onMouseDown={(e) => onMouseDown(e, 4)}>Phone Number</th>
            <th onMouseDown={(e) => onMouseDown(e, 5)}>Status</th>
            <th onMouseDown={(e) => onMouseDown(e, 6)}>Added Date | Time</th>
            <th onMouseDown={(e) => onMouseDown(e, 7)} style={{ width: 80 }}>
              <div className="text-center">Project</div>
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 8)} style={{ width: 80 }}>
              <div className="text-center">Update</div>
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 9)} style={{ width: 60 }}>
              <div className="text-center">Remove</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {result?.map((item, index) => (
            <tr key={index} className={`position-relative cursor-pointer `}>
              <td onClick={() => viewClientData(item)}>
                {index + startIndex + 1}
              </td>
              <td onClick={() => viewClientData(item)}>
                {item?.clientUserName}
              </td>
              <td onClick={() => viewClientData(item)}>{item?.firstName}</td>
              <td onClick={() => viewClientData(item)}>{item?.email}</td>
              <td onClick={() => viewClientData(item)}>{item?.phoneNumber}</td>
              <td className="p-0">
                <Form.Select
                  className={`border-0 rounded-0 bg-transparent `}
                  style={{ fontSize: 12 }}
                  value={item?.status}
                  disabled
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </Form.Select>
              </td>
              <td onClick={() => viewClientData(item)}>
                <Moment format="DD MMM YYYY | HH:mm:ss">
                  {item?.createdDate}
                </Moment>
              </td>
              <td className="text-center">
                <div title="View Project" onClick={() => viewProject(item)}>
                  <FaRegEye className="text-primary" />
                </div>
              </td>
              <td className="text-center">
                <div title="Update" onClick={() => updateClientData(item)}>
                  <FiEdit className="text-primary" />
                </div>
              </td>
              <td>
                <div
                  onClick={(e) => deleteUserById(item?.id)}
                  className="text-center cursor-pointer"
                  title="Delete Client"
                >
                  <i className={`fa-solid fa-trash-can CP red text-danger`}></i>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={userList?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default ClientTableData;
