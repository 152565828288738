import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateForm";
import { CiExport } from "react-icons/ci";
import FormTableData from "./FormTableData";
import { AiOutlinePlus } from "react-icons/ai";
import UseToggle from "../../../Hooks/UseToggle";
import { authServices } from "../../../APIServices/authServices";
import { useParams } from "react-router-dom";

const FormManagementData = ({ onMouseDown, tableRef, handleAddNewTab }) => {
  const { toggle, setToggle } = UseToggle();
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [formName, setFormName] = useState("");
  const [domainClass, setDoaminClass] = useState("");
  const [domainDescription, setDomainDescription] = useState("");
  const [domainCode, setDomainCode] = useState("");
  const [logForm, setLogForm] = useState(false);
  const [projectID, setProjectID] = useState("");
  const [specialForm, setSpecialForm] = useState(false);
  const [selectForm, setSelectForm] = useState("");
  const [formData, setFormData] = useState("");
  const [formID, setFormID] = useState("");
  const [updateFormData, setUpdateFormData] = useState("");
  const [selectedFormName, setSelectedFormName] = useState("");
  const { studyId } = useParams();

  const getFormData = async () => {
    setLoading(true);
    let data = await authServices.getFormData(studyId);
    setFormData(data || []);
    setLoading(false);
  };

  const addForm = async () => {
    setLoader(true);
    let userData = {};
    userData.studyId = studyId;
    userData.name = formName;
    userData.domainClass = domainClass;
    userData.domainDescription = domainDescription;
    userData.domainCode = domainCode;
    userData.logForm = logForm;
    userData.specialForm = specialForm;
    let result = await authServices.addForm(userData);
    if (result?.statusCode === 200) {
      getFormData();
      clearForm();
      setToggle(false);
    }
    if (selectForm) {
      userData.studyId = studyId;
      userData.name = selectedFormName;
      userData.domainClass = domainClass;
      userData.domainCode = domainCode;
      userData.logForm = logForm;
      userData.specialForm = specialForm;
    }
    let results = await authServices.addForm(userData);
    if (results?.statusCode === 200) {
      getFormData();
      clearForm();
      setToggle(false);
      setLoader(false);
    }

  };

  useEffect(() => {
    if (studyId) {
      getFormData();
    }
  }, [studyId]);

  const clearForm = () => {
    setFormName("");
    setDoaminClass("");
    setDomainDescription("");
    setDomainCode("");
    setLogForm("");
    setSpecialForm("");
    setFormID("");
  };

  return (
    <>
      <div className={`code-list-container`}>
        <div
          className="hstack justify-content-between w-100 border-bottom"
          style={{ padding: "4.6px 9px" }}
        >
          <div className="hstack gap-2">
            <button
              title={`Add Form`}
              className="tabButtons border rounded-1 fs-13"
              style={{ padding: "1px 5px" }}
              onClick={() => {
                setToggle();
                setFormID("");
              }}
            >
              <AiOutlinePlus />
            </button>
          </div>
          <button className="tabButtons border rounded-1 fs-13" title="Export">
            <CiExport />
          </button>
        </div>
        <div className="position-relative p-2">
          <FormTableData
            setToggle={setToggle}
            formData={formData}
            setFormID={setFormID}
            setProjectID={setProjectID}
            setUpdateFormData={setUpdateFormData}
            onMouseDown={onMouseDown}
            tableRef={tableRef}
            handleAddNewTab={handleAddNewTab}
          />
        </div>
      </div>
      <AddUpdateForm
        Show={!toggle}
        Hide={setToggle}
        Title={formID ? "Update Form" : "Add Form"}
        formData={formData}
        addForm={addForm}
        loader={loader}
        formID={formID}
        loading={loading}
        formName={formName}
        setFormName={setFormName}
        updateFormData={updateFormData}
        domainClass={domainClass}
        setDoaminClass={setDoaminClass}
        domainDescription={domainDescription}
        setDomainDescription={setDomainDescription}
        domainCode={domainCode}
        setDomainCode={setDomainCode}
        setSelectedFormName={setSelectedFormName}
        selectedFormName={selectedFormName}
        selectForm={selectForm}
        setSelectForm={setSelectForm}
        logForm={logForm}
        setLogForm={setLogForm}
        specialForm={specialForm}
        setSpecialForm={setSpecialForm}
      />
    </>
  );
};

export default FormManagementData;
