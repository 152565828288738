import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import UserDetails from "../UserDetails/UserDetails";
import { ChangePassword, TwoFA } from "../../Popups.js";
import { authServices } from "../../../APIServices/authServices.jsx";

const AccountSettings = ({ userCurrentData, currentUserData }) => {
  const [openSection, setOpenSection] = useState("profile");
  const [profileImage, setProfileImage] = useState("");
  const [emailTwoFA, setEmailTwoFA] = useState(false);
  const [status, setStatus] = useState(false);
  const [appTwoFA, setAppTwoFA] = useState(false);
  const [authType, setAuthType] = useState("");

  const getTwoFactorAuthType = async () => {
    let data = await authServices.getTwoFactorAuthType();
    setAuthType(data?.authType);
    setStatus(data?.status);
    setAppTwoFA(data?.appTwoFA);
    setEmailTwoFA(data?.emailTwoFA);
  };

  useEffect(() => {
    getTwoFactorAuthType();
    setProfileImage(
      userCurrentData?.profileImage && userCurrentData?.profileImage
    );
  }, [userCurrentData]);

  return (
    <Container fluid>
      <Row>
        <Form.Group as={Col} md={3} className="p-3 ">
          <div className="shadow-sm rounded border overflow-auto">
            <div className="p-2 border-bottom text-center bg-light">
              <Form.Label className="fw-bold">Accout Settings</Form.Label>
            </div>
            <div className="p-4">
              <button
                className={`w-100 border-0 bg-secondary-subtle mb-2 rounded-1 p-1 fs-13 ${
                  openSection === "profile" && "active"
                }`}
                onClick={() => setOpenSection("profile")}
              >
                Profile
              </button>
              <button
                className={`w-100 border-0 bg-secondary-subtle mb-2 rounded-1 p-1 fs-13 ${
                  openSection === "twoFA" && "active"
                }`}
                onClick={() => setOpenSection("twoFA")}
              >
                2FA
              </button>
              <button
                className={`w-100 border-0 bg-secondary-subtle mb-2 rounded-1 p-1 fs-13 ${
                  openSection === "changePassword" && "active"
                }`}
                onClick={() => setOpenSection("changePassword")}
              >
                Change Password
              </button>
            </div>
          </div>
        </Form.Group>
        <Form.Group as={Col} md={9} className="p-0">
          <div
            className="overflow-auto"
            style={{ height: "calc(100vh - 115px)" }}
          >
            {openSection === "profile" ? (
              <UserDetails
                userCurrentData={userCurrentData}
                currentUserData={currentUserData}
              />
            ) : openSection === "twoFA" ? (
              <TwoFA
                getTwoFactorAuthType={getTwoFactorAuthType}
                setAuthType={setAuthType}
                authType={authType}
                status={status}
                appTwoFA={appTwoFA}
                emailTwoFA={emailTwoFA}
              />
            ) : openSection === "changePassword" ? (
              <ChangePassword />
            ) : (
              ""
            )}
          </div>
        </Form.Group>
      </Row>
    </Container>
  );
};

export default AccountSettings;
