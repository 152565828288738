import React, { useEffect, useState } from "react";
import ProjectTable from "./ProjectTable";
import { authServices } from "../../../../../APIServices/authServices";

const ProjectStudyList = ({ onMouseDown, tableRef, sponsorId }) => {
  const [loader, setLoader] = useState(false);
  const [projectList, setProjectList] = useState([]);

  const getProjectData = async () => {
    setLoader(true);
    let data = await authServices.getProjectData(sponsorId);
    setProjectList(data);
    setLoader(false);
  };

  useEffect(() => {
    if (sponsorId) {
      getProjectData();
    }
  }, [sponsorId]);

  return (
    <>
      <ProjectTable
        loader={loader}
        sponsorId={sponsorId}
        projectList={projectList}
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        getProjectData={getProjectData}
      />
    </>
  );
};

export default ProjectStudyList;
