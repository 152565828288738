import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const CoverageChart = () => {
  const [chartData] = useState({
    series: [70],
    options: {
      chart: {
        height: 150,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
          track: {
            background: "#e7e7e7",
            strokeWidth: "100%",
          },
          dataLabels: {
            name: {
              color: "#333", 
              fontSize: "12px",
            },
            value: {
              color: "#111",
              fontSize: "10px",
            },
          },
        },
      },
      stroke: {
        lineCap: "round",
        width: 5, 
      },
      colors: ["#172b73"],
      labels: ["Complete"],
    },
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="radialBar"
          height={150}
        />
      </div>
    </div>
  );
};

export default CoverageChart;
