import React, { useState } from "react";
import Toolbar from "./Toolbar/Toolbar";
import { Col, Container, Row } from "react-bootstrap";
import EditorMainPage from "./EditorMainpage/EditorMainPage";

const FormEditor = () => {
  const [formData, setFormData] = useState([])
  const handleAddNewField = (type) => {
    setFormData([
      ...formData,
      {
        id: Math.random().toString().substr(2, 12),
        type: type,
      },
    ]);
  };

  const handleRemoveNewField = (id) => {
    const updatedSections = formData?.filter((section) => section.id !== id);
    setFormData(updatedSections);
  };

  const handleUpdateField = (id, field, value) => {
    const updatedSections = formData?.map((section) =>
      section.id === id ? { ...section, [field]: value } : section
    );
    setFormData(updatedSections);
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md={2} className="border-end overflow-auto p-0" style={{ height: "calc(100vh - 115px)" }}>
            <Toolbar
              handleAddNewField={handleAddNewField}
            />
          </Col>
          <Col md={10} className="p-0" >
            <EditorMainPage
              handleRemoveNewField={handleRemoveNewField}
              handleUpdateField={handleUpdateField}
              formData={formData}
              setFormData={setFormData}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default FormEditor;
