import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import Pagination from "../../../Hooks/Pagination";
import { authServices } from "../../../APIServices/authServices";

const UserTableData = ({
  onMouseDown,
  tableRef,
  setToggle,
  accessMgmt,
  setAccessMgmtID,
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = accessMgmt?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(accessMgmt?.length / itemsPerPage);

  const updateAccessManagement = (item) => {
    setAccessMgmtID(item);
    setToggle();
  };

  const deleteAccessManagement = async (id) => {
    let data = await authServices.deleteAccessManagement(id);
  };

  return (
    <>
      <Table
        tableRef={tableRef}
        id="resizable-table"
        className="text-nowrap mb-1 custom-table"
        hover
        bordered
        striped
      >
        <thead className="position-sticky z-1" style={{ top: -1 }}>
          <tr>
            <th onMouseDown={(e) => onMouseDown(e, 1)} style={{ width: 40 }}>
              Sr.
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 2)}>Management name</th>
            <th onMouseDown={(e) => onMouseDown(e, 3)}>Description</th>
            <th onMouseDown={(e) => onMouseDown(e, 4)} style={{ width: 80 }}>
              <div className="text-center">Update</div>
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 5)} style={{ width: 60 }}>
              <div className="text-center">Remove</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {result?.map((item, index) => (
            <tr key={index} className={`position-relative cursor-pointer `}>
              <td>{index + startIndex + 1}</td>
              <td>{item.name}</td>
              <td>{item.description}</td>
              <td className="text-center">
                <div
                  title="Update"
                  onClick={() => updateAccessManagement(item)}
                >
                  <FiEdit className="text-primary" />
                </div>
              </td>
              <td>
                <div
                  className="text-center cursor-pointer"
                  title="Delete User"
                  onClick={() => deleteAccessManagement(item?.id)}
                >
                  <i className={`fa-solid fa-trash-can CP red text-danger`}></i>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={accessMgmt?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default UserTableData;
