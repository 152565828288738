import React, { useEffect, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import { useParams } from "react-router-dom";
import { authServices } from "../../../../APIServices/authServices";
import { Form, Spinner, Table } from "react-bootstrap";

const SpecialForm = () => {
  const [loading, setLoading] = useState(false);
  const [specialFormData, setSpecialFormData] = useState([]);
  const [specialForm, setSpecialForm] = useState([]);
  const [visitSpecialFormData, setVisitSpecialFormData] = useState([]);
  const { studyId } = useParams();

  const getSpecialFormData = async () => {
    setLoading(true);
    let data = await authServices.getSpecialFormData(studyId, "true");
    setSpecialFormData(data || []);
    setLoading(false);
  };

  const addVisitSpecialFormPosition = async () => {
    const userData = {};
    userData.studyId = studyId;
    userData.forms = visitSpecialFormData;
    let data = await authServices.addVisitSpecialFormPosition(userData);
  };

  const handleFormOrderChange = (index, value) => {
    setVisitSpecialFormData((prevData) =>
      prevData.map((item, idx) =>
        idx === index ? { ...item, formOrder: value } : item
      )
    );
  };

  const removeItem = (indexToRemove) => {
    setSpecialForm((prevForm) =>
      prevForm.filter((_, index) => index !== indexToRemove)
    );
  };

  useEffect(() => {
    const mainDataDict = visitSpecialFormData?.reduce((acc, item) => {
      acc[item.id] = item.logForm;
      return acc;
    }, {});
  
    const resultData = specialForm?.map((entry) => ({
      formId: entry.value,
      formName: entry.label,
      logForm: mainDataDict[entry.value] || false,
      formOrder: entry?.formOrder || ""
    }));

    setVisitSpecialFormData(resultData)
  }, [specialFormData, specialForm]);


  useEffect(() => {
    setSpecialFormData(specialFormData)
  }, [specialFormData]);


  useEffect(() => {
    getSpecialFormData();
  }, [studyId]);

  return (
    <>
      <div className={`code-list-container`}>
        <div
          className="w-100 specialForm border-bottom"
          style={{ height: "33px" }}
        >
          <Multiselect
            displayValue="label"
            onKeyPressFn={function noRefCheck() {}}
            onChange={setSpecialForm}
            options={
              specialFormData
                ? specialFormData
                    .filter((item) => item && item.name)
                    .map((item) => ({
                      label: item.name,
                      value: item.name,
                    }))
                : []
            }
            selectedValues={specialForm || []}
            onSelect={(selectedList) => setSpecialForm(selectedList)}
            onRemove={(removedList) => setSpecialForm(removedList)}
          />
        </div>
        <div className="position-relative">
          {loading ? (
            <div
              className="hstack justify-content-center"
              style={{ height: "calc(100vh - 156px)" }}
            >
              <Spinner />
            </div>
          ) : specialForm?.length === 0 ? (
            <div
              className="hstack justify-content-center"
              style={{ height: "calc(100vh - 156px)" }}
            >
              Select for set Position!
            </div>
          ) : (
            <>
              <div
                className="position-relative px-2 mt-2 overflow-auto"
                style={{ height: "calc(100vh - 157px)" }}
              >
                <Table
                  id="resizable-table"
                  className="text-nowrap mb-1 custom-table"
                  hover
                  bordered
                  striped
                >
                  <thead className="position-sticky z-1" style={{ top: -1 }}>
                    <tr>
                      <th style={{ width: 40 }}>Sr.</th>
                      <th>Form Name</th>
                      <th>Position</th>
                      <th style={{ width: 80 }}>
                        <div className="text-center">Remove</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {specialForm?.map((item, index) => (
                      <tr
                        key={index}
                        className="position-relative cursor-pointer"
                      >
                        <td>{index + 1}</td>
                        <td>{item?.label}</td>
                        <td className="p-0">
                          <Form.Control
                            type="number"
                            placeholder="Enter Position"
                            onChange={(e) =>
                              handleFormOrderChange(index, e.target.value)
                            }
                            value={item?.formOrder}
                            className="py-0 border-0 bg-transparent px-2"
                          />
                        </td>
                        <td
                          className="text-center cursor-pointer"
                          title="Remove Form"
                          onClick={() => removeItem(index)}
                        >
                          <i className="fa-solid fa-trash-can CP red text-danger"></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="text-end">
                  <button
                    className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
                    onClick={addVisitSpecialFormPosition}
                  >
                    Save
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SpecialForm;
