import React, { useEffect, useState } from "react";
import { Form, Table, Spinner } from "react-bootstrap";
import { BiFilterAlt } from "react-icons/bi";
import Moment from "react-moment";
import { authServices } from "../../../APIServices/authServices";
import { useParams } from "react-router-dom";
import Pagination from "../../../Hooks/Pagination";

const DataEntry = ({ onMouseDown, tableRef }) => {
  const [loading, setLoading] = useState(false);
  const [formEntryData, setFormEntryData] = useState([]);

  const { studyId } = useParams();

  const getFormEntryBySubject = async () => {
    setLoading(true);
    let data = await authServices.getFormEntryBySite("SUBJECT", studyId);
    setFormEntryData(data || []);
    setLoading(false);
  };

  const getFormEntryBySite = async () => {
    setLoading(true);
    let data = await authServices.getFormEntryBySite("SITE", studyId);
    setFormEntryData(data || []);
    setLoading(false);
  };
  const getFormEntryByvisit = async () => {
    setLoading(true);
    let data = await authServices.getFormEntryBySite("VISIT", studyId);
    setFormEntryData(data || []);
    setLoading(false);
  };

  useEffect(() => {
    getFormEntryBySite();
    getFormEntryBySubject();
    getFormEntryByvisit();
  }, [studyId]);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = formEntryData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(formEntryData?.length / itemsPerPage);

  return (
    <>
      {loading ? (
        <div
          className="hstack justify-content-center"
          style={{ height: "calc(100vh - 156px)" }}
        >
          <Spinner />
        </div>
      ) : result?.length === 0 ? (
        <div
          className="hstack justify-content-center"
          style={{ height: "calc(100vh - 156px)" }}
        >
          Click to view Form Review!
        </div>
      ) : (
        <div
          className="position-relative px-2 mt-2 overflow-auto"
          style={{ height: "calc(100vh - 154px)" }}
        >
          <Table
            ref={tableRef}
            id="resizable-table"
            className="text-nowrap mb-1 custom-table"
            hover
            bordered
            striped
          >
            <thead className="position-sticky z-1" style={{ top: -1 }}>
              <tr>
                <th style={{ width: 40 }}>Sr.</th>
                {formEntryData[0]?.data?.map((item, index) => (
                  <th key={index}>{item.label}</th>
                ))}
                <th>Modified Date | Time</th>
              </tr>
            </thead>
            <tbody>
              {result.map((entry, index) => (
                <tr key={entry.id} className="position-relative cursor-pointer">
                  <td>{startIndex + index + 1}</td>
                  {entry.data.map((item, idx) => (
                    <td key={idx}>{item.name}</td>
                  ))}
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm:ss">
                      {entry.modifiedOn}
                    </Moment>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="position-sticky bottom-0">
            <Pagination
              totalPage={totalPage}
              pageNumber={pageNumber}
              itemsPerPage={itemsPerPage}
              totalItems={formEntryData?.length}
              setPageNumber={setPageNumber}
              setItemsPerPage={setItemsPerPage}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default DataEntry;
