import React, { useEffect, useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { FaPlusSquare } from "react-icons/fa";
import { authServices } from "../../../../../APIServices/authServices";

const AddUpdateProject = ({
  Show,
  Hide,
  Title,
  addUpdateProjectId,
  getProjectData,
  setProjectLabel,
  projectLabel,
  sponsorId,
}) => {
  const [id, setId] = useState("");
  const addProject = async () => {
    let userData = {};
    userData.sponsorId = sponsorId;
    userData.label = projectLabel;
    let result = await authServices.addProject(userData);
    getProjectData();
    clearForm();
    Hide();
  };

  const updateProject = async () => {
    let userData = {};
    userData.sponsorId = sponsorId;
    userData.label = projectLabel;
    let data = await authServices.updateProject(userData, id);
    getProjectData();
    Hide();
  };

  const clearForm = () => {
    setProjectLabel("");
    setId("");
  };

  useEffect(() => {
    setProjectLabel(addUpdateProjectId?.label);
    setId(addUpdateProjectId?.id);
  }, [addUpdateProjectId]);

  return (
    <div>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title>{Title}</Offcanvas.Title>
          <div onClick={Hide} className="close_modal" title="Close">
            X
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Project Label <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Project Label"
              value={projectLabel}
              onChange={(e) => setProjectLabel(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="text-end">
            <button
              onClick={addUpdateProjectId ? updateProject : addProject}
              className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
            >
              <FaPlusSquare style={{ marginBottom: 2 }} />{" "}
              {addUpdateProjectId ? "Update" : "Add"} Project
            </button>
          </Form.Group>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default AddUpdateProject;
