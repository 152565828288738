import React from "react";
import { Card, Form } from "react-bootstrap";
import { BsCalendar2Date } from "react-icons/bs";
import { CgRadioChecked } from "react-icons/cg";
import { GrCheckboxSelected } from "react-icons/gr";
import { RiCheckboxMultipleFill } from "react-icons/ri";
import { LiaHeadingSolid, LiaTapeSolid } from "react-icons/lia";
import {
  MdCloudUpload,
  MdMoreTime,
  MdMovieEdit,
  MdOutlineFormatListNumberedRtl,
  MdOutlineKeyboardHide,
} from "react-icons/md";

const Toolbar = ({ handleAddNewField }) => {
  return (
    <div className="p-3">
      <Card className="border">
        <Card.Body className="p-0">
          <div className="bg-light p-3 text-center border-bottom fw-bold">TOOLS</div>
          <Form.Group onClick={() => handleAddNewField("heading")} className="hstack gap-2 border-bottom py-2 px-3 cursor-pointer">
            <LiaHeadingSolid />
            <Form.Label className="m-0 cursor-pointer">Heading</Form.Label>
          </Form.Group>
          <Form.Group onClick={() => handleAddNewField("date")} className="hstack gap-2 border-bottom py-2 px-3 cursor-pointer">
            <BsCalendar2Date />
            <Form.Label className="m-0 cursor-pointer">Date</Form.Label>
          </Form.Group>
          <Form.Group onClick={() => handleAddNewField("time")} className="hstack gap-2 border-bottom py-2 px-3 cursor-pointer">
            <MdMoreTime />
            <Form.Label className="m-0 cursor-pointer">Time</Form.Label>
          </Form.Group>
          <Form.Group onClick={() => handleAddNewField("text")} className="hstack gap-2 border-bottom py-2 px-3 cursor-pointer">
            <MdOutlineKeyboardHide />
            <Form.Label className="m-0 cursor-pointer">Text Input [short]</Form.Label>
          </Form.Group>
          <Form.Group onClick={() => handleAddNewField("number")} className="hstack gap-2 border-bottom py-2 px-3 cursor-pointer">
            <MdOutlineFormatListNumberedRtl />
            <Form.Label className="m-0 cursor-pointer">Number Input</Form.Label>
          </Form.Group>
          <Form.Group onClick={() => handleAddNewField("textarea")} className="hstack gap-2 border-bottom py-2 px-3 cursor-pointer">
            <MdMovieEdit />
            <Form.Label className="m-0 cursor-pointer">Text Area [long]</Form.Label>
          </Form.Group>
          <Form.Group onClick={() => handleAddNewField("checkbox")} className="hstack gap-2 border-bottom py-2 px-3 cursor-pointer">
            <RiCheckboxMultipleFill />
            <Form.Label className="m-0 cursor-pointer">Checkbox</Form.Label>
          </Form.Group>
          <Form.Group onClick={() => handleAddNewField("radio")} className="hstack gap-2 border-bottom py-2 px-3 cursor-pointer">
            <CgRadioChecked />
            <Form.Label className="m-0 cursor-pointer">Radio</Form.Label>
          </Form.Group>
          <Form.Group onClick={() => handleAddNewField("select")} className="hstack gap-2 border-bottom py-2 px-3 cursor-pointer">
            <GrCheckboxSelected />
            <Form.Label className="m-0 cursor-pointer">Select</Form.Label>
          </Form.Group>
          <Form.Group onClick={() => handleAddNewField("upload")} className="hstack gap-2 border-bottom py-2 px-3 cursor-pointer">
            <MdCloudUpload />
            <Form.Label className="m-0 cursor-pointer">Upload File</Form.Label>
          </Form.Group>
          <Form.Group onClick={() => handleAddNewField("scale")} className="hstack gap-2 py-2 px-3 cursor-pointer">
            <LiaTapeSolid />
            <Form.Label className="m-0 cursor-pointer">Scale</Form.Label>
          </Form.Group>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Toolbar;
