import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import {
  BiCommentCheck,
  BiCommentDetail,
  BiCommentError,
  BiFilterAlt,
} from "react-icons/bi";
import { authServices } from "../../../APIServices/authServices";
import { useParams } from "react-router-dom";
import QueryTable from "./QueryTable";

const QueryManagement = ({ onMouseDown, tableRef }) => {
  const [staticData, setStaticData] = useState([]);
  const [queryData, setQueryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { studyId } = useParams();

  const getFormquarystatic = async () => {
    setLoading(true);
    let data = await authServices.getFormquarystatic(studyId);
    setStaticData(data || []);
    setLoading(false);
  };

  const getFormquaryquery = async () => {
    setLoading(true);
    let data = await authServices.getFormquaryquery(studyId);
    setQueryData(data || []);
    setLoading(false);
  };

  useEffect(() => {
    getFormquarystatic();
    getFormquaryquery();
  }, [studyId]);
  return (
    <>
      <div className={`code-list-container`}>
        <div className="position-relative p-2 border-bottom">
          <Container fluid>
            <Row>
              <Form.Group as={Col} md={4} sm={12} className="p-0">
                <Card className="bg-light border">
                  <Card.Body>
                    <div className="d-flex gap-3">
                      <div className="study_icopn">
                        <BiCommentDetail className="rounded-1 bg-white shadow-sm " />
                      </div>
                      <div>
                        <Card.Title style={{ fontSize: 20, margin: 0 }}>
                          {staticData?.totalCount || "0"}
                        </Card.Title>
                        <Card.Text style={{ fontSize: 13 }}>
                          Total Query
                        </Card.Text>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Form.Group>
              <Form.Group as={Col} md={4} sm={12}>
                <Card className="bg-light border">
                  <Card.Body>
                    <div className="d-flex gap-3">
                      <div className="study_icopn">
                        <BiCommentCheck className="rounded-1 bg-white shadow-sm " />
                      </div>
                      <div>
                        <Card.Title style={{ fontSize: 20, margin: 0 }}>
                          {staticData?.assignedCount || "0"}
                        </Card.Title>
                        <Card.Text style={{ fontSize: 13 }}>
                          Assigned Query
                        </Card.Text>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Form.Group>
              <Form.Group as={Col} md={4} sm={12} className="p-0">
                <Card className="bg-light border">
                  <Card.Body>
                    <div className="d-flex gap-3">
                      <div className="study_icopn">
                        <BiCommentError className="rounded-1 bg-white shadow-sm " />
                      </div>
                      <div>
                        <Card.Title style={{ fontSize: 20, margin: 0 }}>
                          {staticData?.unAssignedCount || "0"}
                        </Card.Title>
                        <Card.Text style={{ fontSize: 13 }}>
                          UnAssigned Query
                        </Card.Text>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Form.Group>
            </Row>
          </Container>
        </div>
      </div>
      <div className={`code-list-container`}>
        <div
          className="hstack justify-content-between w-100 border-bottom"
          style={{ padding: "4.6px 9px" }}
        >
          <div className="hstack gap-2">
            <button
              title="Filter"
              className="tabButtons border rounded-1 fs-13"
              style={{ padding: "1px 5px" }}
            >
              <BiFilterAlt />
            </button>
          </div>
        </div>
        <QueryTable
          queryData={queryData}
          tableRef={tableRef}
          onMouseDown={onMouseDown}
          loading={loading}
          getFormquaryquery={getFormquaryquery}
        />
      </div>
    </>
  );
};

export default QueryManagement;
