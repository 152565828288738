import React from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { FaRegCircleXmark } from "react-icons/fa6";
import { IoMdCheckboxOutline } from "react-icons/io";
import { MdOutlineComment } from "react-icons/md";
import CoverageChart from "./CoverageChart";
import FormReviewQuery from "./FormReviewQuery";
import UseToggle from "../../../Hooks/UseToggle";

const FormReviewData = () => {
  const { toggle, setToggle } = UseToggle();

  return (
    <>
      <div className="p-2">
        <div className={`code-list-container w-75 m-auto border rounded-1`}>
          <Container>
            <Row>
              <Form.Group
                as={Col}
                md={8}
                className="border-end overflow-auto"
                style={{ height: "calc(100vh - 173px)" }}
              >
                {/* Text  */}
                <div className="py-2 pt-4">
                  <div
                    className="p-3 rounded-1 position-relative"
                    style={{ border: "1px dashed #dee2e6" }}
                  >
                    <div
                      className="position-absolute rounded-1"
                      style={{ right: 15, top: -15 }}
                    >
                      <InputGroup>
                        <InputGroup.Text className="py-1 px-3 fs-13 cursor-pointer bg-secondary-subtle" onClick={setToggle}>
                          <MdOutlineComment className="me-2" /> Query
                        </InputGroup.Text>
                        <InputGroup.Text className="py-1 px-3 fs-13 cursor-pointer bg-success-subtle">
                          <IoMdCheckboxOutline className="me-2" /> Accept
                        </InputGroup.Text>
                        <InputGroup.Text className="py-1 px-3 fs-13 cursor-pointer bg-danger-subtle">
                          <FaRegCircleXmark className="me-2" /> Reject
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    <div className="hstack gap-2">
                      <Form.Group className="mb-2 w-100">
                        <Form.Label className="mb-1 fw-bold">
                          Text Label | Text Name
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Label" />
                      </Form.Group>
                      <Form.Group className="mb-2 w-100">
                        <Form.Label className="mb-1 fw-bold">Unit</Form.Label>
                        <Form.Control type="text" placeholder="Enter Label" />
                      </Form.Group>
                    </div>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 1
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Label" />
                    </Form.Group>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 2
                      </Form.Label>
                      <Form.Control type="number" placeholder="Enter Number" />
                    </Form.Group>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 3
                      </Form.Label>
                      <Form.Control type="date" />
                    </Form.Group>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 4
                      </Form.Label>
                      <Form.Control type="time" />
                    </Form.Group>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 5
                      </Form.Label>
                      <Form.Control type="datetime-local" />
                    </Form.Group>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 6
                      </Form.Label>
                      <Form.Check type="checkbox" label="Male" />
                      <Form.Check type="checkbox" label="Female" />
                    </Form.Group>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 6
                      </Form.Label>
                      <Form.Check type="radio" name="radioGroup" label="Male" />
                      <Form.Check
                        type="radio"
                        name="radioGroup"
                        label="Female"
                      />
                    </Form.Group>
                  </div>
                </div>

                {/* Number  */}
                <div className="py-2 pt-4">
                  <div
                    className="p-3 rounded-1 position-relative"
                    style={{ border: "1px dashed #dee2e6" }}
                  >
                    <div
                      className="position-absolute rounded-1"
                      style={{ right: 15, top: -15 }}
                    >
                      <InputGroup>
                        <InputGroup.Text className="py-1 px-3 fs-13 cursor-pointer bg-secondary-subtle" onClick={setToggle}>
                          <MdOutlineComment className="me-2" /> Query
                        </InputGroup.Text>
                        <InputGroup.Text className="py-1 px-3 fs-13 cursor-pointer bg-success-subtle">
                          <IoMdCheckboxOutline className="me-2" /> Accept
                        </InputGroup.Text>
                        <InputGroup.Text className="py-1 px-3 fs-13 cursor-pointer bg-danger-subtle">
                          <FaRegCircleXmark className="me-2" /> Reject
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    <div className="hstack gap-2">
                      <Form.Group className="mb-2 w-100">
                        <Form.Label className="mb-1 fw-bold">
                          Number Label | Number Name
                        </Form.Label>
                        <Form.Control type="number" placeholder="Enter Label" />
                      </Form.Group>
                      <Form.Group className="mb-2 w-100">
                        <Form.Label className="mb-1 fw-bold">Unit</Form.Label>
                        <Form.Control type="text" placeholder="Enter Label" />
                      </Form.Group>
                    </div>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 1
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Label" />
                    </Form.Group>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 2
                      </Form.Label>
                      <Form.Control type="number" placeholder="Enter Number" />
                    </Form.Group>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 3
                      </Form.Label>
                      <Form.Control type="date" />
                    </Form.Group>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 4
                      </Form.Label>
                      <Form.Control type="time" />
                    </Form.Group>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 5
                      </Form.Label>
                      <Form.Control type="datetime-local" />
                    </Form.Group>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 6
                      </Form.Label>
                      <Form.Check type="checkbox" label="Male" />
                      <Form.Check type="checkbox" label="Female" />
                    </Form.Group>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 6
                      </Form.Label>
                      <Form.Check type="radio" name="radioGroup" label="Male" />
                      <Form.Check
                        type="radio"
                        name="radioGroup"
                        label="Female"
                      />
                    </Form.Group>
                  </div>
                </div>

                {/* Date  */}
                <div className="py-2 pt-4">
                  <div
                    className="p-3 rounded-1 position-relative"
                    style={{ border: "1px dashed #dee2e6" }}
                  >
                    <div
                      className="position-absolute rounded-1"
                      style={{ right: 15, top: -15 }}
                    >
                      <InputGroup>
                        <InputGroup.Text className="py-1 px-3 fs-13 cursor-pointer bg-secondary-subtle" onClick={setToggle}>
                          <MdOutlineComment className="me-2" /> Query
                        </InputGroup.Text>
                        <InputGroup.Text className="py-1 px-3 fs-13 cursor-pointer bg-success-subtle">
                          <IoMdCheckboxOutline className="me-2" /> Accept
                        </InputGroup.Text>
                        <InputGroup.Text className="py-1 px-3 fs-13 cursor-pointer bg-danger-subtle">
                          <FaRegCircleXmark className="me-2" /> Reject
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    <div className="hstack gap-2">
                      <Form.Group className="mb-2 w-100">
                        <Form.Label className="mb-1 fw-bold">
                          Date Label | Date Name
                        </Form.Label>
                        <Form.Control type="date" />
                      </Form.Group>
                      <Form.Group className="mb-2 w-100">
                        <Form.Label className="mb-1 fw-bold">Unit</Form.Label>
                        <Form.Control type="text" placeholder="Enter Label" />
                      </Form.Group>
                    </div>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 1
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Label" />
                    </Form.Group>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 2
                      </Form.Label>
                      <Form.Control type="number" placeholder="Enter Number" />
                    </Form.Group>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 3
                      </Form.Label>
                      <Form.Control type="date" />
                    </Form.Group>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 4
                      </Form.Label>
                      <Form.Control type="time" />
                    </Form.Group>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 5
                      </Form.Label>
                      <Form.Control type="datetime-local" />
                    </Form.Group>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 6
                      </Form.Label>
                      <Form.Check type="checkbox" label="Male" />
                      <Form.Check type="checkbox" label="Female" />
                    </Form.Group>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 6
                      </Form.Label>
                      <Form.Check type="radio" name="radioGroup" label="Male" />
                      <Form.Check
                        type="radio"
                        name="radioGroup"
                        label="Female"
                      />
                    </Form.Group>
                  </div>
                </div>

                {/* Drop Down  */}
                <div className="py-2 pt-4">
                  <div
                    className="p-3 rounded-1 position-relative"
                    style={{ border: "1px dashed #dee2e6" }}
                  >
                    <div
                      className="position-absolute rounded-1"
                      style={{ right: 15, top: -15 }}
                    >
                      <InputGroup>
                        <InputGroup.Text className="py-1 px-3 fs-13 cursor-pointer bg-secondary-subtle" onClick={setToggle}>
                          <MdOutlineComment className="me-2" /> Query
                        </InputGroup.Text>
                        <InputGroup.Text className="py-1 px-3 fs-13 cursor-pointer bg-success-subtle">
                          <IoMdCheckboxOutline className="me-2" /> Accept
                        </InputGroup.Text>
                        <InputGroup.Text className="py-1 px-3 fs-13 cursor-pointer bg-danger-subtle">
                          <FaRegCircleXmark className="me-2" /> Reject
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    <div className="hstack gap-2">
                      <Form.Group className="mb-2 w-100">
                        <Form.Label className="mb-1 fw-bold">
                          Select Label | Select Name
                        </Form.Label>
                        <Form.Select>
                          <option value="">Select</option>
                          <option value="">Option 1</option>
                          <option value="">Option 1</option>
                          <option value="">Option 1</option>
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="mb-2 w-100">
                        <Form.Label className="mb-1 fw-bold">Unit</Form.Label>
                        <Form.Control type="text" placeholder="Enter Label" />
                      </Form.Group>
                    </div>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 1
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Label" />
                    </Form.Group>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 2
                      </Form.Label>
                      <Form.Control type="number" placeholder="Enter Number" />
                    </Form.Group>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 3
                      </Form.Label>
                      <Form.Control type="date" />
                    </Form.Group>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 4
                      </Form.Label>
                      <Form.Control type="time" />
                    </Form.Group>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 5
                      </Form.Label>
                      <Form.Control type="datetime-local" />
                    </Form.Group>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 6
                      </Form.Label>
                      <Form.Check type="checkbox" label="Male" />
                      <Form.Check type="checkbox" label="Female" />
                    </Form.Group>
                    <Form.Group className="mb-2 w-100">
                      <Form.Label className="mb-1 fw-bold">
                        Additional Field 6
                      </Form.Label>
                      <Form.Check type="radio" name="radioGroup" label="Male" />
                      <Form.Check
                        type="radio"
                        name="radioGroup"
                        label="Female"
                      />
                    </Form.Group>
                  </div>
                </div>
              </Form.Group>
              <Form.Group as={Col} md={4} className="p-0">
                <Form.Group className="border-bottom text-center bg-light p-2">
                  <Form.Label className="fw-bold" style={{ fontSize: 14 }}>
                    Review Coverage
                  </Form.Label>
                </Form.Group>
                <div
                  style={{ overflowX: "hidden", height: "calc(100vh - 220px)" }}
                >
                  <div className="text-center">
                    <CoverageChart />
                  </div>
                  <Form.Group className="text-center p-2">
                    <Form.Label className="fw-bold">
                      Total Target : 100%
                    </Form.Label>
                  </Form.Group>
                </div>
              </Form.Group>
            </Row>
          </Container>
          <div className="p-2 border-top bg-light">
            <div className="hstack gap-2 justify-content-end">
              <button
                class="bg-secondary-subtle fs-13 border"
                style={{ padding: "2px 25px", borderRadius: "3px" }}
              >
                Cancel
              </button>
              <button
                class="bg-danger-subtle fs-13 border"
                style={{ padding: "2px 25px", borderRadius: "3px" }}
              >
                Reject
              </button>
              <button
                class="bg-color fs-13 text-white border"
                style={{ padding: "2px 25px", borderRadius: "3px" }}
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
      <FormReviewQuery
        Show={!toggle}
        Hide={setToggle}
        Title={"Add Query"}
      />
    </>
  );
};

export default FormReviewData;
