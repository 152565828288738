import React, { useRef, useState } from "react";
import { authServices } from "../../../APIServices/authServices";
import { Alert, Form, Spinner } from "react-bootstrap";

const TwoFactorAuthByApps = ({ appTwoFA, setNextBy, getTwoFactorAuthType }) => {
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const otpInputs = useRef([]);
  const [statusMessage, setStatusMessage] = useState("");
  const [loader, setLoader] = useState(false);

  const handleInputChange = (index, e) => {
    const value = e.target.value;
    if (value === "" || /^[0-9]$/.test(value)) {
      const updatedValues = [...otpValues];
      updatedValues[index] = value;
      setOtpValues(updatedValues);

      if (value !== "" && index < 5) {
        otpInputs.current[index + 1].focus();
      }

      if (value === "" && index > 0) {
        otpInputs.current[index - 1].focus();
      }
    }
  };

  const [base64, setBase64] = useState("");
  const [secret, setSecret] = useState("");
  const [check, setCheck] = useState(false);
  const [disable, setDisable] = useState(false);
  const [showQR, setShowQR] = useState(false);

  const generateQRCode = async () => {
    setLoader(true);
    const data = await authServices.generateQRCode();
    setLoader(false);
    setBase64(data.base64);
    setSecret(data.secret);
  };

  const showQRCode = async () => {
    const data = await authServices.showQRCode();
    setBase64(data);
    if (data) {
      setShowQR(true);
    }
  };

  const enableTwoFactorAuth = async () => {
    setLoader(true);
    const userData = { secret, OTP: otpValues };
    const data = await authServices.enableTwoFactorAuth(userData);
    setStatusMessage(data);
    setLoader(false);
    if (data?.statusCode === 200) {
      getTwoFactorAuthType();
      setOtpValues(["", "", "", "", "", ""]);
    }
    setTimeout(() => {
      setStatusMessage("");
    }, 3000);
  };

  const disableTwoFactorAuth = async () => {
    setLoader(true);
    const userData = { secret, otp: otpValues };
    const data = await authServices.disableTwoFactorAuth(userData);
    setStatusMessage(data);
    setLoader(false);
    if (data?.statusCode === 200) {
      getTwoFactorAuthType();
      setDisable(false);
      setShowQR(false);
      setCheck(false);
      setNextBy(true);
      setOtpValues(["", "", "", "", "", ""]);
      setBase64("");
      setSecret("");
    }
    setTimeout(() => {
      setStatusMessage("");
    }, 3000);
  };

  const getQRDataForDisable = async () => {
    const data = await authServices.getQRData();
    setSecret(data);
    setDisable(true);
  };

  return (
    <>
      {!disable ? (
        <>
          {!appTwoFA ? (
            <>
              {!check ? (
                <>
                  {base64 ? (
                    <div className="qr__imag">
                      <img
                        style={{ color: "red" }}
                        src={base64}
                        alt="QR Code"
                      />
                    </div>
                  ) : (
                    <>
                      <div className="qr__imag mb-3">
                        <div className="security-shield">
                          <i className="fa-solid fa-unlock mb-2"></i>
                          <p className="m-0">
                            <b>Disabled</b>
                          </p>
                        </div>
                      </div>
                      <p style={{ fontSize: 12, textAlign: "center" }}>
                        To enable Apps 2FA, generate QR. After generating the
                        QR, you have to scan the QR with the Authenticator app,
                        after that the OTP will be automatically sent to your
                        device.
                      </p>
                    </>
                  )}

                  {base64 ? (
                    <div className="hstack gap-3 justify-content-end pt-3 w-full">
                      <button
                        className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
                        onClick={() => setBase64("")}
                      >
                        Back
                      </button>
                      <button
                        className="p-1 px-3 border rounded-1 text-white bg-color fs-13 w-100"
                        onClick={() => generateQRCode()}
                      >
                        {loader && (
                          <Spinner style={{ width: 14, height: 14 }} />
                        )}{" "}
                        {base64 ? "Re-Generate QR" : "Generate QR"}
                      </button>
                      <button
                        className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
                        onClick={() => {
                          setCheck(true);
                        }}
                      >
                        Next
                      </button>
                    </div>
                  ) : (
                    <div className="hstack gap-3 justify-content-end pt-2">
                      <button
                        className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
                        onClick={() => setNextBy(false)}
                      >
                        Back
                      </button>
                      <button
                        className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
                        style={{ width: 140 }}
                        onClick={() => generateQRCode()}
                      >
                        {loader && (
                          <Spinner style={{ width: 14, height: 14 }} />
                        )}{" "}
                        {base64 ? "Re-Generate QR" : "Generate QR"}
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <center>
                    <Form.Label className="fw-bold mb-0">Enter OTP</Form.Label>
                  </center>
                  <div className="otp-input-fields hstack gap-2 py-3 justify-content-center">
                    {otpValues.map((value, index) => (
                      <input
                        key={index}
                        type="text"
                        pattern="\d*"
                        maxLength="1"
                        className={`border text-center otp__digit otp__field__${
                          index + 1
                        }`}
                        style={{ height: 35, width: 35 }}
                        value={value}
                        onChange={(e) => handleInputChange(index, e)}
                        ref={(input) => (otpInputs.current[index] = input)}
                      />
                    ))}
                  </div>
                  <div className="hstack gap-2 justify-content-between">
                    <button
                      className="w-100 p-1 px-3 border rounded-1 text-white bg-color fs-13"
                      onClick={() => {
                        setCheck(false);
                        setOtpValues(["", "", "", "", "", ""]);
                      }}
                    >
                      Back
                    </button>
                    <button
                      className="w-100 p-1 px-3 border rounded-1 text-white bg-color fs-13"
                      onClick={() => enableTwoFactorAuth()}
                    >
                      {loader && <Spinner style={{ width: 14, height: 14 }} />}{" "}
                      Enable
                    </button>
                  </div>
                  {statusMessage && (
                    <Alert
                      className="fs-10 py-1 px-1 m-0 text-center mt-2"
                      variant={
                        statusMessage?.statusCode === 200 ? "success" : "danger"
                      }
                    >
                      {statusMessage?.customMessage}
                    </Alert>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {!showQR ? (
                <>
                  <div className="qr__imag">
                    <div className="security-shield">
                      <i className="fa-solid fa-lock mb-2"></i>
                      <p className="m-0">
                        <b>Enabled (2FA by Apps)</b>
                      </p>
                    </div>
                  </div>
                  <div className="hstack gap-3 justify-content-center pt-3">
                    <button
                      className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
                      onClick={() => showQRCode()}
                    >
                      Show QR
                    </button>
                    <button
                      className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
                      onClick={() => getQRDataForDisable()}
                    >
                      Disable
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="qr__imag">
                    {base64 && <img src={base64} alt="QR Code" />}
                  </div>
                  <div className="hstack gap-3 justify-content-center pt-3">
                    <button
                      className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
                      onClick={() => setShowQR(false)}
                    >
                      Hide QR
                    </button>
                    <button
                      className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
                      onClick={() => getQRDataForDisable()}
                    >
                      Disable
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <center>
            <Form.Label className="mb-0 fw-bold">Enter OTP</Form.Label>
          </center>
          <div className="otp-input-fields hstack gap-2 py-3 justify-content-center">
            {otpValues.map((value, index) => (
              <input
                key={index}
                type="text"
                pattern="\d*"
                maxLength="1"
                className={`border text-center otp__digit otp__field__${
                  index + 1
                }`}
                style={{ height: 35, width: 35 }}
                value={value}
                onChange={(e) => handleInputChange(index, e)}
                ref={(input) => (otpInputs.current[index] = input)}
              />
            ))}
          </div>
          <div className="hstack gap-2 justify-content-between">
            <button
              className="w-100 p-1 px-3 border rounded-1 text-white bg-color fs-13"
              onClick={() => {
                setDisable(false);
                setOtpValues(["", "", "", "", "", ""]);
              }}
            >
              Back
            </button>
            <button
              className="w-100 p-1 px-3 border rounded-1 text-white bg-color fs-13"
              onClick={() => disableTwoFactorAuth()}
            >
              {loader && <Spinner style={{ width: 14, height: 14 }} />} Disable
              2FA
            </button>
          </div>
          {statusMessage && (
            <Alert
              className="fs-10 py-1 px-1 m-0 text-center mt-2"
              variant={statusMessage?.statusCode === 200 ? "success" : "danger"}
            >
              {statusMessage?.customMessage}
            </Alert>
          )}
        </>
      )}
    </>
  );
};

export default TwoFactorAuthByApps;
