import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import UseToggle from "../../../Hooks/UseToggle";
import AddAccessManagementForm from "./AddAccessManagementForm";
import AccessManagementTableData from "./AccessManagementTableData";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { authServices } from "../../../APIServices/authServices";

const AccessManagement = ({ onMouseDown, tableRef }) => {
  const { toggle, setToggle } = UseToggle();
  const [loader, setLoader] = useState(false);
  const [accessMgmt, setAccessMgmt] = useState([]);
  const [accessMgmtID, setAccessMgmtID] = useState("");
  const [accessManagementName, setAccessManagementName] = useState("");
  const [accessManagementDescription, setAccessManagementDescription] =
    useState("");

  const addAccessManagement = () => {
    setToggle();
    setAccessManagementName("");
    setAccessManagementDescription("");
    setAccessMgmtID("");
  };

  const accessManagements = async () => {
    setLoader(true);
    let data = await authServices.accessManagements("show", "65892129dc0bda701538e161");
    // let data = await authServices.accessManagements("show", accessGroupId);
    setAccessMgmt(data);
    setLoader(false);
  };

  useEffect(() => {
    accessManagements();
  }, []);

  return (
    <>
      <div className={`code-list-container`}>
        <div
          className="hstack justify-content-between w-100 border-bottom"
          style={{ padding: "4.6px 9px" }}
        >
          <div className="hstack gap-2">
            <button
              title="Add Access Management"
              className="tabButtons border rounded-1 fs-13"
              style={{ padding: "1px 5px" }}
              onClick={() => addAccessManagement()}
            >
              <AiOutlinePlus />
            </button>
          </div>
        </div>
        <Container fluid>
          <Row>
            <Form.Group as={Col} md={7} className="p-0">
              <div
                className="px-2 mt-2 overflow-auto"
                style={{ height: "calc(100vh - 154px)" }}
              >
                {loader ? (
                  <div
                    className="hstack justify-content-center"
                    style={{ height: "calc(100vh - 154px)" }}
                  >
                    <Spinner />
                  </div>
                ) : accessMgmt.length === 0 ? (
                  <div
                    className="hstack justify-content-center"
                    style={{ height: "calc(100vh - 154px)" }}
                  >
                    Management List Not Found!
                  </div>
                ) : (
                  <AccessManagementTableData
                    accessMgmt={accessMgmt}
                    setToggle={setToggle}
                    onMouseDown={onMouseDown}
                    setAccessMgmtID={setAccessMgmtID}
                    tableRef={tableRef}
                  />
                )}
              </div>
            </Form.Group>
            <Form.Group as={Col} md={5} className="p-0">
              <div
                className="p-2 overflow-auto border-start"
                style={{ height: "calc(100vh - 146px)" }}
              >
                <Form.Group className="px-3">
                  <Form.Label className="fw-bold" style={{ fontSize: 14 }}>
                    <u> User Management</u>
                  </Form.Label>
                  <Row>
                    <Form.Group as={Col} md={12}>
                      <Form.Check
                        type="checkbox"
                        label="Create & Edit User"
                        name="accessCheck"
                        inline
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={12}>
                      <Form.Check
                        type="checkbox"
                        label="Read Only User"
                        name="accessCheck"
                        inline
                      />
                    </Form.Group>
                  </Row>
                  <Form.Label className="fw-bold mt-3" style={{ fontSize: 14 }}>
                    <u> Client Management</u>
                  </Form.Label>
                  <Row>
                    <Form.Group as={Col} md={12}>
                      <Form.Check
                        type="checkbox"
                        label="Create & Edit Client"
                        name="accessCheck"
                        inline
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={12}>
                      <Form.Check
                        type="checkbox"
                        label="Read Only Client"
                        name="accessCheck"
                        inline
                      />
                    </Form.Group>
                  </Row>
                </Form.Group>
              </div>
            </Form.Group>
          </Row>
        </Container>
      </div>

      <AddAccessManagementForm
        Show={!toggle}
        Hide={setToggle}
        accessMgmtID={accessMgmtID}
        accessManagements={accessManagements}
        accessManagementName={accessManagementName}
        setAccessManagementName={setAccessManagementName}
        accessManagementDescription={accessManagementDescription}
        setAccessManagementDescription={setAccessManagementDescription}
        Title={`${accessMgmtID ? "Update" : "Add Access"} Management`}
      />
    </>
  );
};

export default AccessManagement;
