import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const Users = () => {
  const staticGraphData = [70, 30 ];
  const totalLength = staticGraphData.reduce((a, b) => a + b, 0);

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "donut",
    },
    labels: [
      "Active Users",
      "Inactive Users",
    ],
    colors: [
      "rgba(23, 43, 115, 1)", // Full opacity (100%)
      "rgba(23, 43, 115, 0.8)", // 80% opacity
    ],
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (value) =>
          value !== undefined && value !== null ? value.toString() : "0",
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (value) => {
        const calculatedValue = Math.round((value * totalLength) / 100);
        return calculatedValue !== undefined && calculatedValue !== null
          ? calculatedValue.toString()
          : "0";
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
  });

  return (
    <ReactApexChart
      options={chartOptions}
      series={staticGraphData}
      type="donut"
      height={200}
    />
  );
};

export default Users;
