import React, { useEffect, useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { FaPlusSquare } from "react-icons/fa";
import { authServices } from "../../../../../APIServices/authServices";
import Multiselect from "multiselect-react-dropdown";

const AddUpdateStudy = ({
  Show,
  Hide,
  Title,
  addUpdateStudyId,
  getStudyData,
  studyName,
  setStudyName,
  percentage,
  setPercentage,
  reviewers,
  setReviewers,
  dateFormate,
  setDateFormate,
  studyListId,
}) => {
  const [id, setId] = useState("");
  const [userList, setUserList] = useState([]);
  const [userDataId, setUserDataId] = useState("");

  const addStudy = async () => {
    let userData = {};
    userData.projectId = studyListId;
    userData.name = studyName;
    userData.dateFormat = dateFormate;
    userData.reviewMinPercentage = percentage;
    userData.reviewers = reviewers?.map(item => item.value) || [];
    let result = await authServices.addStudy(userData);
    getStudyData();
    clearForm();
    Hide();
  };

  const updateStudy = async () => {
    let userData = {};
    userData.projectId = studyListId;
    userData.name = studyName;
    userData.reviewMinPercentage = percentage;
    userData.reviewers =reviewers?.map(item => item.value) || [];
    let data = await authServices.updateStudy(userData, id);
    getStudyData();
    Hide();
  };

  const clearForm = () => {
    setStudyName("");
    setId("");
  };

  const getMyData = async () => {
    let data = await authServices.getMyData("me");
    setUserDataId(data?.principal?.clientUserName);
  };

  const getUserListByType = async () => {
    let data = await authServices.getUserListByType(
      "client_user",
      "createdDate",
      userDataId
    );
    setUserList(data);
  };

  
  useEffect(() => {
    setStudyName(addUpdateStudyId?.name);
    setPercentage(addUpdateStudyId?.reviewMinPercentage);
    setReviewers(addUpdateStudyId?.reviewers);
    setId(addUpdateStudyId?.projectId);
  }, [addUpdateStudyId]);

  useEffect(() => {
    getMyData();
  }, []);

  useEffect(() => {
    if (userDataId) {
      getUserListByType();
    }
  }, [userDataId]);

  return (
    <div>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title>{Title}</Offcanvas.Title>
          <div onClick={Hide} className="close_modal" title="Close">
            X
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Study Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter  Study Name"
              value={studyName}
              onChange={(e) => setStudyName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Review Min Percentage<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Review Min Percentage"
              value={percentage}
              onChange={(e) => setPercentage(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Select Date Format<span className="text-danger">*</span>
            </Form.Label>
            <Form.Select onChange={(e) => setDateFormate(e.target.value)}>
              <option value="">Select Formate</option>
              <option selected={dateFormate === "dd/mm"} value="dd/mm">
                dd/yyyy
              </option>
              <option
                selected={dateFormate === "dd/mm/yyyy"}
                value="dd/mm/yyyy"
              >
                dd/mm/yyyy
              </option>
              <option selected={dateFormate === "mm/dd/yyy"} value="mm/dd/yyyy">
                mm/dd/yyyy
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              reviewers <span className="text-danger">*</span>
            </Form.Label>
            <Multiselect
              displayValue="label"
              onKeyPressFn={function noRefCheck() {}}
              onChange={setReviewers}
              options={userList?.map((item, index) => ({
                label: `${item.firstName} ${item.lastName}`,
                value: item.username,
              }))}
              selectedValues={reviewers || []}
              onSelect={(selectedList) => setReviewers(selectedList)}
              onRemove={(removedList) => setReviewers(removedList)}
            />
          </Form.Group>
          <Form.Group className="text-end">
            <button
              onClick={addUpdateStudyId ? updateStudy : addStudy}
              className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
            >
              <FaPlusSquare style={{ marginBottom: 2 }} />{" "}
              {addUpdateStudyId ? "Update" : "Add"} Study
            </button>
          </Form.Group>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default AddUpdateStudy;
