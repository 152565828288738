import React, { useState } from "react";
import { MdDashboard } from "react-icons/md";
import { TbLogout } from "react-icons/tb";
import { VscChevronDown, VscChevronRight } from "react-icons/vsc";
import { IoFolderOpenOutline } from "react-icons/io5";
import { CiFolderOn } from "react-icons/ci";
import { Collapse } from "react-bootstrap";
import { AiOutlineFileText } from "react-icons/ai";
import { LuFileCheck2, LuFileEdit } from "react-icons/lu";
import { BiCommentDetail } from "react-icons/bi";

const StudySidebar = ({ handleAddNewTab, tabs }) => {
  const [openStudyMgmt, setOpenStudyMgmt] = useState(false);

  const studyLogout = () => {
    window.location.href = `/home`;
    localStorage.removeItem('loginType'); 
};

  return (
    <>
      <div className="border rounded-1 bg-light overflow-hidden">
        <div
          className="overflow-auto"
          style={{ height: "calc(100vh - 115px)" }}
        >
          <div
            className={`cursor-pointer p-2 ${
              tabs.some(
                (item) =>
                  item.tabId === "dashboardId" && item.status === "active"
              ) && "activeMenu"
            }`}
            onClick={() =>
              handleAddNewTab(
                "Dashboard",
                "fa-solid fa-chart-line",
                "dashboardId"
              )
            }
          >
            <div
              className="hstack justify-content-between"
              style={{ whiteSpace: "nowrap" }}
            >
              <span>
                <MdDashboard />
                <span className="ms-2 fs-13">Dashboard</span>
              </span>
            </div>
          </div>
          <div
            className={`cursor-pointer p-2 ${
              tabs.some(
                (item) =>
                  item.tabId === "formManagement" && item.status === "active"
              ) && "activeMenu"
            }`}
            title="Form Management"
            onClick={() =>
              handleAddNewTab(
                "Form Management",
                "fa-solid fa-file-lines",
                "formManagement"
              )
            }
          >
            <div
              className="hstack justify-content-between"
              style={{ whiteSpace: "nowrap" }}
            >
              <span>
                <AiOutlineFileText />
                <span className="ms-2 fs-13">Form Management</span>
              </span>
            </div>
          </div>
          <div
            className={`cursor-pointer p-2 ${
              tabs.some(
                (item) =>
                  item.tabId === "formReviewer" && item.status === "active"
              ) && "activeMenu"
            }`}
            title="Form Reviewer"
            onClick={() =>
              handleAddNewTab(
                "Form Reviewer",
                "fa-solid fa-file-circle-check",
                "formReviewer"
              )
            }
          >
            <div
              className="hstack justify-content-between"
              style={{ whiteSpace: "nowrap" }}
            >
              <span>
                <LuFileCheck2 />
                <span className="ms-2 fs-13">Form Reviewer</span>
              </span>
            </div>
          </div>
          <div
            className={`cursor-pointer p-2 ${
              tabs.some(
                (item) =>
                  item.tabId === "queryManagement" && item.status === "active"
              ) && "activeMenu"
            }`}
            title="Query Management"
            onClick={() =>
              handleAddNewTab(
                "Query Management",
                "fa-solid fa-comment-dots",
                "queryManagement"
              )
            }
          >
            <div
              className="hstack justify-content-between"
              style={{ whiteSpace: "nowrap" }}
            >
              <span>
                <BiCommentDetail />
                <span className="ms-2 fs-13">Query Management</span>
              </span>
            </div>
          </div>
          <div
            className={`cursor-pointer p-2 ${
              tabs.some(
                (item) =>
                  item.tabId === "dataEntryId" && item.status === "active"
              ) && "activeMenu"
            }`}
            title="Data Entry"
            onClick={() =>
              handleAddNewTab(
                "Data Entry",
                "fa-solid fa-file-pen",
                "dataEntryId"
              )
            }
          >
            <div
              className="hstack justify-content-between"
              style={{ whiteSpace: "nowrap" }}
            >
              <span>
                <LuFileEdit />
                <span className="ms-2 fs-13">Data Entry</span>
              </span>
            </div>
          </div>
          <div
            className={`cursor-pointer p-2 ${
              tabs.some(
                (item) =>
                  item.tabId === "dataReviewerId" && item.status === "active"
              ) && "activeMenu"
            }`}
            title="Data Reviewer"
            onClick={() =>
              handleAddNewTab(
                "Data Reviewer",
                "fa-solid fa-file-circle-check",
                "dataReviewerId"
              )
            }
          >
            <div
              className="hstack justify-content-between"
              style={{ whiteSpace: "nowrap" }}
            >
              <span>
                <LuFileCheck2 />
                <span className="ms-2 fs-13">Data Reviewer</span>
              </span>
            </div>
          </div>
          <div
            className={`cursor-pointer p-2 ${
              openStudyMgmt && "activeMenu"
            } cursor-pointer p-1`}
            onClick={() => setOpenStudyMgmt(!openStudyMgmt)}
            aria-expanded={openStudyMgmt}
          >
            <div className="hstack justify-content-between">
              <span>
                {openStudyMgmt ? <IoFolderOpenOutline /> : <CiFolderOn />}
                <span className="ms-2 fs-13">Study Management</span>
              </span>
              <span>
                {openStudyMgmt ? <VscChevronDown /> : <VscChevronRight />}
              </span>
            </div>
          </div>
          <Collapse in={openStudyMgmt}>
            <div>
              <div
                className={`cursor-pointer p-2 ps-3 ${
                  tabs.some(
                    (item) =>
                      item.tabId === "studyMgmtDetails" &&
                      item.status === "active"
                  ) && "activeMenu"
                }`}
                title="Details"
                onClick={() =>
                  handleAddNewTab(
                    "Details",
                    "fa-solid fa-file-lines",
                    "studyMgmtDetails"
                  )
                }
              >
                <div className="hstack justify-content-between">
                  <span>
                    <AiOutlineFileText />
                    <span className="ms-2 fs-13">Details</span>
                  </span>
                </div>
              </div>
              <div
                className={`cursor-pointer p-2 ps-3 ${
                  tabs.some(
                    (item) =>
                      item.tabId === "studyMgmtMetadata" &&
                      item.status === "active"
                  ) && "activeMenu"
                }`}
                title="Metadata"
                onClick={() =>
                  handleAddNewTab(
                    "Metadata",
                    "fa-solid fa-file-lines",
                    "studyMgmtMetadata"
                  )
                }
              >
                <div className="hstack justify-content-between">
                  <span>
                    <AiOutlineFileText />
                    <span className="ms-2 fs-13">Metadata</span>
                  </span>
                </div>
              </div>
              <div
                className={`cursor-pointer p-2 ps-3 ${
                  tabs.some(
                    (item) =>
                      item.tabId === "studyMgmtConfig" &&
                      item.status === "active"
                  ) && "activeMenu"
                }`}
                title="Config"
                onClick={() =>
                  handleAddNewTab(
                    "Config",
                    "fa-solid fa-file-lines",
                    "studyMgmtConfig"
                  )
                }
              >
                <div className="hstack justify-content-between">
                  <span>
                    <AiOutlineFileText />
                    <span className="ms-2 fs-13">Config</span>
                  </span>
                </div>
              </div>
              <div
                className={`cursor-pointer p-2 ps-3 ${
                  tabs.some(
                    (item) =>
                      item.tabId === "studyMgmtSpecialForm" &&
                      item.status === "active"
                  ) && "activeMenu"
                }`}
                title="Special Form"
                onClick={() =>
                  handleAddNewTab(
                    "Special Form",
                    "fa-solid fa-file-lines",
                    "studyMgmtSpecialForm"
                  )
                }
              >
                <div className="hstack justify-content-between">
                  <span>
                    <AiOutlineFileText />
                    <span className="ms-2 fs-13">Special Form</span>
                  </span>
                </div>
              </div>
              <div
                className={`cursor-pointer p-2 ps-3 ${
                  tabs.some(
                    (item) =>
                      item.tabId === "studyMgmtSubject" &&
                      item.status === "active"
                  ) && "activeMenu"
                }`}
                title="Subject"
                onClick={() =>
                  handleAddNewTab(
                    "Subject",
                    "fa-solid fa-file-lines",
                    "studyMgmtSubject"
                  )
                }
              >
                <div className="hstack justify-content-between">
                  <span>
                    <AiOutlineFileText />
                    <span className="ms-2 fs-13">Subject</span>
                  </span>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
        <div className={`cursor-pointer p-1 bg-danger-subtle`} onClick={studyLogout}>
          <div
            className="hstack justify-content-between"
            style={{ whiteSpace: "nowrap" }}
          >
            <span>
              <TbLogout />
              <span className="ms-2 fs-13">Study Logout</span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudySidebar;
