import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ConfigTableData from "./ConfigTableData";
import { authServices } from "../../../../APIServices/authServices";
import { Col, Container, Form, ListGroup, Row, Spinner } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";

const Config = () => {
  const [loading, setLoading] = useState(false);
  const [visitFormListData, setVisitFormListData] = useState([]);
  const [visitFormList, setVisitFormList] = useState([]);
  const [visitData, setVisitData] = useState([]);
  const [visitForm, setVisitForm] = useState([]);
  const [visitId, setVisitId] = useState("");
  const [visitName, setVisitName] = useState("");
  const [visitOrder, setVisitOrder] = useState("");
  const { studyId } = useParams();

  const getVisitFormListData = async () => {
    setLoading(true);
    let data = await authServices.getStudyManageDetails(studyId, "VISIT");
    setVisitFormListData(data || []);
    setLoading(false);
  };

  const getVisitFormPosition = async () => {
    setLoading(true);
    let data = await authServices.getVisitFormPosition(visitId);
    setVisitFormList(data?.[0]?.forms || []);
    setVisitOrder(data?.[0]?.order)
    const resultData = (data?.[0]?.forms || [])?.map((entry) => ({
      label: entry.formName,
      value: entry.formId,
      logForm: entry.logForm || false,
      formOrder: entry.formOrder || ""
    }));
    setVisitForm(resultData)
    setLoading(false);
  };

  const getVisitData = async () => {
    let data = await authServices.getFormData(studyId);
    setVisitData(data || []);
  };

  useEffect(() => {
    getVisitFormListData();
    getVisitData();
  }, [studyId]);

  useEffect(() => {
    if (visitId) {
      getVisitFormPosition();
    }
  }, [visitId]);

  return (
    <>
      <Container fluid>
        <Row>
          <Form.Group as={Col} md={2} className="border-end p-0">
            <div className="p-1 border-bottom bg-light text-center">
              <Form.Label className="fw-bold mb-0">Visit List</Form.Label>
            </div>
            <div
              className="p-2 overflow-auto"
              style={{ height: "calc(100vh - 147px)" }}
            >
              <ListGroup className="rounded-1">
                {visitFormListData?.map((item, index) => {
                  const { id, data } = item;
                  const [headerKey] = Object.keys(data);
                  return (
                    <ListGroup.Item
                      key={index}
                      className={`cursor-pointer ${
                        visitId === id ? "activeMenu" : ""
                      }`}
                      style={{ fontSize: 12 }}
                      onClick={() => {
                        setVisitId(id);
                        setVisitName(data[headerKey]);
                      }}
                    >
                      {data[headerKey]}
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </div>
          </Form.Group>
          <Form.Group as={Col} md={10} className="p-0">
            {visitId ? (
              <>
                <div
                  className="w-100 specialForm border-bottom"
                  style={{ height: "33px" }}
                >
                  <Multiselect
                    displayValue="label"
                    onKeyPressFn={function noRefCheck() {}}
                    onChange={setVisitForm}
                    options={
                      visitData
                        ? visitData
                            .filter((item) => item && item.name)
                            .map((item) => ({
                              label: item.name,
                              value: item.id,
                            }))
                        : []
                    }
                    selectedValues={visitForm || []}
                    onSelect={(selectedList) => setVisitForm(selectedList)}
                    onRemove={(removedList) => setVisitForm(removedList)}
                  />
                </div>
                {loading ? (
                  <div
                    className="hstack justify-content-center"
                    style={{ height: "calc(100vh - 115px)" }}
                  >
                    <Spinner />
                  </div>
                ) : visitFormListData?.length === 0 ? (
                  <div
                    className="hstack justify-content-center"
                    style={{ height: "calc(100vh - 115px)" }}
                  >
                    Visit Not Found!
                  </div>
                ) : (
                  <ConfigTableData
                    visitName={visitName}
                    visitForm={visitForm}
                    studyId={studyId}
                    visitId={visitId}
                    setVisitForm={setVisitForm}
                    visitData={visitData}
                    visitOrder={visitOrder}
                    visitFormList={visitFormList}
                  />
                )}
              </>
            ) : (
              <div
                className="hstack justify-content-center"
                style={{ height: "calc(100vh - 115px)" }}
              >
                Select for Details!
              </div>
            )}
          </Form.Group>
        </Row>
      </Container>
    </>
  );
};

export default Config;
