import React, { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import { CiExport } from "react-icons/ci";
import Pagination from "../../../../../Hooks/Pagination";
import { authServices } from "../../../../../APIServices/authServices";
import { FiEdit } from "react-icons/fi";
import { LuLogIn } from "react-icons/lu";
import AddUpdateStudy from "./AddUpdateStudy";
import UseToggle from "../../../../../Hooks/UseToggle";
import StudyLogin from "./StudyLogin";

const StudyTable = ({ onMouseDown, tableRef, studyListId }) => {
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [loading, setLoading] = useState(false);
  const [studyList, setStudyList] = useState([]);
  const [addUpdateStudyId, setAddUpdateStudyId] = useState("");
  const [studyName, setStudyName] = useState("");
  const [percentage, setPercentage] = useState("");
  const [studyData, setStudyData] = useState("");
  const [reviewers, setReviewers] = useState([]);
  const [dateFormate, setDateFormate] = useState("");
  const [modeId, setModeId] = useState("");

  const getStudyData = async () => {
    setLoading(true);
    let data = await authServices.getStudyData(studyListId);
    setStudyList(data);
    setLoading(false);
  };

  const addStudy = () => {
    setAddUpdateStudyId("");
    setStudyName("");
    setToggle();
  };

  const updateStudy = (item) => {
    setAddUpdateStudyId(item);
    setToggle();
  };

  const deleteStudy = async (id) => {
    let data = await authServices.deleteProject(id);
    getStudyData();
  };

  useEffect(() => {
    if (studyListId) {
      getStudyData();
    }
  }, [studyListId]);

  const loginStudy = (item) => {
    setModeId(item?.id)
    localStorage.setItem("setStudyData", JSON.stringify(item));
    setToggle1()
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = studyList?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(studyList?.length / itemsPerPage);

  return (
    <>
      <div
        className="hstack justify-content-between w-100 border-bottom"
        style={{ padding: "4.6px 9px" }}
      >
        <div className="hstack gap-2">
          <button
            title="Add Study"
            className="tabButtons border rounded-1 fs-13"
            style={{ padding: "1px 5px" }}
            onClick={() => addStudy()}
          >
            <AiOutlinePlus />
          </button>
        </div>
        <button className="tabButtons border rounded-1 fs-13" title="Export">
          <CiExport />
        </button>
      </div>
      <div
        className="overflow-auto px-2 mt-2"
        style={{ height: "calc(100vh - 156px)" }}
      >
        {loading ? (
          <div
            className="hstack justify-content-center"
            style={{ height: "calc(100vh - 156px)" }}
          >
            <Spinner />
          </div>
        ) : result?.length === 0 ? (
          <div
            className="hstack justify-content-center"
            style={{ height: "calc(100vh - 156px)" }}
          >
            Click to view Study!
          </div>
        ) : (
          <>
            <Table
              ref={tableRef}
              id="resizable-table"
              className="text-nowrap mb-1 custom-table"
              hover
              bordered
              striped
            >
              <thead className="position-sticky z-1" style={{ top: -1 }}>
                <tr>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 0)}
                    style={{ width: 40 }}
                  >
                    Sr.
                  </th>
                  <th onMouseDown={(e) => onMouseDown(e, 1)}>Study Name</th>
                  <th onMouseDown={(e) => onMouseDown(e, 2)}>
                    Review Min Percentage
                  </th>
                  <th onMouseDown={(e) => onMouseDown(e, 3)}>Reviewers</th>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 4)}
                    style={{ width: 60 }}
                    className="text-center"
                  >
                    Login
                  </th>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 5)}
                    style={{ width: 60 }}
                    className="text-center"
                  >
                    Edit
                  </th>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 6)}
                    style={{ width: 60 }}
                    className="text-center"
                  >
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {result?.map((item, index) => (
                  <tr
                    key={index}
                    className={`position-relative cursor-pointer `}
                  >
                    <td>{index + startIndex + 1}</td>
                    <td>{item?.name}</td>
                    <td>{item?.reviewMinPercentage}</td>
                    <td>{item?.reviewers}</td>
                    <td className="text-center">
                      <div
                        title="Login Study"
                        onClick={() => loginStudy(item)}
                      >
                        <LuLogIn className="text-primary" />
                      </div>
                    </td>
                    <td className="text-center">
                      <div
                        title="Update Study"
                        onClick={() => updateStudy(item)}
                      >
                        <FiEdit className="text-primary" />
                      </div>
                    </td>
                    <td>
                      <div
                        className="text-center cursor-pointer"
                        title="Delete Study"
                        onClick={() => deleteStudy(item?.id)}
                      >
                        <i
                          className={`fa-solid fa-trash-can CP red text-danger`}
                        ></i>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky bottom-0">
              <Pagination
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={studyList?.length}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </>
        )}
      </div>
      <StudyLogin
        Show={!toggle1}
        Hide={setToggle1}
        modeId={modeId}
        studyListId={studyListId}
        Title={`Study Login Mode`}
      />

      <AddUpdateStudy
        Show={!toggle}
        Hide={setToggle}
        studyName={studyName}
        setStudyName={setStudyName}
        percentage={percentage}
        setPercentage={setPercentage}
        reviewers={reviewers}
        setReviewers={setReviewers}
        dateFormate={dateFormate}
        setDateFormate={setDateFormate}
        getStudyData={getStudyData}
        studyListId={studyListId}
        addUpdateStudyId={addUpdateStudyId}
        Title={`${addUpdateStudyId ? "Update" : "Add"} Study`}
      />
    </>
  );
};

export default StudyTable;
