import React from "react";
import { Offcanvas, Table } from "react-bootstrap";
import Moment from "react-moment";

const QueryOffcanvas = ({ Show, Hide, Title, formDataId }) => {
  return (
    <>
      <div>
        <Offcanvas show={Show} onHide={Hide} placement="end">
          <Offcanvas.Header>
            <Offcanvas.Title>{Title}</Offcanvas.Title>
            <div onClick={Hide} className="close_modal" title="Close">
              X
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Table
              id="resizable-table"
              striped
              hover
              bordered
              className="m-0 custom-table"
            >
              <thead className="position-sticky" style={{ top: -1 }}>
                <tr>
                  <th style={{ width: "100px" }}>Attribute</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Query Name</td>
                  <td>{formDataId.formName || "-"}</td>
                </tr>
                <tr>
                  <td>Query Id</td>
                  <td>{formDataId.id || "-"}</td>
                </tr>
                <tr>
                  <td>Query Comments</td>
                  <td>{formDataId.comments}</td>
                </tr>
                <tr>
                  <td>Comments By </td>
                  <td>{formDataId.commentedBy}</td>
                </tr>
                <tr>
                  <td>Query Type</td>
                  <td>{formDataId.type}</td>
                </tr>
                <tr>
                  <td>Query Created Date|Time</td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm:ss">
                      {formDataId.createdOn}
                    </Moment>
                  </td>
                </tr>
                <tr>
                  <td>Updated Date & Time</td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm:ss">
                      {formDataId.modifiedOn}
                    </Moment>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default QueryOffcanvas;
