import React, { useEffect, useState } from "react";
import { Form, Offcanvas, Spinner } from "react-bootstrap";
import { FaFloppyDisk } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import { authServices } from "../../../../APIServices/authServices";

const AddUpdateSubjectMetadata = ({
  Show,
  Hide,
  Title,
  id,
  subjectColumnData,
  getSubjectDataById,
  updateId,
  siteId,
  modeType,
}) => {
  const { studyId } = useParams();
  const [loader, setLoader] = useState(false);
  const [subjectData, setSubjectData] = useState({});

  const addSubjectetadata = async () => {
    setLoader(true);
    const userData = {};
    userData.studyId = studyId;
    userData.siteId = siteId;
    userData.dbMode = modeType;
    userData.data = subjectData;
    let data = await authServices.addNewSubject(userData);
    setLoader(false);
    getSubjectDataById();
    Hide();
  };

  const updateSubjectMetadata = async () => {
    setLoader(true);
    const userData = {};
    userData.studyId = studyId;
    userData.siteId = siteId;
    userData.dbMode = modeType;
    userData.data = subjectData;
    let data = await authServices.updateSubjectMetadata(id, userData);
    getSubjectDataById();
    setLoader(false);
    Hide();
  };

  useEffect(() => {
    if (updateId?.data) {
      setSubjectData({ ...updateId.data });
    }
  }, [updateId]);

  return (
    <div>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title>{Title}</Offcanvas.Title>
          <div onClick={Hide} className="close_modal" title="Close">
            X
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="overflow-auto py-2 px-3"
            style={{ height: "calc(100vh - 84px)" }}
          >
            {subjectColumnData[0]?.data?.map((item, index) => (
              <Form.Group key={index} className="mb-2 w-100">
                <Form.Label className="mb-1 fw-bold">
                  {item?.label} <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type={item?.type}
                  name={item?.name}
                  placeholder={`Enter ${item?.name} value`}
                  value={subjectData[item.name] || ""}
                  onChange={(e) =>
                    setSubjectData((prevData) => ({
                      ...prevData,
                      [item.name]: e.target.value,
                    }))
                  }
                />
              </Form.Group>
            ))}
          </div>
          <Form.Group className="text-end p-2 bg-light">
            <button
              onClick={() =>
                updateId ? updateSubjectMetadata() : addSubjectetadata()
              }
              className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
            >
              {loader && (
                <Spinner
                  animation="border"
                  style={{ width: 15, height: 15 }}
                  className="me-2"
                />
              )}
              <FaFloppyDisk style={{ marginBottom: 2 }} />{" "}
              {updateId ? "Update" : "Save"}
            </button>
          </Form.Group>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default AddUpdateSubjectMetadata;
