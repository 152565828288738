import React, { useEffect, useState } from "react";
import { Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { authServices } from "../../../../APIServices/authServices";
import SubjectMetadata from "./SubjectMetadata";

const Subject = () => {
  const [siteData, setSiteData] = useState([]);
  const [siteId, setSiteId] = useState("");
  const { studyId } = useParams();

  const getSiteData = async () => {
    let data = await authServices.getStudyManageDetails(studyId, "SITE");
    setSiteData(data || []);
  };

  useEffect(() => {
    getSiteData();
  }, [studyId]);

  return (
    <>
      <Container fluid>
        <Row>
          <Form.Group as={Col} md={2} className="border-end p-0">
            <div className="p-1 border-bottom bg-light text-center">
              <Form.Label className="fw-bold mb-0">Site List</Form.Label>
            </div>
            <div
              className="p-2 overflow-auto"
              style={{ height: "calc(100vh - 147px)" }}
            >
              <ListGroup className="rounded-1">
                {siteData?.map((item, index) => (
                  <ListGroup.Item
                    action
                    key={index}
                    onClick={() => setSiteId(item?.id)}
                    className={`cursor-pointer`}
                    style={{ fontSize: 12 }}
                  >
                    {Object.keys(item.data)
                      ?.slice(0, 1)
                      ?.map((header, i) => (
                        <td key={i}>{item.data[header]}</td>
                      ))}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          </Form.Group>
          <Form.Group as={Col} md={10} className="p-0">
            <SubjectMetadata siteId={siteId} />
          </Form.Group>
        </Row>
      </Container>
    </>
  );
};

export default Subject;
