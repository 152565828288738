import React from "react";
import { Offcanvas, Table } from "react-bootstrap";

const FormAttibuteData = ({
  Show,
  Hide,
  Title,
  tableRef,
  onMouseDown,
  formDataId,
}) => {
  return (
    <>
      <div>
        <Offcanvas show={Show} onHide={Hide} placement="end">
          <Offcanvas.Header>
            <Offcanvas.Title>{Title}</Offcanvas.Title>
            <div onClick={Hide} className="close_modal" title="Close">
              X
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Table
              id="resizable-table"
              striped
              hover
              bordered
              className="m-0 custom-table"
              ref={tableRef}
            >
              <thead className="position-sticky" style={{ top: -1 }}>
                <tr>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 0)}
                    style={{ width: "100px" }}
                  >
                    Attribute
                  </th>
                  <th onMouseDown={(e) => onMouseDown(e, 1)}>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Order</td>
                  <td>{formDataId.order || "-"}</td>
                </tr>
                <tr>
                  <td>Form Name</td>
                  <td>{formDataId.name || "-"}</td>
                </tr>
                <tr>
                  <td>Form Version</td>
                  <td>{formDataId.version || "-"}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>
                    <div
                      className={
                        formDataId.status === "Approved" && "text-success"
                      }
                    >
                      {formDataId.status || "-"}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Scope</td>
                  <td>{formDataId.scope || "-"}</td>
                </tr>
                <tr>
                  <td>Domain Class</td>
                  <td>{formDataId.domainClass || "-"}</td>
                </tr>
                <tr>
                  <td>Domain Description</td>
                  <td>{formDataId.domainDescription || "-"}</td>
                </tr>
              </tbody>
            </Table>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default FormAttibuteData;
