import React, { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { BiFilterAlt } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import AddUpdateConfigMetadata from "./AddUpdateConfigMetadata";
import AddUpdateSiteMetadata from "./AddUpdateSiteMetadata";
import UseToggle from "../../../../../Hooks/UseToggle";
import { authServices } from "../../../../../APIServices/authServices";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Pagination from "../../../../../Hooks/Pagination";

const SiteMetadata = ({ onMouseDown, tableRef }) => {
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [loader, setLoader] = useState(false);
  const [siteConfigId, setSiteConfigId] = useState("");
  const [siteColumnData, setSiteColumnData] = useState([]);
  const [siteRowData, setSiteRowData] = useState([]);
  const [updateId, setUpdateId] = useState("");
  const [id, setId] = useState("");
  const { studyId } = useParams();

  const getSiteColumnData = async () => {
    setLoader(true);
    let data = await authServices.getFormEntryBySite("SITE", studyId);
    setSiteColumnData(data || []);
    setLoader(false);
  };

  const getSiteRowData = async () => {
    setLoader(true);
    let data = await authServices.getStudyManageDetails(studyId, "SITE");
    setSiteRowData(data || []);
    setLoader(false);
  };

  const handleUpdateClick = (item) => {
    setUpdateId(item);
    setId(item?.id);
    setToggle1();
  };

  const handleAddSiteConfigClick = () => {
    const newId = result[0]?.id;
    setSiteConfigId(newId);
    setToggle();
  };

  const deleteSiteMetaData = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete This MetaData!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      let data = await authServices.deleteMetaData(studyId, id);
      getSiteRowData();
    });
  };

  useEffect(() => {
    getSiteColumnData();
    getSiteRowData();
  }, [studyId]);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = siteRowData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(siteRowData?.length / itemsPerPage);

  return (
    <>
      <div
        className="hstack justify-content-between w-100 border-bottom"
        style={{ padding: "4.6px 9px" }}
      >
        <div className="hstack gap-2">
          <button
            title="Site Config"
            className="tabButtons border rounded-1 fs-10"
            style={{ padding: "3px 10px" }}
            onClick={handleAddSiteConfigClick}
          >
            Add Site Config
          </button>
          <div className="vr"></div>
          <button
            title="Site Config"
            className="tabButtons border rounded-1 fs-10"
            style={{ padding: "3px 10px" }}
            onClick={() => {
              setToggle1();
              setUpdateId("");
              setId("");
            }}
          >
            Add New Site
          </button>
          <div className="vr"></div>
          <button
            title="Filter"
            className="tabButtons border rounded-1 fs-13"
            style={{ padding: "1px 5px" }}
          >
            <BiFilterAlt />
          </button>
        </div>
      </div>
      {loader ? (
        <div
          className="hstack justify-content-center"
          style={{ height: "calc(100vh - 156px)" }}
        >
          <Spinner />
        </div>
      ) : siteColumnData?.length === 0 ? (
        <div
          className="hstack justify-content-center"
          style={{ height: "calc(100vh - 156px)" }}
        >
          Site Not Found!
        </div>
      ) : (
        <>
          <div
            className="position-relative px-2 mt-2 overflow-auto"
            style={{ height: "calc(100vh - 154px)" }}
          >
            <Table
              tableRef={tableRef}
              id="resizable-table"
              className="text-nowrap mb-1 custom-table"
              hover
              bordered
              striped
            >
              <thead className="position-sticky z-1" style={{ top: -1 }}>
                <tr>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 0)}
                    style={{ width: 40 }}
                  >
                    Sr.
                  </th>
                  {siteColumnData[0]?.data?.map((header, index) => (
                    <th key={index} onMouseDown={(e) => onMouseDown(e, 1)}>
                      {header?.label}
                    </th>
                  ))}
                  <th
                    onMouseDown={(e) => onMouseDown(e, 2)}
                    style={{ width: 80 }}
                  >
                    <div className="text-center">Edit</div>
                  </th>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 3)}
                    style={{ width: 80 }}
                  >
                    <div className="text-center">Remove</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {result?.map((item, index) => (
                  <tr
                    key={item.id}
                    className="position-relative cursor-pointer"
                  >
                    <td>{index + 1}</td>
                    {Object.keys(item.data).map((header, i) => (
                      <td key={i}>{item.data[header]}</td>
                    ))}

                    <td
                      className="text-center"
                      onClick={() => handleUpdateClick(item)}
                    >
                      <div title="Update">
                        <FiEdit className="text-primary" />
                      </div>
                    </td>
                    <td
                      className="text-center cursor-pointer"
                      onClick={() => deleteSiteMetaData(item.id)}
                      title="Delete Visit"
                    >
                      <i className="fa-solid fa-trash-can CP red text-danger"></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky bottom-0">
              <Pagination
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={siteRowData?.length}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </div>
        </>
      )}
      <AddUpdateConfigMetadata
        Show={!toggle}
        Hide={setToggle}
        siteConfigId={siteConfigId}
        siteColumnData={siteColumnData}
        getSiteColumnData={getSiteColumnData}
        Title={"Add Site Config"}
      />
      <AddUpdateSiteMetadata
        Show={!toggle1}
        Hide={setToggle1}
        getSiteRowData={getSiteRowData}
        Title={`${updateId ? "Update" : "Add"} Site`}
        siteColumnData={siteColumnData}
        id={id}
        updateId={updateId}
      />
    </>
  );
};

export default SiteMetadata;
