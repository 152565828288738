import React, { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useDrag, useDrop } from "react-dnd";
import { calculateDuration } from "../../Utils/UniversalFunction";
import { HiMiniXMark } from "react-icons/hi2";

const ItemType = "TAB";

const DraggableTab = ({
  item,
  index,
  handleMoveTab,
  handleActiveTab,
  handleDeleteTab,
}) => {
  const ref = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        handleMoveTab(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
    collect: (monitor) => {
      setIsDragging(monitor.isOver());
    },
  });

  const [{ isDraggingItem }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDraggingItem: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className={`tablist ${item.status}`}
      style={{
        background: "var(--resizeBg)",
        borderRadius: "3px 3px 0 0",
        opacity: isDraggingItem ? 0.5 : 1,
        transition: "transform 0.2s ease",
      }}
    >
      <div
        key={index}
        className={`hstack ps-2 pe-1 tablist ${item.status}`}
        style={{
          padding: 2,
          borderRadius: item.status === "active" && "3px 3px 0 0",
        }}
      >
        <Form.Label
          onClick={() => handleActiveTab(item.tabId)}
          title={`Tab Name: ${item.tabName}\nOpened Time: ${calculateDuration(
            item?.openDT
          )}`}
          className={`m-0 hstack cursor-pointer text-truncate me-2 ${
            item.status === "active" ? "text-white" : "text-muted"
          }`}
          style={{ minWidth: 100 }}
        >
          <i className={`${item.icon} me-2`}></i>
          <span style={{ marginBottom: -2 }}>{item.tabName}</span>
        </Form.Label>
        <HiMiniXMark
          onClick={() => handleDeleteTab(item.tabId)}
          className={item.status === "active" ? "text-white" : "text-muted"}
          title="Close Tab"
        />
      </div>
    </div>
  );
};

export default DraggableTab;
