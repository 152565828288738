import React, { useState } from "react";
import Moment from "react-moment";
import { Spinner, Table } from "react-bootstrap";
import { FaRegEye } from "react-icons/fa";
import Pagination from "../../../Hooks/Pagination";
import UseToggle from "../../../Hooks/UseToggle";
import FormReviewOffcanvas from "./FormReviewOffcanvas";

const FormReviewerTable = ({ formData, loading, handleAddNewTab }) => {
  const [formReviewData, setFormReviewData] = useState("");
  const { toggle1, setToggle1 } = UseToggle();

  const viewDetail = (item) => {
    setToggle1();
    setFormReviewData(item);
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = formData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(formData?.length / itemsPerPage);

  return (
    <>
      {loading ? (
        <div
          className="hstack justify-content-center"
          style={{ height: "calc(100vh - 156px)" }}
        >
          <Spinner />
        </div>
      ) : result?.length === 0 ? (
        <div
          className="hstack justify-content-center"
          style={{ height: "calc(100vh - 156px)" }}
        >
          Click to view Form Review!
        </div>
      ) : (
        <>
          <Table
            id="resizable-table"
            className="text-nowrap mb-1 custom-table"
            hover
            bordered
            striped
          >
            <thead className="position-sticky z-1" style={{ top: -1 }}>
              <tr>
                <th style={{ width: 40 }}>Sr.</th>
                <th>Form Name</th>
                <th>Status</th>
                <th>Form Version</th>
                <th>Created Date | Time</th>
                <th style={{ width: 80 }}>
                  <div className="text-center">View Form</div>
                </th>
                <th style={{ width: 80 }}>
                  <div className="text-center">Form Details</div>
                </th>
                <th style={{ width: 60 }}>
                  <div className="text-center">Remove</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {result?.map((item, index) => (
                <tr key={index} className={`position-relative cursor-pointer `}>
                  <td>{index + startIndex + 1}</td>
                  <td>{item.name}</td>
                  <td>
                    <div
                      className={
                        item.status === "PUBLISHED"
                          ? "text-success"
                          : item.status === "Rejected"
                          ? "text-danger"
                          : item.status === "Approved"
                          ? "text-success"
                          : ""
                      }
                    >
                      {item.status || "-"}
                    </div>
                  </td>
                  <td>{item.version}</td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm:ss">
                      {item.modifiedOn}
                    </Moment>
                  </td>
                  <td
                    className="text-center"
                    onClick={() =>
                      handleAddNewTab(
                        `${item?.name} Form`,
                        "fa-solid fa-file",
                        `formReviewerId_${item?.id}`,
                        { id: item?.id }
                      )
                    }
                  >
                    <div title="View Project">
                      <FaRegEye className="text-primary" />
                    </div>
                  </td>
                  <td className="text-center" onClick={() => viewDetail(item)}>
                    <div title="View Project">
                      <FaRegEye className="text-primary" />
                    </div>
                  </td>
                  <td>
                    <div
                      className="text-center cursor-pointer"
                      title="Delete Client"
                    >
                      <i
                        className={`fa-solid fa-trash-can CP red text-danger`}
                      ></i>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="position-sticky bottom-0">
            <Pagination
              totalPage={totalPage}
              pageNumber={pageNumber}
              itemsPerPage={itemsPerPage}
              totalItems={formData?.length}
              setPageNumber={setPageNumber}
              setItemsPerPage={setItemsPerPage}
            />
          </div>
        </>
      )}

      <FormReviewOffcanvas
        Show={!toggle1}
        Hide={setToggle1}
        Title={"View Form Review Detail"}
        formReviewData={formReviewData}
      />
    </>
  );
};

export default FormReviewerTable;
