import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";

const StudyLogin = ({ Show, Hide, Title, modeId, studyListId, setDetailsData }) => {
  const [mode, setMode] = useState("");

  const studyLogin = () => {
    window.location.href = `/study/${modeId}`;
    localStorage.setItem("studyListId", JSON.stringify(studyListId));
    localStorage.setItem("loginType", JSON.stringify("study"));
    localStorage.setItem("modeType", JSON.stringify(mode));
  };

  return (
    <>
      <Modal show={Show} onHide={Hide} placement="end">
        <Modal.Header>
          <Modal.Title>{Title}</Modal.Title>
          <div onClick={Hide} className="close_modal" title="Close">
            X
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Select Mode <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select onChange={(e) => setMode(e.target.value)}>
              <option value="">Select Mode</option>
              <option selected={mode === "DBP"} value="DBP">
                DBP
              </option>
              <option selected={mode === "QC"} value="QC">
                QC
              </option>
              <option selected={mode === "Live"} value="Live">
                Live
              </option>
              <option selected={mode === "Study"} value="Study">
                Study Settings
              </option>
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Form.Group className="text-end">
            <button
              className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
              onClick={studyLogin}
            >
              Login
            </button>
          </Form.Group>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StudyLogin;
