import React from "react";
import { Offcanvas, Table } from "react-bootstrap";

const UserAttributeData = ({
  Show,
  Hide,
  Title,
  tableRef,
  onMouseDown,
  userUpdateId,
}) => {
  return (
    <>
      <div>
        <Offcanvas show={Show} onHide={Hide} placement="end">
          <Offcanvas.Header>
            <Offcanvas.Title>{Title}</Offcanvas.Title>
            <div onClick={Hide} className="close_modal" title="Close">
              X
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Table
              id="resizable-table"
              striped
              hover
              bordered
              className="m-0 custom-table"
              ref={tableRef}
            >
              <thead className="position-sticky" style={{ top: -1 }}>
                <tr>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 0)}
                    style={{ width: "100px" }}
                  >
                    Attribute
                  </th>
                  <th onMouseDown={(e) => onMouseDown(e, 1)}>Value</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>User Name</td>
                  <td>{userUpdateId?.clientUserName}</td>
                </tr>
                <tr>
                  <td>First Name</td>
                  <td>{userUpdateId?.firstName}</td>
                </tr>
                <tr>
                  <td>Middle Name</td>
                  <td>{userUpdateId?.middleName}</td>
                </tr>
                <tr>
                  <td>Last Name</td>
                  <td>{userUpdateId?.lastName}</td>
                </tr>
                <tr>
                  <td>Email Id</td>
                  <td>
                    <a href={`mailto:${userUpdateId?.email}`}>
                      {userUpdateId?.email}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Phone Number</td>
                  <td>
                    <a href={`tel:+${userUpdateId?.phoneNumber}`}>
                      {userUpdateId?.phoneNumber}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>{userUpdateId?.address}</td>
                </tr>
                <tr>
                  <td>Country</td>
                  <td>{userUpdateId?.country}</td>
                </tr>
                <tr>
                  <td>State</td>
                  <td>{userUpdateId?.state}</td>
                </tr>
                <tr>
                  <td>City</td>
                  <td>{userUpdateId?.city}</td>
                </tr>
                <tr>
                  <td>Zip Code</td>
                  <td>{userUpdateId?.pinCode}</td>
                </tr>
              </tbody>
            </Table>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default UserAttributeData;
