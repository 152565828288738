import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import UseToggle from "../../../../Hooks/UseToggle";
import AddClientForm from "./AddClientForm";
import ClientTableData from "./ClientTableData";
import ClientAttributeData from "./ClientAttributeData";
import { CiExport } from "react-icons/ci";
import { authServices } from "../../../../APIServices/authServices";
import { Spinner } from "react-bootstrap";

const ClientData = ({ onMouseDown, tableRef, handleAddNewTab }) => {
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [loader, setLoader] = useState(false);
  const [userUpdateId, setUserUpdateId] = useState("");
  const [userList, setUserList] = useState([]);
  const [userDataId, setUserDataId] = useState("");

  const getMyData = async () => {
    let data = await authServices.getMyData("me");
    setUserDataId(data?.principal?.clientUserName);
  };

  const getUserListByType = async () => {
    setLoader(true);
    let data = await authServices.getUserListByType("sponsor", "createdDate", userDataId);
    setUserList(data);
    setLoader(false);
  };

  useEffect(() => {
    getMyData();
  }, []);

  useEffect(() => {
    if (userDataId) {
      getUserListByType();
    }
  }, [userDataId]);

  return (
    <>
      <div className={`code-list-container`}>
        <div
          className="hstack justify-content-between w-100 border-bottom"
          style={{ padding: "4.6px 9px" }}
        >
          <div className="hstack gap-2">
            <button
              title="Add Sponsor"
              className="tabButtons border rounded-1 fs-13"
              style={{ padding: "1px 5px" }}
              onClick={setToggle}
            >
              <AiOutlinePlus />
            </button>
          </div>
          <button className="tabButtons border rounded-1 fs-13" title="Export">
            <CiExport />
          </button>
        </div>
        <div
          className="position-relative px-2 mt-2 overflow-auto"
          style={{ height: "calc(100vh - 156px)" }}
        >
          {loader ? (
            <div className="hstack justify-content-center h-100">
              <Spinner />
            </div>
          ) : userList?.length === 0 ? (
            <div className="hstack justify-content-center h-100">
              Sponsor List not Found!
            </div>
          ) : (
            <ClientTableData
              setToggle={setToggle}
              setToggle1={setToggle1}
              onMouseDown={onMouseDown}
              tableRef={tableRef}
              userList={userList}
              handleAddNewTab={handleAddNewTab}
              setUserUpdateId={setUserUpdateId}
              getUserListByType={getUserListByType}
            />
          )}
        </div>
      </div>

      <ClientAttributeData
        Show={!toggle1}
        Hide={setToggle1}
        Title={"View Sponsor Detail"}
        userUpdateId={userUpdateId}
        tableRef={tableRef}
        onMouseDown={onMouseDown}
      />
      <AddClientForm
        Show={!toggle}
        Hide={setToggle}
        Title={`${userUpdateId ? "Update" : "Add"} Sponsor`}
        getUserListByType={getUserListByType}
        userUpdateId={userUpdateId}
      />
    </>
  );
};

export default ClientData;
