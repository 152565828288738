import React, { useEffect, useState } from "react";
import { Form, Offcanvas, Spinner } from "react-bootstrap";
import { FaPlusSquare } from "react-icons/fa";

const AddUpdateForm = ({
  formName,
  setFormName,
  domainClass,
  setDoaminClass,
  domainDescription,
  setDomainDescription,
  domainCode,
  setDomainCode,
  setSelectForm,
  logForm,
  setLogForm,
  specialForm,
  setSpecialForm,
  formData,
  Show,
  Hide,
  Title,
  addForm,
  formID,
  updateFormData,
  setSelectedFormName,
  loader
}) => {
  const [openAddForm, setOpenAddForm] = useState("custom");

  useEffect(() => {
    setFormName(updateFormData?.name);
    setDoaminClass(updateFormData?.domainClass);
    setDomainDescription(updateFormData?.domainDescription);
    setDomainCode(updateFormData?.domainCode);
    setLogForm(updateFormData?.logForm);
    setSpecialForm(updateFormData?.specialForm);
  }, [updateFormData]);

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    const selectedName = e.target.options[e.target.selectedIndex].text;
    setSelectForm(selectedValue);
    setSelectedFormName(selectedName);
  };

  const clearFormData = () => {
    Hide();
    setSelectForm("");
    setSelectedFormName("");
    setFormName("");
    setDoaminClass("");
    setDomainDescription("");
    setDomainCode("");
    setLogForm("");
    setSpecialForm("");
  };

  return (
    <div>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title>{Title}</Offcanvas.Title>
          <div onClick={() => clearFormData()} className="close_modal" title="Close">
            X
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div className="hstack">
            <button
              className={`w-100 border fs-13 py-2 ${openAddForm === "custom" ? "activeMenu" : "bg-white"
                }`}
              onClick={() => setOpenAddForm("custom")}
            >
              Custom Form
            </button>
            {!formID && (
              <button
                className={`w-100 border fs-13 py-2 ${openAddForm === "fromDB" ? "activeMenu" : "bg-white"
                  }`}
                onClick={() => setOpenAddForm("fromDB")}
              >
                Pull Form Database
              </button>
            )}
          </div>
          <div
            className="overflow-auto"
            style={{ height: "calc(100vh - 119px)" }}
          >
            <div className="px-3 py-2">
              {openAddForm === "custom" ? (
                <Form>
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1 fw-bold">
                      Form Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Form Name"
                      value={formName}
                      onChange={(e) => setFormName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1 fw-bold">
                      Domain Class <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      value={domainClass}
                      onChange={(e) => setDoaminClass(e.target.value)}
                    >
                      <option value="">Select Domain Class</option>
                      <option
                        selected={
                          domainClass === "specialPurposeDomain" &&
                          "specialPurposeDomain"
                        }
                        value="interventionsDomains"
                      >
                        Special Purpose Domain
                      </option>
                      <option
                        selected={
                          domainClass === "interventionsDomains" &&
                          "interventionsDomains"
                        }
                        value="interventionsDomains"
                      >
                        Interventions Domains
                      </option>
                      <option
                        selected={
                          domainClass === "eventsDomains" && "eventsDomains"
                        }
                        value="eventsDomains"
                      >
                        Events Domains
                      </option>
                      <option
                        selected={
                          domainClass === "findingsDomains" && "findingsDomains"
                        }
                        value="findingsDomains"
                      >
                        Findings Domains
                      </option>
                      <option
                        selected={
                          domainClass ===
                          "findingsAboutEventsorInterventions" &&
                          "findingsAboutEventsorInterventions"
                        }
                        value="findingsAboutEventsorInterventions"
                      >
                        Findings About Events or Interventions
                      </option>
                      <option
                        selected={
                          domainClass === "customDomain" && "customDomain"
                        }
                        value="customDomain"
                      >
                        Custom Domain
                      </option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1 fw-bold">
                      Domain Description <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Domain Description"
                      value={domainDescription}
                      onChange={(e) => setDomainDescription(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1 fw-bold">
                      Domain Code <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Domain Code"
                      value={domainCode}
                      onChange={(e) => setDomainCode(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Check
                      type="checkbox"
                      label="Log Form"
                      checked={logForm}
                      onChange={(e) => setLogForm(e.target.checked)}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Special Form"
                      checked={specialForm}
                      onChange={(e) => setSpecialForm(e.target.checked)}
                    />
                  </Form.Group>
                </Form>
              ) : openAddForm === "fromDB" ? (
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1 fw-bold">
                    Select Form <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select onChange={handleSelectChange}>
                    <option value="">Select Form</option>
                    {formData?.map((item, index) => (
                      <option key={index} value={item?.id}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              ) : (
                ""
              )}
            </div>
          </div>
          <Form.Group className="text-end p-2 bg-light">
            <button
              className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
              onClick={() => formID ? "" : addForm()}
            >
              {loader ? (
                <Spinner
                  animation="border"
                  style={{ width: 15, height: 15 }}
                  className="me-2"
                />
              ) : null}
              <FaPlusSquare style={{ marginBottom: 2 }} />
              {formID ? "Update" : "Add"}
            </button>
          </Form.Group>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default AddUpdateForm;
