import React, { useState } from "react";
import { Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import { CiExport } from "react-icons/ci";
import Pagination from "../../../../../Hooks/Pagination";
import { FiEdit } from "react-icons/fi";
import { FaRegEye } from "react-icons/fa";
import UseToggle from "../../../../../Hooks/UseToggle";
import AddUpdateProject from "./AddUpdateProject";
import { authServices } from "../../../../../APIServices/authServices";
import StudyTable from "./StudyTable";

const ProjectTable = ({
  onMouseDown,
  tableRef,
  projectList,
  loader,
  sponsorId,
  getProjectData,
}) => {
  const { toggle, setToggle } = UseToggle();
  const [addUpdateProjectId, setAddUpdateProjectId] = useState("");
  const [projectLabel, setProjectLabel] = useState("");
  const [studyListId, setStudyListId] = useState("");

  const addProject = () => {
    setAddUpdateProjectId("");
    setProjectLabel("");
    setToggle();
  };

  const updateProject = (item) => {
    setAddUpdateProjectId(item);
    setToggle();
  };

  const deleteProject = async (id) => {
    let data = await authServices.deleteProject(id);
    getProjectData();
  };

  const saveid = (item) => {
    setStudyListId(item?.id);
    localStorage.setItem("ProjectData", JSON.stringify(item));

  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = projectList?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(projectList?.length / itemsPerPage);

  return (
    <Container fluid>
      <Row>
        <Form.Group as={Col} md={studyListId ? 4 : 12} className="p-0">
          <div className="border-end">
            <div
              className="hstack justify-content-between w-100 border-bottom"
              style={{ padding: "4.6px 9px" }}
            >
              <div className="hstack gap-2">
                <button
                  title="Add Project"
                  className="tabButtons border rounded-1 fs-13"
                  style={{ padding: "1px 5px" }}
                  onClick={() => addProject()}
                >
                  <AiOutlinePlus />
                </button>
              </div>
              <button
                className="tabButtons border rounded-1 fs-13"
                title="Export"
              >
                <CiExport />
              </button>
            </div>
            <div
              className="overflow-auto px-2 mt-2"
              style={{ height: "calc(100vh - 156px)" }}
            >
              {loader ? (
                <div
                  className="hstack justify-content-center"
                  style={{ height: "calc(100vh - 156px)" }}
                >
                  <Spinner />
                </div>
              ) : result?.length === 0 ? (
                <div
                  className="hstack justify-content-center"
                  style={{ height: "calc(100vh - 156px)" }}
                >
                  Project List not Found!
                </div>
              ) : (
                <>
                  <Table
                    ref={tableRef}
                    id="resizable-table"
                    className="text-nowrap mb-1 custom-table"
                    hover
                    bordered
                    striped
                  >
                    <thead className="position-sticky z-1" style={{ top: -1 }}>
                      <tr>
                        <th
                          onMouseDown={(e) => onMouseDown(e, 0)}
                          style={{ width: 40 }}
                        >
                          Sr.
                        </th>
                        <th onMouseDown={(e) => onMouseDown(e, 1)}>
                          Project Name
                        </th>
                        <th
                          onMouseDown={(e) => onMouseDown(e, 2)}
                          style={{ width: 60 }}
                          className="text-center"
                        >
                          Study
                        </th>
                        <th
                          onMouseDown={(e) => onMouseDown(e, 3)}
                          style={{ width: 60 }}
                          className="text-center"
                        >
                          Edit
                        </th>
                        <th
                          onMouseDown={(e) => onMouseDown(e, 4)}
                          style={{ width: 60 }}
                          className="text-center"
                        >
                          Delete
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.map((item, index) => (
                        <tr
                          key={index}
                          className={`position-relative cursor-pointer `}
                        >
                          <td>{index + startIndex + 1}</td>
                          <td>{item?.label}</td>
                          <td className="text-center">
                            <div
                              title="View Study"
                              onClick={() => saveid(item)}
                            >
                              <FaRegEye className="text-primary" />
                            </div>
                          </td>
                          <td className="text-center">
                            <div
                              title="Update Project"
                              onClick={() => updateProject(item)}
                            >
                              <FiEdit className="text-primary" />
                            </div>
                          </td>
                          <td>
                            <div
                              className="text-center cursor-pointer"
                              title="Delete Project"
                              onClick={() => deleteProject(item?.id)}
                            >
                              <i
                                className={`fa-solid fa-trash-can CP red text-danger`}
                              ></i>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className="position-sticky bottom-0">
                    <Pagination
                      totalPage={totalPage}
                      pageNumber={pageNumber}
                      itemsPerPage={itemsPerPage}
                      totalItems={projectList?.length}
                      setPageNumber={setPageNumber}
                      setItemsPerPage={setItemsPerPage}
                    />
                  </div>
                </>
              )}
            </div>
            <AddUpdateProject
              Show={!toggle}
              Hide={setToggle}
              setProjectLabel={setProjectLabel}
              projectLabel={projectLabel}
              sponsorId={sponsorId}
              getProjectData={getProjectData}
              addUpdateProjectId={addUpdateProjectId}
              Title={`${addUpdateProjectId ? "Update" : "Add"} Project`}
            />
          </div>
        </Form.Group>
        {studyListId && (
          <Form.Group as={Col} md={8} className="p-0">
            <StudyTable studyListId={studyListId} />
          </Form.Group>
        )}
      </Row>
    </Container>
  );
};

export default ProjectTable;
