import React, { useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Card, Col, Collapse, Form, InputGroup, Row } from "react-bootstrap";
import { FaSquarePlus } from "react-icons/fa6";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import EditorFieldSettings from "./EditorFieldSettings";

const EditorMainPage = ({
  formData,
  setFormData,
  handleRemoveNewField,
  handleUpdateField,
}) => {
  const [openFieldSettings, setOpenFieldSettings] = useState("");
  return (
    <>
      <div
        className="overflow-auto p-3"
        style={{ height: "calc(100vh - 157px)" }}
      >
        {formData?.length > 0 ?
          formData?.map((item, index) => (
            /* Heading Section */
            item?.type === "heading" ?
              <Card key={index} className="mb-2 border overflow-hidden">
                <Card.Body className="p-0">
                  <Form.Group className="p-3 bg-light">
                    <div className="d-flex justify-content-between">
                      <Form.Label className="fw-bold mb-0">
                        {item?.label || "Heading Label"} | {item?.name || "Heading Name"}
                        {item?.help &&
                          <BsFillQuestionCircleFill title={item?.help} className="ms-2" />
                        }
                      </Form.Label>
                      <div style={{ fontSize: 11 }}>
                        <FaRegEdit
                          className="CP"
                          style={{ marginRight: 5 }}
                          onClick={() => setOpenFieldSettings(openFieldSettings === "heading" ? "" : "heading")}
                        />
                        <RiDeleteBin6Line onClick={() => handleRemoveNewField(item?.id)} className="text-danger CP" />
                      </div>
                    </div>
                  </Form.Group>
                  <Collapse in={openFieldSettings === "heading"}>
                    <div className="border-top">
                      <EditorFieldSettings
                        formIndex={index}
                        formValues={formData}
                        setFormValues={setFormData}
                        handleUpdateField={handleUpdateField}
                        fieldData={item}
                      />
                    </div>
                  </Collapse>
                </Card.Body>
              </Card>
              :
              /* Date Section  */
              item?.type === "date" ?
                <Card key={index} className="mb-2 border overflow-hidden">
                  <Card.Body className="p-0">
                    <Form.Group className="p-3 bg-light">
                      <div className="d-flex justify-content-between">
                        <Form.Label className="fw-bold mb-1">
                          {item?.label || "Date Label"} | {item?.name || "Date Name"}
                          <span className="text-danger">*</span>
                          {item?.help &&
                            <BsFillQuestionCircleFill title={item?.help} className="ms-2" />
                          }
                        </Form.Label>
                        <div className="mb-1" style={{ fontSize: 11 }}>
                          <FaRegEdit
                            className="CP"
                            style={{ marginRight: 5 }}
                            onClick={() => setOpenFieldSettings(openFieldSettings === "date" ? "" : "date")}
                          />
                          <RiDeleteBin6Line onClick={() => handleRemoveNewField(item?.id)} className="text-danger CP" />
                        </div>
                      </div>
                      <Form.Control
                        type="date"
                        disabled
                        placeholder="Enter Date"
                      />
                    </Form.Group>
                    <Collapse in={openFieldSettings === "date"}>
                      <div className="border-top">
                        <EditorFieldSettings
                          formIndex={index}
                          formValues={formData}
                          setFormValues={setFormData}
                          handleUpdateField={handleUpdateField}
                          fieldData={item}
                        />
                      </div>
                    </Collapse>
                  </Card.Body>
                </Card>
                :
                /* Time Section  */
                item?.type === "time" ?
                  <Card key={index} className="mb-2 border overflow-hidden">
                    <Card.Body className="p-0">
                      <Form.Group className="p-3 bg-light">
                        <div className="d-flex justify-content-between">
                          <Form.Label className="fw-bold mb-1">
                            {item?.label || "Time Label"} | {item?.name || "Time Name"}
                            <span className="text-danger">*</span>
                            {item?.help &&
                              <BsFillQuestionCircleFill title={item?.help} className="ms-2" />
                            }
                          </Form.Label>
                          <div className="mb-1" style={{ fontSize: 11 }}>
                            <FaRegEdit
                              className="CP"
                              style={{ marginRight: 5 }}
                              onClick={() => setOpenFieldSettings(openFieldSettings === "time" ? "" : "time")}
                            />
                            <RiDeleteBin6Line onClick={() => handleRemoveNewField(item?.id)} className="text-danger CP" />
                          </div>
                        </div>
                        <Form.Control
                          disabled
                          type="Time" placeholder="Enter Time" />
                      </Form.Group>
                      <Collapse in={openFieldSettings === "time"}>
                        <div className="border-top">
                          <EditorFieldSettings
                            formIndex={index}
                            formValues={formData}
                            setFormValues={setFormData}
                            handleUpdateField={handleUpdateField}
                            fieldData={item}
                          />
                        </div>
                      </Collapse>
                    </Card.Body>
                  </Card>
                  :
                  /* Text Section  */
                  item?.type === "text" ?
                    <Card key={index} className="mb-2 border overflow-hidden">
                      <Card.Body className="p-0">
                        <div className="d-flex justify-content-between p-3 bg-light">
                          <Row className="w-100">
                            <Form.Group as={Col} md={6} className="">
                              <Form.Label className="fw-bold mb-1">
                                {item?.label || "Text Label"} | {item?.name || "Text Name"}
                                <span className="text-danger">*</span>
                                {item?.help &&
                                  <BsFillQuestionCircleFill title={item?.help} className="ms-2" />
                                }
                              </Form.Label>
                              <Form.Control
                                disabled
                                type="text" placeholder="Enter Text"
                              />
                            </Form.Group>
                            <Form.Group as={Col} md={6}>
                              <Form.Label className="fw-bold mb-1">
                                {item?.unitLabel || "Unit Label"} | {item?.unitName || "Unit Name"}
                                <span className="text-danger">*</span>
                                {item?.unitHelp &&
                                  <BsFillQuestionCircleFill title={item?.unitHelp} className="ms-2" />
                                }
                              </Form.Label>
                              <Form.Select
                                onChange={(e) => handleUpdateField(item?.id, "unit", e.target.value)}
                                value={item?.unit}
                              >
                                <option value="">{item?.placeholder || "Select Option"}</option>
                              </Form.Select>
                            </Form.Group>
                          </Row>
                          <div className="mb-1 d-flex" style={{ fontSize: 11 }}>
                            <FaRegEdit
                              className="CP"
                              style={{ marginRight: 5 }}
                              onClick={() => setOpenFieldSettings(openFieldSettings === "text" ? "" : "text")}
                            />
                            <RiDeleteBin6Line onClick={() => handleRemoveNewField(item?.id)} className="text-danger CP" />
                          </div>
                        </div>
                        <Collapse in={openFieldSettings === "text"}>
                          <div className="border-top">
                            <EditorFieldSettings
                              formIndex={index}
                              formValues={formData}
                              setFormValues={setFormData}
                              handleUpdateField={handleUpdateField}
                              fieldData={item}
                            />
                          </div>
                        </Collapse>
                      </Card.Body>
                    </Card>
                    :
                    /* Number Section  */
                    item?.type === "number" ?
                      <Card key={index} className="mb-2 border overflow-hidden">
                        <Card.Body className="p-0">
                          <div className="d-flex justify-content-between p-3 bg-light">
                            <Row className="w-100">
                              <Form.Group as={Col} md={6} className="">
                                <Form.Label className="fw-bold mb-1">
                                  {item?.label || "Number Label"} | {item?.name || "Number Name"}
                                  <span className="text-danger">*</span>
                                  {item?.help &&
                                    <BsFillQuestionCircleFill title={item?.help} className="ms-2" />
                                  }
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  type="text" placeholder="Enter Text"
                                />
                              </Form.Group>
                              <Form.Group as={Col} md={6}>
                                <Form.Label className="fw-bold mb-1">
                                  {item?.unitLabel || "Unit Label"} | {item?.unitName || "Unit Name"}
                                  <span className="text-danger">*</span>
                                  {item?.unitHelp &&
                                    <BsFillQuestionCircleFill title={item?.unitHelp} className="ms-2" />
                                  }
                                </Form.Label>
                                <Form.Select>
                                  <option value="">{item?.placeholder || "Select Option"}</option>
                                </Form.Select>
                              </Form.Group>
                            </Row>
                            <div className="mb-1 d-flex" style={{ fontSize: 11 }}>
                              <FaRegEdit
                                className="CP"
                                style={{ marginRight: 5 }}
                                onClick={() => setOpenFieldSettings(openFieldSettings === "number" ? "" : "number")}
                              />
                              <RiDeleteBin6Line onClick={() => handleRemoveNewField(item?.id)} className="text-danger CP" />
                            </div>
                          </div>
                          <Collapse in={openFieldSettings === "number"}>
                            <div className="border-top">
                              <EditorFieldSettings
                                formIndex={index}
                                formValues={formData}
                                setFormValues={setFormData}
                                handleUpdateField={handleUpdateField}
                                fieldData={item}
                              />
                            </div>
                          </Collapse>
                        </Card.Body>
                      </Card>
                      :
                      /* Text Area Section  */
                      item?.type === "textarea" ?
                        <Card key={index} className="mb-2 border overflow-hidden">
                          <Card.Body className="p-0">
                            <Form.Group className="p-3 bg-light">
                              <div className="d-flex justify-content-between">
                                <Form.Label className="fw-bold mb-1">
                                  {item?.label || "Long Text Label"} | {item?.name || "Long Text Name"}{" "}
                                  <span className="text-danger">*</span>
                                  {item?.help &&
                                    <BsFillQuestionCircleFill title={item?.help} className="ms-2" />
                                  }
                                </Form.Label>
                                <div className="mb-1" style={{ fontSize: 11 }}>
                                  <FaRegEdit
                                    className="CP"
                                    style={{ marginRight: 5 }}
                                    onClick={() => setOpenFieldSettings(openFieldSettings === "textarea" ? "" : "textarea")}
                                  />
                                  <RiDeleteBin6Line onClick={() => handleRemoveNewField(item?.id)} className="text-danger CP" />
                                </div>
                              </div>
                              <Form.Control
                                disabled
                                as="textarea"
                                placeholder="Write here..."
                                rows={4}
                              />
                            </Form.Group>
                            <Collapse in={openFieldSettings === "textarea"}>
                              <div className="border-top">
                                <EditorFieldSettings
                                  formIndex={index}
                                  formValues={formData}
                                  setFormValues={setFormData}
                                  handleUpdateField={handleUpdateField}
                                  fieldData={item}
                                />
                              </div>
                            </Collapse>
                          </Card.Body>
                        </Card>
                        :
                        /* Checkbox Section  */
                        item?.type === "checkbox" ?
                          <Card key={index} className="mb-2 border overflow-hidden">
                            <Card.Body className="p-0">
                              <Form.Group className="p-3 bg-light">
                                <div className="d-flex justify-content-between">
                                  <Form.Label className="fw-bold mb-1">
                                    {item?.label || "Checkbox Label"} | {item?.name || "Checkbox Name"}{" "}
                                    <span className="text-danger">*</span>
                                    {item?.help &&
                                      <BsFillQuestionCircleFill title={item?.help} className="ms-2" />
                                    }
                                  </Form.Label>
                                  <div className="mb-1" style={{ fontSize: 11 }}>
                                    <FaRegEdit
                                      className="CP"
                                      style={{ marginRight: 5 }}
                                      onClick={() => setOpenFieldSettings(openFieldSettings === "checkBox" ? "" : "checkBox")}
                                    />
                                    <RiDeleteBin6Line onClick={() => handleRemoveNewField(item?.id)} className="text-danger CP" />
                                  </div>
                                </div>
                                <Form.Check disabled type="checkbox" label="Male" />
                                <Form.Check disabled type="checkbox" label="Female" />
                              </Form.Group>
                              <Collapse in={openFieldSettings === "checkBox"}>
                                <div className="border-top">
                                  <EditorFieldSettings
                                    formIndex={index}
                                    formValues={formData}
                                    setFormValues={setFormData}
                                    handleUpdateField={handleUpdateField}
                                    fieldData={item}
                                  />
                                </div>
                              </Collapse>
                            </Card.Body>
                          </Card>
                          :
                          /* Radio Box Section  */
                          item?.type === "radio" ?
                            <Card key={index} className="mb-2 border overflow-hidden">
                              <Card.Body className="p-0">
                                <Form.Group className="p-3 bg-light">
                                  <div className="d-flex justify-content-between">
                                    <Form.Label className="fw-bold mb-1">
                                      {item?.label || "Radio Label"} | {item?.name || "Radio Name"}{" "}
                                      <span className="text-danger">*</span>
                                      {item?.help &&
                                        <BsFillQuestionCircleFill title={item?.help} className="ms-2" />
                                      }
                                    </Form.Label>
                                    <div className="mb-1" style={{ fontSize: 11 }}>
                                      <FaRegEdit
                                        className="CP"
                                        style={{ marginRight: 5 }}
                                        onClick={() => setOpenFieldSettings(openFieldSettings === "radio" ? "" : "radio")}
                                      />
                                      <RiDeleteBin6Line onClick={() => handleRemoveNewField(item?.id)} className="text-danger CP" />
                                    </div>
                                  </div>
                                  <Form.Check disabled type="radio" label="Male" />
                                  <Form.Check disabled type="radio" label="Female" />
                                </Form.Group>
                                <Collapse in={openFieldSettings === "radio"}>
                                  <div className="border-top">
                                    <EditorFieldSettings
                                      formIndex={index}
                                      formValues={formData}
                                      setFormValues={setFormData}
                                      handleUpdateField={handleUpdateField}
                                      fieldData={item}
                                    />
                                  </div>
                                </Collapse>
                              </Card.Body>
                            </Card>
                            :
                            /* Select Section  */
                            item?.type === "select" ?
                              <Card key={index} className="mb-2 border overflow-hidden">
                                <Card.Body className="p-0">
                                  <Form.Group className="p-3 bg-light">
                                    <div className="d-flex justify-content-between">
                                      <Form.Label className="fw-bold mb-1">
                                        {item?.label || "Dropdown Label"} | {item?.name || "Dropdown Name"}{" "}
                                        <span className="text-danger">*</span>
                                        {item?.help &&
                                          <BsFillQuestionCircleFill title={item?.help} className="ms-2" />
                                        }
                                      </Form.Label>
                                      <div className="mb-1" style={{ fontSize: 11 }}>
                                        <FaRegEdit
                                          className="CP"
                                          style={{ marginRight: 5 }}
                                          onClick={() => setOpenFieldSettings(openFieldSettings === "select" ? "" : "select")}
                                        />
                                        <RiDeleteBin6Line onClick={() => handleRemoveNewField(item?.id)} className="text-danger CP" />
                                      </div>
                                    </div>
                                    <Form.Select disabled>
                                      <option value="">Select </option>
                                      <option value="">Male</option>
                                      <option value="">Female</option>
                                    </Form.Select>
                                  </Form.Group>
                                  <Collapse in={openFieldSettings === "select"}>
                                    <div className="border-top">
                                      <EditorFieldSettings
                                        formIndex={index}
                                        formValues={formData}
                                        setFormValues={setFormData}
                                        handleUpdateField={handleUpdateField}
                                        fieldData={item}
                                      />
                                    </div>
                                  </Collapse>
                                </Card.Body>
                              </Card>
                              :
                              /* Upload File Section  */
                              item?.type === "upload" ?
                                <Card key={index} className="mb-2 border overflow-hidden">
                                  <Card.Body className="p-0">
                                    <Form.Group className="p-3 bg-light">
                                      <div className="d-flex justify-content-between">
                                        <Form.Label className="fw-bold mb-1">
                                          {item?.label || "Upload File Label"} | {item?.name || "Upload File Name"}{" "}
                                          <span className="text-danger">*</span>
                                          {item?.help &&
                                            <BsFillQuestionCircleFill title={item?.help} className="ms-2" />
                                          }
                                        </Form.Label>
                                        <div className="mb-1" style={{ fontSize: 11 }}>
                                          <FaRegEdit
                                            className="CP"
                                            style={{ marginRight: 5 }}
                                            onClick={() => setOpenFieldSettings(openFieldSettings === "uploadFile" ? "" : "uploadFile")}
                                          />
                                          <RiDeleteBin6Line onClick={() => handleRemoveNewField(item?.id)} className="text-danger CP" />
                                        </div>
                                      </div>
                                      <Form.Control disabled type="file" placeholder="upload file" />
                                    </Form.Group>
                                    <Collapse in={openFieldSettings === "uploadFile"}>
                                      <div className="border-top">
                                        <EditorFieldSettings
                                          formIndex={index}
                                          formValues={formData}
                                          setFormValues={setFormData}
                                          handleUpdateField={handleUpdateField}
                                          fieldData={item}
                                        />
                                      </div>
                                    </Collapse>
                                  </Card.Body>
                                </Card>
                                :
                                /* Scale Section  */
                                item?.type === "scale" ?
                                  <Card key={index} className="mb-2 border overflow-hidden">
                                    <Card.Body className="p-0">
                                      <Form.Group className="p-3 bg-light">
                                        <div className="d-flex justify-content-between">
                                          <Form.Label className="fw-bold mb-1">
                                            {item?.label || "Scale Label"} | {item?.name || "Scale Name"}{" "}
                                            <span className="text-danger">*</span>
                                            {item?.help &&
                                              <BsFillQuestionCircleFill title={item?.help} className="ms-2" />
                                            }
                                          </Form.Label>
                                          <div className="mb-1" style={{ fontSize: 11 }}>
                                            <FaRegEdit
                                              className="CP"
                                              style={{ marginRight: 5 }}
                                              onClick={() => setOpenFieldSettings(openFieldSettings === "scale" ? "" : "scale")}
                                            />
                                            <RiDeleteBin6Line onClick={() => handleRemoveNewField(item?.id)} className="text-danger CP" />
                                          </div>
                                        </div>
                                        <InputGroup
                                          style={{
                                            background: "linear-gradient(to right, green, orange, red)",
                                          }}
                                        >
                                          <InputGroup.Text
                                            className="text-white bg-transparent rounded-0 justify-content-center w-100"
                                            style={{ fontSize: 12 }}
                                          >
                                            Good
                                          </InputGroup.Text>
                                          <InputGroup.Text
                                            className="text-white bg-transparent rounded-0 justify-content-center w-100"
                                            style={{ fontSize: 12 }}
                                          >
                                            Good
                                          </InputGroup.Text>
                                          <InputGroup.Text
                                            className="text-white bg-transparent rounded-0 justify-content-center w-100"
                                            style={{ fontSize: 12 }}
                                          >
                                            Good
                                          </InputGroup.Text>
                                          <InputGroup.Text
                                            className="text-white bg-transparent rounded-0 justify-content-center w-100"
                                            style={{ fontSize: 12 }}
                                          >
                                            Good
                                          </InputGroup.Text>
                                        </InputGroup>
                                      </Form.Group>
                                      <Collapse in={openFieldSettings === "scale"}>
                                        <div className="border-top p-3">
                                          <Row>
                                            <Form.Group as={Col} md={4} className="mb-2">
                                              <Form.Label className="fw-bold mb-1">
                                                Field Label <span className="text-danger">*</span>
                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                onChange={(e) => handleUpdateField(item?.id, "label", e.target.value)}
                                                value={item?.label}
                                                placeholder="Enter Text Label" />
                                            </Form.Group>
                                            <Form.Group as={Col} md={4} className="mb-2">
                                              <Form.Label className="fw-bold mb-1">
                                                Field Name <span className="text-danger">*</span>
                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                onChange={(e) => handleUpdateField(item?.id, "name", e.target.value)}
                                                value={item?.name}
                                                placeholder="Enter Text Name" />
                                            </Form.Group>
                                            <Form.Group as={Col} md={4} className="mb-2">
                                              <Form.Label className="fw-bold mb-1">
                                                Field Help <span className="text-danger">*</span>
                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                onChange={(e) => handleUpdateField(item?.id, "help", e.target.value)}
                                                value={item?.help}
                                                placeholder="Enter Field Help"
                                              />
                                            </Form.Group>
                                            <Form.Group as={Col} md={12} className="mb-2">
                                              <Form.Label className="fw-bold mb-1">
                                                Add Options <span className="text-danger">*</span>
                                                <FaSquarePlus className="ms-2" title="Add More Option" />
                                              </Form.Label>
                                              <Form.Control type="text" placeholder="Enter Placeholder" />
                                            </Form.Group>
                                          </Row>
                                        </div>
                                      </Collapse>
                                    </Card.Body>
                                  </Card>
                                  : ""
          )) :
          <div className="hstack justify-content-center overflow-hidden"
           style={{ height: "calc(100vh - 189px)" }}>
            Add Fields from Tool!
          </div>
        }
      </div>
      {formData?.length > 0 &&
        <div className="text-end position-sticky bottom-0 bg-white shadow px-3 py-2">
          <button
            className="bg-color fs-13 text-white"
            style={{ padding: "2px 25px", borderRadius: 3 }}
          >
            Submit
          </button>
        </div>
      }
    </>
  );
};

export default EditorMainPage;
