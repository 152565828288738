import React, { useEffect, useState } from "react";
import { Form, Offcanvas, Spinner } from "react-bootstrap";
import { FaFloppyDisk } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import { authServices } from "../../../../../APIServices/authServices";

const AddUpdateVisitMetadata = ({
  Show,
  Hide,
  Title,
  id,
  visitColumnData,
  getVisitRowData,
  updateId,
}) => {
  const { studyId } = useParams();
  const [loader, setLoader] = useState(false);
  const [visitData, setVisitData] = useState([]);

  const addVisitMetadata = async () => {
    setLoader(true);
    const userData = {};
    userData.type = "VISIT";
    userData.studyId = studyId;
    userData.data = visitData;
    let data = await authServices.addNewVisit(studyId, userData);
    setLoader(false);
    getVisitRowData();
    Hide();
  };

  const updateVisitMetadata = async () => {
    setLoader(true);
    const userData = {};
    userData.type = "VISIT";
    userData.studyId = studyId;
    userData.data = visitData;
    let data = await authServices.getStudyManageDetailsEdit(
      studyId,
      id,
      userData
    );
    getVisitRowData();
    setLoader(false);
    Hide();
  };

  useEffect(() => {
    if (updateId?.data) {
      setVisitData({ ...updateId.data });
    }
  }, [updateId]);

  return (
    <div>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title>{Title}</Offcanvas.Title>
          <div onClick={Hide} className="close_modal" title="Close">
            X
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="overflow-auto py-2 px-3"
            style={{ height: "calc(100vh - 84px)" }}
          >
            {visitColumnData[0]?.data?.map((item, index) => (
              <Form.Group key={index} className="mb-2 w-100">
                <Form.Label className="mb-1 fw-bold">
                  {item?.label} <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type={item?.type}
                  name={item?.name}
                  placeholder={`Enter ${item?.name} value`}
                  value={visitData[item.name] || ""}
                  onChange={(e) =>
                    setVisitData((prevData) => ({
                      ...prevData,
                      [item.name]: e.target.value,
                    }))
                  }
                />
              </Form.Group>
            ))}
          </div>
          <Form.Group className="text-end p-2 bg-light">
            <button
              onClick={() => (updateId ? updateVisitMetadata() : addVisitMetadata())}
              className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
            >
              {loader && (
                <Spinner
                  animation="border"
                  style={{ width: 15, height: 15 }}
                  className="me-2"
                />
              )}
              <FaFloppyDisk style={{ marginBottom: 2 }} />{" "}
              {updateId ? "Update" : "Save"}
            </button>
          </Form.Group>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default AddUpdateVisitMetadata;
