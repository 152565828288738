import config from "../config/config.json";
import axios from "axios";
import { headers } from "../APIHelpers/Headers";

import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../APIHelpers/Responses";

export const authServices = {
  login,
  logout,
  forgotPassword,
  getMyData,
  resetPassword,
  getCurrentUserData,
  otpVerification,
  addUserClient,
  updateUserClient,
  twoFAEnableDisable,
  updateProfileImage,
  getCurrectLoginUser,
  updateProfile,
  generateQRCode,
  getQRData,
  showQRCode,
  enableTwoFactorAuth,
  disableTwoFactorAuth,
  twoFactorAuthType,
  getTwoFactorAuthType,
  emailOTPFor2FA,
  enableTwoFactorAuthByEmail,
  disableTwoFactorAuthByEmail,
  verifyTwoFAForEmailLogin,
  verifyTwoFAForAppLogin,
  disableTwoFactorAuthByAdmin,
  getUserListByType,
  deleteUserById,
  getDashboardGraphData,

  accessManagements,
  addAccessManagemnt,
  updateAccessManagemnt,
  deleteAccessManagement,
  getProjectData,
  getStudyData,
  addProject,
  updateProject,
  deleteProject,

  addStudy,
  updateStudy,
  deleteStudy,

  getFormData,
  getFormDataByStatus,
  addForm,
  getFormquarystatic,
  getFormquaryquery,
  updateAssignquery,
  getFormEntryBySite,
  getStudyManageDetails,
  getStudyManageDetailsEdit,
  deleteMetaData,
  addNewVisit,
  addConfigMetadata,
  getSubjectMetadata,
  addSubjectMetadata,
  updateSiteServices,
  addConfigForm,
  getSpecialFormData,

  getSubjectById,
  addNewSubject,
  updateSubjectMetadata,


  getVisitFormPosition,
  addVisitFormPosition,
  updateVisitFormPosition,


  addVisitSpecialFormPosition,
};


// add New Special Form
async function addVisitSpecialFormPosition(userData) {
  const response = await fetch(
    `${config.SM_API_URL}visit-form/special-form`,
    headersWithAuth("POST", userData, headers)
  );
  let responseData = {
    statusCode: 200,
    customMessage: "Visit Special Form position has been Successfully Added",
  };
  handleResponse(responseData);
}



// get Visit Form Position
async function getVisitFormPosition(id) {
  const response = await fetch(
    `${config.SM_API_URL}visit-form?visitId=${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// add New Subject
async function addVisitFormPosition(userData) {
  const response = await fetch(
    `${config.SM_API_URL}visit-form`,
    headersWithAuth("POST", userData, headers)
  );
  let responseData = {
    statusCode: 200,
    customMessage: "Visit Form position has been Successfully Added",
  };
  handleResponse(responseData);
}

// add Update Visit Form Position
async function updateVisitFormPosition(id, userData) {
  const response = await fetch(
    `${config.SM_API_URL}visit-form/${id}`,
    headersWithAuth("PATCH", userData, headers)
  );
  let responseData = {
    statusCode: 200,
    customMessage: "Visit Form position has been Successfully Updated",
  };
  handleResponse(responseData);
}

// update Subject Metadata
async function updateSubjectMetadata(id, userData) {
  const response = await fetch(
    `${config.SM_API_URL}subject/${id}`,
    headersWithAuth("PATCH", userData, headers)
  );
  let responseData = {
    statusCode: 200,
    customMessage: "Form has been Successfully Updated",
  };
  handleResponse(responseData);
}


// add New Subject
async function addNewSubject(userData) {
  const response = await fetch(
    `${config.SM_API_URL}subject`,
    headersWithAuth("POST", userData, headers)
  );
  let responseData = {
    statusCode: 200,
    customMessage: "Subject has been Successfully Added",
  };
  handleResponse(responseData);
}


// get Subject By Id
async function getSubjectById(siteId, studyMode) {
  const response = await fetch(
    `${config.SM_API_URL}subject?siteId=${siteId}&dbMode=${studyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Dashboaerd Graph Data
// async function addForm(userData, id) {
//   let response;
//   if (id) {

//     response = await fetch(
//       `${config.SM_API_URL}forms/${id}`,
//       headersWithAuth("POST", userData, headers)
//     );
//   } else {
//     response = await fetch(
//       `${config.SM_API_URL}forms`,
//       headersWithAuth("POST", userData, headers)
//     );
//   }
//   let responseData = {
//     statusCode: 200,
//     customMessage: "Form has been Successfully Added",
//   };
//   handleResponse(responseData);
// }

async function addForm(userData) {
  const response = await fetch(
    `${config.SM_API_URL}forms`,
    headersWithAuth("POST", userData, headers)
  );
  let responseData = {
    statusCode: 200,
    customMessage: "Form has been Successfully Added",
  };
  handleResponse(responseData);
}

async function addConfigForm(userData) {
  const response = await fetch(
    `${config.SM_API_URL}visit-form`,
    headersWithAuth("POST", userData, headers)
  );
  let responseData = {
    statusCode: 200,
    customMessage: "Form has been Successfully Added",
  };
  handleResponse(responseData);
}

// Get Dashboaerd Graph Data
async function getFormData(studyId) {
  const response = await fetch(
    `${config.SM_API_URL}forms?studyId=${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//getSpecialFormData
async function getSpecialFormData(studyId, status) {
  const response = await fetch(
    `${config.SM_API_URL}forms?studyId=${studyId}&specialForm=${status}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get getSubjectMetadata
async function getSubjectMetadata(type, studyId) {
  const response = await fetch(
    `${config.SM_API_URL}metadata?type=${type}&studyId=${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// getFormDataByStatus Form  Data
async function getFormDataByStatus(studyId, status) {
  const response = await fetch(
    `${config.SM_API_URL}forms?studyId=${studyId}&status=${status}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// getFormEntryBySite Form  Data
async function getFormEntryBySite(type, studyId) {
  const response = await fetch(
    `${config.SM_API_URL}metadata?type=${type}&studyId=${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Approved Form  Data
async function getStudyManageDetails(studyId, type) {
  const response = await fetch(
    `${config.SM_API_URL}study/${studyId}/config?type=${type}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudyManageDetailsEdit(studyId, id, userData) {
  const response = await fetch(
    `${config.SM_API_URL}study/${studyId}/config/${id}`,
    headersWithAuth("PATCH", userData, headers)
  );
  let responseData = {
    statusCode: 200,
    customMessage: "Form has been Successfully Added",
  };
  handleResponse(responseData);
}

async function updateSiteServices(studyId, id, userData) {
  const response = await fetch(
    `${config.SM_API_URL}study/${studyId}/config/${id}`,
    headersWithAuth("PATCH", userData, headers)
  );
  let responseData = {
    statusCode: 200,
    customMessage: "Form has been Successfully Added",
  };
  handleResponse(responseData);
}
//Subject MetaData Add addSubjectMetadata
async function addSubjectMetadata(id, userData) {
  const response = await fetch(
    `${config.SM_API_URL}metadata/${id}`,
    headersWithAuth("PATCH", userData, headers)
  );
  let responseData = {
    statusCode: 200,
    customMessage: "Form has been Successfully Added",
  };
  handleResponse(responseData);
}

//addConfigMetadata
async function addConfigMetadata(userData, id) {
  const response = await fetch(
    `${config.SM_API_URL}metadata/${id}`,
    headersWithAuth("PATCH", userData, headers)
  );
  let responseData = {
    statusCode: 200,
    customMessage: "Form has been Successfully Added",
  };
  handleResponse(responseData);
}

// getFormquarystatic Form  Data
async function getFormquarystatic(studyId) {
  const response = await fetch(
    `${config.SM_API_URL}query/statistics?studyId=${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// getFormquarystatic Form  Data
async function getFormquaryquery(studyId) {
  const response = await fetch(
    `${config.SM_API_URL}query?studyId=${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function updateAssignquery(userData, id) {
  const response = await fetch(
    `${config.SM_API_URL}query/assign/${id}`,
    headersWithAuth("PATCH", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Get Dashboaerd Graph Data
async function addStudy(userData) {
  const response = await fetch(
    `${config.SM_API_URL}study`,
    headersWithAuth("POST", userData, headers)
  );
  let responseData = {
    statusCode: 200,
    customMessage: "Study has been Successfully Added",
  };
  handleResponse(responseData);
}

async function updateStudy(userData, id) {
  const response = await fetch(
    `${config.SM_API_URL}study/${id}`,
    headersWithAuth("PATCH", userData, headers)
  );
  const data = await response.json();
  return data;
}

async function deleteStudy(id) {
  const response = await fetch(
    `${config.SM_API_URL}study/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  let responseData = {
    statusCode: 200,
    customMessage: "Study has been Successfully Deleted",
  };
  handleResponse(responseData);
}

// DELETE METADATA
async function deleteMetaData(studyId, id) {
  const response = await fetch(
    `${config.SM_API_URL}study/${studyId}/config/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  let responseData = {
    statusCode: 200,
    customMessage: "Study has been Successfully Deleted",
  };
  handleResponse(responseData);
}

// Get Dashboaerd Graph Data
async function addProject(userData) {
  const response = await fetch(
    `${config.SM_API_URL}project`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Get Dashboaerd Graph Data
async function addNewVisit(studyId, userData) {
  const response = await fetch(
    `${config.SM_API_URL}study/${studyId}/config`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

async function updateProject(userData, id) {
  const response = await fetch(
    `${config.SM_API_URL}project/${id}`,
    headersWithAuth("PATCH", userData, headers)
  );
  const data = await response.json();
  return data;
}

async function deleteProject(id) {
  const response = await fetch(
    `${config.SM_API_URL}project/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  let responseData = {
    statusCode: 200,
    customMessage: "Project has been Successfully Deleted",
  };
  handleResponse(responseData);
}

// Get Dashboaerd Graph Data
async function getStudyData(projectId) {
  const response = await fetch(
    `${config.SM_API_URL}study?projectId=${projectId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Dashboaerd Graph Data
async function getProjectData(sponsorId) {
  const response = await fetch(
    `${config.SM_API_URL}project?sponsorId=${sponsorId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// User login
async function login(userData) {
  const response = await fetch(
    `${config.API_URL}auth/token`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let tokenData = data.jwt;
  if (tokenData) {
    localStorage.setItem("token", JSON.stringify(data.jwt));
    window.location.href = "/home";
    return {
      statusCode: 200,
      customMessage: "You have Successfully loggedIn",
    };
  } else {
    return {
      statusCode: 400,
      customMessage: "Opps! Please try again",
    };
  }
}

// User Logout
async function logout(userData) {
  // const response = await fetch(
  //   `${config.API_URL}logout`,
  //   headersWithAuth("PUT", userData, headers)
  // );
  // const data = await response.json();
  // await handleResponse(data);
  localStorage.clear();
  window.location.href = "/login";
}

// OTP Verification
async function otpVerification(userData) {
  const response = await fetch(
    `${config.API_URL}otpVerification`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  if (data.statusCode === 200) {
    localStorage.setItem("isTwoFA", JSON.stringify(false));
    window.location.href = "/admin/dashboard";
  }
  return data;
}

// Change Password
async function addUserClient(userData) {
  const response = await fetch(
    `${config.API_URL}users/`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Update Client
async function updateUserClient(userData, id) {
  const response = await fetch(
    `${config.API_URL}users/${id}`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Get My Data
async function getMyData(id) {
  const response = await fetch(
    `${config.API_URL}users/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Add Access Managements
async function addAccessManagemnt(userData) {
  const response = await fetch(
    `${config.API_URL}accessmanagements/`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Update Access Managements
async function updateAccessManagemnt(userData, id) {
  const response = await fetch(
    `${config.API_URL}accessmanagements/${id}`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Get Access Managements
async function accessManagements(display, accessGroupId) {
  const response = await fetch(
    `${config.API_URL}accessmanagements?display=${display}&accessGroupId=${accessGroupId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Current User Data
async function getCurrentUserData(userType) {
  const response = await fetch(
    `${config.API_URL}useraccess?username=${userType}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Dashboaerd Graph Data
async function getDashboardGraphData(status) {
  const response = await fetch(
    `${config.SM_API_URL}stats?active=${status}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Current User Login Profile Data
async function getUserListByType(userType, sortBy, clientUserName) {
  const response = await fetch(
    `${config.API_URL}users?userType=${userType}&sortBy=${sortBy}&clientUserName=${clientUserName}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Update Profile Image
async function updateProfileImage(formData, id) {
  const response = await axios.post(
    `${config.API_URL}updateProfileImage/${id}`,
    formData
  );
  const data = response?.data;
  await handleResponse(data);
  return data;
}

// Reset Password
async function resetPassword(userData) {
  const response = await fetch(
    `${config.API_URL}resetPassword`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Forgot Password
async function forgotPassword(userData) {
  const response = await fetch(
    `${config.API_URL}forgotPassword`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Get Current User Login Profile Data
async function getCurrectLoginUser() {
  const response = await fetch(
    `${config.API_URL}getCurrectLoginUser`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// delete Access Management
async function deleteAccessManagement(id) {
  const response = await fetch(
    `${config.API_URL}accessmanagements/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  return data;
}

// Get Current User Login Profile Data
async function deleteUserById(id) {
  const response = await fetch(
    `${config.API_URL}users/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  return data;
}

// Two Factor Authentication
async function twoFAEnableDisable(userData) {
  const response = await fetch(
    `${config.API_URL}twoFAEnableDisable`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Update Company Profile Data
async function updateProfile(userData) {
  const response = await fetch(
    `${config.API_URL}updateProfile`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Generate QR code
async function generateQRCode() {
  const response = await fetch(
    `${config.API_URL}generateQRCode`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get QR Data
async function getQRData() {
  const response = await fetch(
    `${config.API_URL}getQRData`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Show QR code
async function showQRCode() {
  const response = await fetch(
    `${config.API_URL}showQRCode`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Enable Two Factor Auth
async function enableTwoFactorAuth(userData) {
  const response = await fetch(
    `${config.API_URL}enableTwoFactorAuth`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Disable Two Factor Auth
async function disableTwoFactorAuth(userData) {
  const response = await fetch(
    `${config.API_URL}disableTwoFactorAuth`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Get Auth Type
async function getTwoFactorAuthType() {
  const response = await fetch(
    `${config.API_URL}getTwoFactorAuthType`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Update Auth Type
async function twoFactorAuthType(userData) {
  const response = await fetch(
    `${config.API_URL}twoFactorAuthType`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Send OTP for Email two factor auth
async function emailOTPFor2FA(userData) {
  const response = await fetch(
    `${config.API_URL}emailOTPFor2FA`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Disable Email Two Factor Auth
async function disableTwoFactorAuthByEmail(userData) {
  const response = await fetch(
    `${config.API_URL}disableTwoFactorAuthByEmail`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Enable Email Two Factor Auth
async function enableTwoFactorAuthByEmail(userData) {
  const response = await fetch(
    `${config.API_URL}enableTwoFactorAuthByEmail`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Verify Two FA for Email Login
async function verifyTwoFAForEmailLogin(userData) {
  const response = await fetch(
    `${config.API_URL}verifyTwoFAForEmailLogin`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Verify Two FA for App Login
async function verifyTwoFAForAppLogin(userData) {
  const response = await fetch(
    `${config.API_URL}verifyTwoFAForAppLogin`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Disable Two Factor Auth by Admin
async function disableTwoFactorAuthByAdmin(userData) {
  const response = await fetch(
    `${config.API_URL}disableTwoFactorAuthByAdmin`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
