import React from "react";
import { Offcanvas, Table } from "react-bootstrap";
import Moment from "react-moment";

const FormReviewOffcanvas = ({ Show, Hide, Title, formReviewData }) => {
  return (
    <>
      <div>
        <Offcanvas show={Show} onHide={Hide} placement="end">
          <Offcanvas.Header>
            <Offcanvas.Title>{Title}</Offcanvas.Title>
            <div onClick={Hide} className="close_modal" title="Close">
              X
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Table
              id="resizable-table"
              striped
              hover
              bordered
              className="m-0 custom-table"
            >
              <thead className="position-sticky" style={{ top: -1 }}>
                <tr>
                  <th style={{ width: "100px" }}>Attribute</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Form Name</td>
                  <td>{formReviewData.name || "-"}</td>
                </tr>
                <tr>
                  <td>Form Version</td>
                  <td>{formReviewData.version || "-"}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>
                    <div
                      className={
                        formReviewData.status === "PUBLISHED"
                          ? "text-success"
                          : formReviewData.status === "Rejected"
                          ? "text-danger"
                          : formReviewData.status === "Approved"
                          ? "text-success"
                          : ""
                      }
                    >
                      {formReviewData.status || "-"}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Scope</td>
                  <td>
                    <div
                      className={
                        formReviewData.scope === "ACTIVE"
                          ? "text-success"
                          : formReviewData.scope === "INACTIVE"
                          ? "text-danger"
                          : ""
                      }
                    >
                      {formReviewData.scope}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Domain Class</td>
                  <td>{formReviewData.domainClass}</td>
                </tr>
                <tr>
                  <td>Log Form</td>
                  <td>{formReviewData.logForm?.toString()}</td>
                </tr>
                <tr>
                  <td>Special Class</td>
                  <td>{formReviewData.specialForm?.toString()}</td>
                </tr>
                <tr>
                  <td>Domain Description</td>
                  <td>{formReviewData.domainDescription}</td>
                </tr>
                <tr>
                  <td>Created Date & Time</td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm:ss">
                      {formReviewData.createdOn}
                    </Moment>
                  </td>
                </tr>
                <tr>
                  <td>Updated Date & Time</td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm:ss">
                      {formReviewData.modifiedOn}
                    </Moment>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default FormReviewOffcanvas;
