import React from "react";
import { Container } from "react-bootstrap";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <Container fluid expand="lg" className="bg-color" style={{ padding: 7 }}>
      <div className="text-center">
        <p
          className="fw-bold m-0"
          style={{ fontSize: 12, color: "#b3df43" }}
        >
          All Right Reserved | &copy; {currentYear} Clinical Trial | V1.0.0
        </p>
      </div>
    </Container>
  );
};

export default Footer;
