import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import { authServices } from "../../../../APIServices/authServices";

const ConfigTableData = ({
  visitName,
  visitForm,
  setVisitForm,
  studyId,
  visitId,
  visitData,
  visitOrder,
  visitFormList
}) => {
  const [order, setOrder] = useState("");
  const [visitFormData, setVisitFormData] = useState([]);

  const removeItem = (indexToRemove) => {
    setVisitForm((prevForm) =>
      prevForm.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleFormOrderChange = (index, value) => {
    setVisitFormData((prevData) =>
      prevData.map((item, idx) =>
        idx === index ? { ...item, formOrder: value } : item
      )
    );
  };

  const addVisitFormPosition = async () => {
    const userData = {};
    userData.studyId = studyId;
    userData.visitId = visitId;
    userData.order = order;
    userData.forms = visitFormData;
    let data = await authServices.addVisitFormPosition(userData);
  };

  useEffect(() => {
    const mainDataDict = visitData?.reduce((acc, item) => {
      acc[item.id] = item.logForm;
      return acc;
    }, {});
  
    const resultData = visitForm?.map((entry) => ({
      formId: entry.value,
      formName: entry.label,
      logForm: mainDataDict[entry.value] || false,
      formOrder: entry?.formOrder || ""
    }));

    setVisitFormData(resultData)
  }, [visitData, visitForm]);

  useEffect(() => {
    setVisitFormData(visitFormList)
  }, [visitFormList]);

  useEffect(() => {
    setOrder(visitOrder)
  }, [visitOrder]);

  return (
    <Container fluid>
      <Row>
        <Form.Group
          as={Col}
          md={4}
          className="border-end overflow-auto py-2"
          style={{ height: "calc(100vh - 149px)" }}
        >
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">Visit</Form.Label>
            <Form.Control type="text" disabled value={visitName} />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">Visit Position</Form.Label>
            <Form.Control
              type="number"
              value={order}
              onChange={(e) => setOrder(e.target.value)}
              placeholder="Enter Position"
            />
          </Form.Group>
        </Form.Group>
        <Form.Group
          as={Col}
          md={8}
          className="overflow-auto mt-2 px-2"
          style={{ height: "calc(100vh - 157px)" }}
        >
          {visitFormData?.length > 0 ? (
            <>
              <Table
                id="resizable-table"
                className="text-nowrap mb-1 custom-table"
                hover
                bordered
                striped
              >
                <thead className="position-sticky z-1" style={{ top: -1 }}>
                  <tr>
                    <th style={{ width: 40 }}>Sr.</th>
                    <th>Form Name</th>
                    <th>Position</th>
                    <th style={{ width: 80 }}>
                      <div className="text-center">Remove</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {visitFormData?.map((item, index) => (
                    <tr
                      key={index}
                      className="position-relative cursor-pointer"
                    >
                      <td>{index + 1}</td>
                      <td>{item?.formName}</td>
                      <td className="p-0">
                        <Form.Control
                          type="number"
                          placeholder="Enter Position"
                          onChange={(e) =>
                            handleFormOrderChange(index, e.target.value)
                          }
                          value={item?.formOrder}
                          className="py-0 border-0 bg-transparent px-2"
                        />
                      </td>
                      <td
                        className="text-center cursor-pointer"
                        title="Remove Form"
                        onClick={() => removeItem(index)}
                      >
                        <i className="fa-solid fa-trash-can CP red text-danger"></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="text-end">
                <button
                  className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
                  onClick={addVisitFormPosition}
                >
                  Save
                </button>
              </div>
            </>
          ) : (
            <div
              className="hstack justify-content-center"
              style={{ height: "calc(100vh - 157px)" }}
            >
              Add Form for set Position
            </div>
          )}
        </Form.Group>
      </Row>
    </Container>
  );
};

export default ConfigTableData;
