import React, { useState } from "react";
import { Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import VisitMetadata from "./VisitMetadata/VisitMetadata";
import SubjectMetadata from "./SubjectMetadata/SubjectMetadata";
import SiteMetadata from "./SiteMetadata/SiteMetadata";

const Metadata = ({ onMouseDown, tableRef }) => {
  const [openTab, setOpenTab] = useState("visit");
  return (
    <>
      <Container fluid>
        <Row>
          <Form.Group as={Col} md={2} className="border-end p-0">
            <div
              className="p-2 overflow-auto"
              style={{ height: "calc(100vh - 115px)" }}
            >
              <ListGroup className="rounded-1">
                <ListGroup.Item
                  onClick={() => setOpenTab("visit")}
                  className={`cursor-pointer ${
                    openTab === "visit" && "activeMenu"
                  }`}
                  style={{ fontSize: 12 }}
                >
                  Visit
                </ListGroup.Item>
                <ListGroup.Item
                  onClick={() => setOpenTab("subject")}
                  className={`cursor-pointer ${
                    openTab === "subject" && "activeMenu"
                  }`}
                  style={{ fontSize: 12 }}
                >
                  Subject
                </ListGroup.Item>
                <ListGroup.Item
                  onClick={() => setOpenTab("site")}
                  className={`cursor-pointer ${
                    openTab === "site" && "activeMenu"
                  }`}
                  style={{ fontSize: 12 }}
                >
                  Site
                </ListGroup.Item>
              </ListGroup>
            </div>
          </Form.Group>
          <Form.Group as={Col} md={10} className="p-0">
            {openTab === "visit" ? (
              <VisitMetadata tableRef={tableRef} onMouseDown={onMouseDown} />
            ) : openTab === "subject" ? (
              <SubjectMetadata tableRef={tableRef} onMouseDown={onMouseDown} />
            ) : openTab === "site" ? (
              <SiteMetadata tableRef={tableRef} onMouseDown={onMouseDown} />
            ) : (
              ""
            )}
          </Form.Group>
        </Row>
      </Container>
    </>
  );
};

export default Metadata;
