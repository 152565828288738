import React, { useState } from "react";
import Moment from "react-moment";
import { Form, Spinner, Table } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import { FaRegEye } from "react-icons/fa";
import Pagination from "../../../Hooks/Pagination";
import UseToggle from "../../../Hooks/UseToggle";
import Swal from "sweetalert2";
import { authServices } from "../../../APIServices/authServices";
import QueryOffcanvas from "./QueryOffcanvas";

const QueryTable = ({ onMouseDown, tableRef, queryData, loading }) => {
  const [formDataId, setFormDataId] = useState("");
  const { toggle1, setToggle1 } = UseToggle();
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);

  const viewDetail = (item) => {
    setToggle1();
    setFormDataId(item);
  };

  const updateAssignQuery = async (id, assign) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Query Status!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
    }).then(async (result) => {
      let data = { assign };
      let results = await authServices.updateAssignquery(data, id);
    });
  };
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = queryData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(queryData?.length / itemsPerPage);

  return (
    <div className="p-2">
      <div className="overflow-auto" style={{ height: "calc(100vh - 256px)" }}>
        {loading ? (
          <div
            className="hstack justify-content-center"
            style={{ height: "calc(100vh - 256px)" }}
          >
            <Spinner />
          </div>
        ) : result?.length > 0 ? (
          <>
            <Table
              ref={tableRef}
              id="resizable-table"
              className="text-nowrap mb-1 custom-table"
              hover
              bordered
              striped
            >
              <thead className="position-sticky z-1" style={{ top: -1 }}>
                <tr>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 0)}
                    style={{ width: 40 }}
                  >
                    Sr.
                  </th>
                  <th onMouseDown={(e) => onMouseDown(e, 1)}>Query ID</th>
                  <th onMouseDown={(e) => onMouseDown(e, 2)}>Comment</th>
                  <th onMouseDown={(e) => onMouseDown(e, 3)}>Assignee</th>
                  <th onMouseDown={(e) => onMouseDown(e, 4)}>Assign</th>
                  <th onMouseDown={(e) => onMouseDown(e, 5)}>Form Name</th>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 6)}
                    style={{ width: 150 }}
                  >
                    Modified Date | Time
                  </th>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 7)}
                    style={{ width: 80 }}
                  >
                    <div className="text-center">View Form</div>
                  </th>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 8)}
                    style={{ width: 70 }}
                  >
                    <div className="text-center">Update</div>
                  </th>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 9)}
                    style={{ width: 60 }}
                  >
                    <div className="text-center">Remove</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {result?.map((item, index) => (
                  <tr key={index} className="position-relative cursor-pointer">
                    <td>{index + startIndex + 1}</td>
                    <td>{item.id}</td>
                    <td>{item.comments}</td>
                    <td>{item.assignee || "-"}</td>
                    <td className="px-0">
                      <Form.Select
                        className="border-0 rounded-0 bg-transparent py-0"
                        style={{ fontSize: 12 }}
                        value={item?.assignee}
                        onChange={(e) =>
                          updateAssignQuery(item?.id, e.target.value)
                        }
                      >
                        <option value="assign">Assign</option>
                        <option value="unassign">Unassign</option>
                      </Form.Select>
                    </td>
                    <td>{item.formName}</td>
                    <td>
                      <Moment format="DD MMM YYYY | HH:mm:ss">
                        {item.modifiedOn}
                      </Moment>
                    </td>
                    <td
                      className="text-center"
                      onClick={() => viewDetail(item)}
                    >
                      <div title="View Project">
                        <FaRegEye className="text-primary" />
                      </div>
                    </td>
                    <td className="text-center">
                      <div title="Update Query">
                        <FiEdit className="text-primary" />
                      </div>
                    </td>
                    <td className="text-center">
                      <div title="Delete Query" className="cursor-pointer">
                        <i className="fa-solid fa-trash-can text-danger"></i>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky bottom-0">
              <Pagination
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={queryData?.length}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </>
        ) : (
          <div
            className="hstack justify-content-center"
            style={{ height: "calc(100vh - 256px)" }}
          >
            Query Data Not Found!
          </div>
        )}
      </div>
      <QueryOffcanvas
        Show={!toggle1}
        Hide={setToggle1}
        Title={"View Form Review Detail"}
        formDataId={formDataId}
      />
    </div>
  );
};

export default QueryTable;
