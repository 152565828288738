import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { authServices } from "../../../APIServices/authServices";
import Swal from "sweetalert2";

const Details = () => {
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [statusCode, setStatusCode] = useState(null);
  const { studyId } = useParams();
  const studyData = JSON.parse(localStorage.getItem("setStudyData"));

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    let userData = {};
    userData.active = studyData?.active;
    userData.name = studyData?.name;
    userData.name = studyData?.name;
    userData.reviewMinPercentage = studyData?.reviewMinPercentage;
    userData.reviewers = studyData?.reviewers;
    userData.unfreeze = studyData?.unfreeze;
    let data = await authServices.updateStudy(userData, studyId);
    setStatusCode(data);
    if (data?.status_code === 200) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const updatestatus = async (id, status) => {
    setLoader(true);
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Client Status!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.status = status;
        let results = await authServices.updateStudy(userData, studyId);
        setStatusCode(results);
        if (results?.status_code === 200) {
          setLoader(false);
        } else {
          setLoader(false);
        }
      }

    });
  };

  return (
    <>
      <Container
        fluid
        className="overflow-auto"
        style={{ height: "calc(100vh - 115px)" }}
      >
        <Row>
          <Form.Group as={Col} md="12" lg="12" sm="12" className="px-1">
            <div className="shadow-md border my-2 mx-1 rounded-1 overflow-hidden">
              <Form.Group className="border-bottom pb-2 p-2 hstack justify-content-between bg-light">
                <Form.Label className="m-0 text-nowrap fw-bold fs-13">
                  Study Overview
                </Form.Label>
              </Form.Group>
              <div className="p-2">
                {studyData && (
                  <>
                    <Form.Group className="mb-2">
                      <Form.Label className="m-0 text-nowrap">
                        <b>Study Number :</b> {studyData?.name}
                      </Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label className="m-0 text-nowrap">
                        <b>Review Min Percentage :</b> {studyData?.reviewMinPercentage}
                      </Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label className="m-0 text-nowrap">
                        <b>Reviewers :</b> {studyData?.reviewers}
                      </Form.Label>
                    </Form.Group>
                  </>
                )}
                <Form.Group className="mb-2">
                  <button onClick={handleSubmit} className="p-1 px-3 border rounded-1 text-white bg-color fs-13 me-2">
                    {loading ? (
                      <Spinner
                        animation="border"
                        style={{ width: 15, height: 15 }}
                        className="me-2"
                      />
                    ) : null} Set Freeze</button>
                  <button onClick={() => updatestatus(studyData?.unfreeze === "true" ? "false" : "true")} className="p-1 px-3 border rounded-1 text-white bg-color fs-13"> {loader ? (
                    <Spinner
                      animation="border"
                      style={{ width: 15, height: 15 }}
                      className="me-2"
                    />
                  ) : null}  Set in Active</button>
                </Form.Group>
              </div>
            </div>
          </Form.Group>
        </Row>
      </Container>
    </>
  );
};

export default Details;

